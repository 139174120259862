import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

import { LoadingStatus } from '../../constants';
import {
  selectAssignmentId,
  selectCommentUploadStatus,
  createComment,
} from '../../reducers/labellerSlice';
import { uploadFile } from '../../api';
import Tooltip from '../ui/Tooltip';
import ActionButton from '../ui/ActionButton';
import useStyles from './styles/AssignmentBoard';
import icons from '../../helpers/IconMapping';
const { HelpIcon, AttachFileIcon } = icons;

function ReplyBox() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const labelTaskId = useSelector(selectAssignmentId);
  const commentUploadStatus = useSelector(selectCommentUploadStatus);
  const fileInputRef = useRef(null);
  const [typedText, setTypedText] = useState('');
  const [fileStaged, setFileStaged] = useState(false);

  async function handleClickComment() {
    const fileId = fileStaged ? await uploadFile(fileInputRef.current.files[0]) : null;
    await dispatch(createComment({ comment: typedText, fileId, labelTaskId }));
    setTypedText('');
    setFileStaged(false);
  }

  function showAdornment() {
    if (fileStaged) {
      // Show attachment icon
      return <AttachFileIcon />;
    } else {
      if (commentUploadStatus === LoadingStatus.LOADING) {
        return <CircularProgress />;
      } else {
        // show nothing
        return <></>;
      }
    }
  }

  return (
    <Paper className={classes.comment} style={{ marginTop: '30px' }}>
      <Grid container spacing={2}>
        <Grid item style={{ width: '100%' }}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Type here ..."
            value={typedText}
            onChange={(event) => setTypedText(event.target.value)}
            multiline
            InputProps={{
              endAdornment: <InputAdornment position="end">{showAdornment()}</InputAdornment>,
            }}
          />
        </Grid>
        <Grid container item alignItems="center">
          <Grid item>
            <ActionButton
              label="COMMENT"
              disabled={typedText.length == 0}
              clickHandler={handleClickComment}
            />
          </Grid>
          <Grid item className={classes.grow}>
            <Tooltip message="Please start typing your message to enable this button.">
              <HelpIcon style={{ marginLeft: '15px', marginTop: '7px', color: 'gray' }} />
            </Tooltip>
          </Grid>
          <Grid item>
            <input
              style={{ display: 'none' }}
              accept=".jpg,.png,.pdf"
              ref={fileInputRef}
              onChange={(event) => setFileStaged(event.target.files.length > 0)}
              type="file"
            />
            {fileStaged ? (
              <ActionButton
                label="REMOVE FILE"
                icon="ClearIcon"
                color="reject"
                clickHandler={() => setFileStaged(false)}
              />
            ) : (
              <ActionButton
                label="ATTACH FILE"
                icon="AttachFileIcon"
                clickHandler={() => fileInputRef.current.click()}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ReplyBox;
