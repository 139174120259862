import axios from 'axios';
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { addOrRemoveItem } from '../helpers/utils';
import { LoadingStatus } from '../constants';

export const fetchRows = createAsyncThunk('unassigned/fetchRows', async (_, { getState }) => {
  const {
    unassigned: {
      table: { limit, page, sort },
    },
  } = getState();

  const response = await axios.get('/api/sequenceIntervals/unassigned', {
    params: {
      limit,
      page,
      sort,
    },
  });
  return response.data;
});

/**
 * It might be a bit un-intuitive to find the "assigned rows" function in the
 * "unassigned" reducers. The reason is that the reducer covers functionality to
 * assign new tasks to labelers. Sometimes, we want to assign a task twice to
 * check for inter-labeler variability. */
export const fetchRowsAssigned = createAsyncThunk(
  'unassigned/fetchRowsAssigned',
  async (notLabellerId, { getState }) => {
    const {
      unassigned: {
        table: { limit, page, sort },
      },
    } = getState();

    const response = await axios.get('/api/sequenceIntervals/assigned', {
      params: {
        limit,
        page,
        sort,
        not_labeller_id: notLabellerId,
      },
    });
    return response.data;
  }
);

export const fetchLabellers = createAsyncThunk('unassigned/fetchLabellers', async () => {
  const response = await axios.get('/api/users');
  return response.data;
});

const initialState = {
  rows: [],
  rowsById: {},
  status: LoadingStatus.IDLE,
  actionBar: {
    status: LoadingStatus.PENDING,
    selectedLabeller: null,
    labellersById: {},
    labellersByNameLookup: {},
    shouldFetchAssigned: false,
  },
  table: {
    orderBy: null,
    sortOrder: 'asc',
    filteredBy: '',
    filterValue: '',
    selectedRows: [], //  prev: rowsSelected
    totalItems: 0,
    page: 0,
    limit: 50,
    sort: 'DataCollection.name',
  },
};

const unassignedSlice = createSlice({
  name: 'unassigned',
  initialState,
  reducers: {
    setTableState: {
      reducer(state, action) {
        const newState = { ...state.table, ...action.payload };
        state.table = newState;
      },
    },
    setSelectedRow: {
      reducer(state, action) {
        state.table.selectedRows = addOrRemoveItem(state.table.selectedRows, action.payload).sort();
      },
    },
    setAllRowsToSelected: {
      reducer(state) {
        state.table.selectedRows = state.rows.map((row) => row.id);
      },
    },
    clearSelectedRows: {
      reducer(state) {
        state.table.selectedRows = [];
      },
    },
    setSelectedLabeller: {
      reducer(state, action) {
        state.actionBar.selectedLabeller = action.payload;
      },
    },
    unsetSelectedLabeller: {
      reducer(state) {
        state.actionBar.selectedLabeller = null;
      },
    },
    removeItemsById(state, action) {
      state.rows = state.rows.filter((item) => !action.payload.includes(item.id));
    },
    setShouldFetchAssigned(state, action) {
      state.actionBar.shouldFetchAssigned = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRows.pending, (state) => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(fetchRows.fulfilled, (state, action) => {
        const rows = action.payload.sequence_intervals;
        state.rows = rows;
        rows.map((row) => {
          state.rowsById[row.id] = row;
        });
        state.table.totalItems = action.payload.total_items;
        state.status = LoadingStatus.DONE;
      })

      .addCase(fetchRowsAssigned.pending, (state) => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(fetchRowsAssigned.fulfilled, (state, action) => {
        const rows = action.payload.sequence_intervals;
        state.rows = rows;
        rows.map((row) => {
          state.rowsById[row.id] = row;
        });
        state.table.totalItems = action.payload.total_items;
        state.status = LoadingStatus.DONE;
      })

      .addCase(fetchLabellers.pending, (state) => {
        state.labellersByNameStatus = LoadingStatus.PENDING;
      })
      .addCase(fetchLabellers.fulfilled, (state, action) => {
        action.payload.users.forEach((user) => {
          const userObject = {
            id: user.id,
            name: user.name,
            email: user.email,
          };
          state.actionBar.labellersById[user.id] = userObject;
          state.actionBar.labellersByNameLookup[user.name] = user.id;
        });
        state.actionBar.status = LoadingStatus.DONE;
      });
  },
});

export const selectRows = ({ unassigned }) => unassigned.rows;
export const selectStatus = ({ unassigned }) => unassigned.status;
export const selectTableState = ({ unassigned }) => unassigned.table;
export const selectTotalItems = ({ unassigned }) => unassigned.table.totalItems;
export const selectSelectedRows = ({ unassigned }) => unassigned.table.selectedRows;
export const selectSelectedLabeller = ({ unassigned }) => unassigned.actionBar.selectedLabeller;
export const selectLabellersByName = ({ unassigned }) =>
  Object.keys(unassigned.actionBar.labellersByNameLookup);

export const selectLabellersById = ({ unassigned }) => unassigned.actionBar.labellersById;

export const selectLabellerIdByName = createSelector(
  (state) => state.unassigned.actionBar.labellersByNameLookup,
  (_, name) => name,
  (labellersByNameLookup, name) => (name ? labellersByNameLookup[name] : '')
);

export const selectAssignmentButtonReady = createSelector(
  selectSelectedRows,
  selectSelectedLabeller,
  (selectedRows, selectedLabeller) => selectedRows.length > 0 && selectedLabeller
);
export const selectActionBarStatus = ({ unassigned }) => unassigned.actionBar.status;
export const selectRowsById = ({ unassigned }) => unassigned.rowsById;
export const selectActionBar = ({ unassigned }) => unassigned.actionBar;

export const {
  setTableState,
  setSelectedRow,
  clearSelectedRows,
  setAllRowsToSelected,
  setSelectedLabeller,
  unsetSelectedLabeller,
  removeItemsById,
  setShouldFetchAssigned,
} = unassignedSlice.actions;
export default unassignedSlice.reducer;
