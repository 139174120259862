import axios from 'axios';
import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { LoadingStatus } from '../constants';

const initialState = {
  items: [],
  itemsByName: {},
  itemsById: {},
  status: LoadingStatus.IDLE,
};

// async thunk
export const fetchDataCollections = createAsyncThunk(
  'dataCollection/fetchDataCollections',
  async (params) => {
    const response = await axios({
      url: params.url,
      params: params.requestParams,
      method: params.method,
    });
    return response.data;
  }
);

const dataCollectionSlice = createSlice({
  name: 'dataCollection',
  initialState: initialState,
  reducers: {
    clearDataCollections(state) {
      state.dataCollectionsCatgs = [];
      state.status = LoadingStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataCollections.pending, (state) => {
        state.status = LoadingStatus.LOADING;
      })
      .addCase(fetchDataCollections.fulfilled, (state, action) => {
        state.items = [...action.payload.data_collections];
        state.itemsByName = action.payload.data_collections.reduce(
          (acc, { name, id }) => ({ ...acc, [name]: id }),
          state.itemsByName
        );
        state.itemsById = action.payload.data_collections.reduce(
          (acc, { name, id }) => ({ ...acc, [id]: name }),
          state.itemsById
        );
        state.status = LoadingStatus.DONE;
      });
  },
});

/*
 * Selectors
 */
export const selectDataCollectionCatgs = createSelector(
  (state) => state.dataCollection.items,
  (dataCollections) => dataCollections.map((dataCollection) => dataCollection.name)
);

export const selectDataCollectionsByName = (state) => state.dataCollection.itemsByName;
export const selectDataCollectionsById = (state) => state.dataCollection.itemsById;

export const selectLoadingState = (state) => state.dataCollection.status;

/*
 * Action creators and exports
 */
export const { clearDataCollections } = dataCollectionSlice.actions;
export default dataCollectionSlice.reducer;
