import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import RangeInput, { FormNumber } from './RangeInput';
import icons from '../helpers/IconMapping';

const { DeleteIcon, AddIcon } = icons;

export const LandmarkNotPresentAtPropType = PropTypes.shape({
  start: FormNumber,
  end: FormNumber,
});

const useStyles = makeStyles((theme) => ({
  landmarkNotPresentAts: {
    '& .MuiTypography-overline': {
      display: 'block',
    },

    '& .MuiFormControl-root': {
      margin: theme.spacing(0.5),
      minWidth: '8rem',
    },
  },
}));

export function buildLandmarkNotPresentAtState() {
  return {
    start: null,
    end: null,
  };
}

export default function LandmarkNotPresentAts({
  sequenceIntervalStart,
  sequenceIntervalEnd,
  landmarkNotPresentAts,
  onChange,
  className = '',
  disabled = false,
}) {
  const classes = useStyles();

  // NOTE(sven): Always show at least one input field so the user can start
  // typing directly
  if (landmarkNotPresentAts.length == 0) {
    landmarkNotPresentAts = [buildLandmarkNotPresentAtState()];
  }

  function remove(index) {
    const newAts = landmarkNotPresentAts.slice();

    // NOTE(sven): Never remove all input fields. If only one input
    // field is visible, clear it instead of deleting the element
    if (landmarkNotPresentAts.length == 1) {
      newAts[index] = buildLandmarkNotPresentAtState();
    } else {
      newAts.splice(index, 1);
    }

    onChange(newAts);
  }

  function add() {
    const newAts = landmarkNotPresentAts.slice();
    newAts.push(buildLandmarkNotPresentAtState());
    onChange(newAts);
  }

  function update({ start, end }, index) {
    const newAts = landmarkNotPresentAts.slice();
    newAts[index] = { ...landmarkNotPresentAts[index], start, end };
    onChange(newAts);
  }

  const intervalRangeIsSet = sequenceIntervalStart != null && sequenceIntervalEnd != null;

  function withinRange(value) {
    return sequenceIntervalStart <= value && value <= sequenceIntervalEnd;
  }

  return (
    <div className={`${classes.landmarkNotPresentAts} ${disabled ? 'disabled' : ''} ${className}`}>
      <Typography variant="overline">Landmark not present at</Typography>

      {landmarkNotPresentAts.map((landmarkNotPresentAt, index) => (
        <div className="landmark-not-present-at-form" key={index}>
          <RangeInput
            start={landmarkNotPresentAt.start}
            end={landmarkNotPresentAt.end}
            onChange={({ start, end }) => update({ start, end }, index)}
            forceStartError={
              intervalRangeIsSet &&
              landmarkNotPresentAt.start != null &&
              !withinRange(landmarkNotPresentAt.start) &&
              'Start out of interval range'
            }
            forceEndError={
              intervalRangeIsSet &&
              landmarkNotPresentAt.end != null &&
              !withinRange(landmarkNotPresentAt.end) &&
              'End out of interval range'
            }
            disabled={disabled}
          />
          <IconButton onClick={() => remove(index)} disabled={disabled}>
            <DeleteIcon />
          </IconButton>
          {index == landmarkNotPresentAts.length - 1 && (
            <IconButton onClick={() => add(index)} disabled={disabled}>
              <AddIcon />
            </IconButton>
          )}
        </div>
      ))}
    </div>
  );
}

LandmarkNotPresentAts.propTypes = {
  sequenceIntervalStart: PropTypes.number,
  sequenceIntervalEnd: PropTypes.number,
  landmarkNotPresentAts: PropTypes.arrayOf(LandmarkNotPresentAtPropType).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
