import React from 'react';

import Grid from '@material-ui/core/Grid';
import useStyles from './styles/GridBreak';

const defaultProps = {
  direction: 'row',
};

/**
 * GridBreak
 * Invisible element which forces the subsequent Grid item into the next row or
 * column.
 *
 * Default is 'row' because most of the time we want to introduce a line break in a
 * row of elements (horizontal direction). If we our Grid is directed along the
 * y-axis, we can force the next item after this one into the next column. By using
 * this element, we don't have to use CSS properties on our actual displayed
 * elements.
 */
function GridBreak({ direction }) {
  const classes = useStyles();

  if (direction === 'column') {
    return <Grid className={classes.breakColumn} />;
  } else if (direction === 'row') {
    return <Grid className={classes.breakRow} />;
  } else {
    return <></>;
  }
}

GridBreak.defaultProps = defaultProps;

export default GridBreak;
