import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDropzone from 'react-dropzone';
import Snackbar from '@material-ui/core/Snackbar';

import DropzoneImagePreview from './DropzoneImagePreview';
import DropzoneFilePreview from './DropzoneFilePreview';
import useStyles from './styles/Dropzone';

const defaultProps = {
  acceptedFiles: ['image/png', 'image/jpeg'],
  filesLimit: 3,
  maxFileSize: 3000000,
  errorMessages: {
    acceptedFiles: 'File type is not supported.',
    filesLimit: 'Maximun number of files exceeded.',
    maxFileSize: 'File size is too big.',
  },
  dropzoneText: "Drag 'n' Drop or Click",
  showPreview: false,
  dropHandler: null,
};

const NO_PREVIEW = 'no_preview';

/**
 * The dropzone now supports two use cases:
 *  1. It can stage files and delete these staged files. External logic then controls when the staged files (kept in external state) are submitted.
 *  2. It can call a callback to do whatever necessary when a files (or multiple files) are dropped. Usually we want to submit them directly.
 *  IMPORTANT: If we pass in `setFiles' and `files', the state that handles externally staged files, we also want to pass in `handleDelete' since
 *  function removes the files from the preview.
 *
 * Another feature is the preview. We can configure the preview to no show it all, show previews of images and, finally, show a list of filenames.
 * We will not mix file types (like image and pdf) for now, so we configure the type beforehand. This selects the correct preview.
 */
const Dropzone = ({
  files,
  setFiles,
  maxFileSize,
  acceptedFiles,
  filesLimit,
  errorMessages,
  disabled,
  dropzoneText,
  snackbarProps,
  showPreview,
  handleDelete,
  dropHandler,
  styleOverrides,
}) => {
  const classes = useStyles(styleOverrides);
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState('');
  const previewMimetype = acceptedFiles.includes('image') ? 'image' : 'file';

  useEffect(() => {
    if (previewMimetype === 'image') {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    }
  }, [files]);

  function onDrop(newFiles) {
    if (filesLimit && newFiles.length > filesLimit) {
      setSnackbarContent(errorMessages && errorMessages.filesLimit);
      setSnackbarOpen(true);
    } else {
      // if we configured the dropzone to accept images
      if (acceptedFiles.includes('image')) {
        setFiles(
          newFiles.map((file) =>
            Object.assign(file, {
              preview: file.type.includes('image') ? URL.createObjectURL(file) : NO_PREVIEW,
            })
          )
        );
      } else {
        setFiles((files) => files.concat(...newFiles));
      }
    }
  }

  function onDelete(files) {
    // Call calback to remove from staged files
    handleDelete && handleDelete(files);
  }

  function handleDropRejected(rejectedFiles) {
    let message = '';
    rejectedFiles.forEach((rejectedFile) => {
      if (acceptedFiles && !acceptedFiles.includes(rejectedFile.file.type)) {
        message += errorMessages && errorMessages.acceptedFiles;
      }
      if (maxFileSize && rejectedFile.file.size > maxFileSize) {
        message += errorMessages && errorMessages.maxFileSize;
      }
    });
    setSnackbarContent(message);
    setSnackbarOpen(true);
  }

  return (
    <>
      <Snackbar
        {...snackbarProps}
        open={isSnackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={<span>{snackbarContent}</span>}
      />
      <ReactDropzone
        onDrop={dropHandler ? dropHandler : onDrop}
        onDropRejected={handleDropRejected}
        maxSize={maxFileSize}
        disabled={disabled}
        accept={acceptedFiles && acceptedFiles.join(',')}>
        {({ getRootProps, getInputProps }) => (
          <section className={classes.container}>
            <div {...getRootProps({ className: classes.dropzone })}>
              <input {...getInputProps()} />
              <p>{dropzoneText && dropzoneText}</p>
            </div>
          </section>
        )}
      </ReactDropzone>
      {showPreview && previewMimetype === 'image' && (
        <DropzoneImagePreview files={files} onDelete={onDelete} />
      )}
      {showPreview && previewMimetype === 'file' && (
        <DropzoneFilePreview files={files} onDelete={onDelete} />
      )}
    </>
  );
};

Dropzone.defaultProps = defaultProps;

Dropzone.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  setFiles: PropTypes.func,
  handleDelete: PropTypes.func,
  maxFileSize: PropTypes.number,
  acceptedFiles: PropTypes.array,
  filesLimit: PropTypes.number,
  disabled: PropTypes.bool,
  errorMessages: PropTypes.shape({
    acceptedFiles: PropTypes.string,
    filesLimit: PropTypes.string,
    maxFileSize: PropTypes.string,
  }),
  dropzoneText: PropTypes.string,
  snackbarProps: PropTypes.object,
  showPreview: PropTypes.bool,
  dropHandler: PropTypes.func,
  styleOverrides: PropTypes.object,
};

export default Dropzone;
