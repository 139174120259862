import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles/DropzoneFilePreview';
import IconMapping from '../../helpers/IconMapping';
import IconButton from '@material-ui/core/IconButton';

const { ClearIcon } = IconMapping;

/**
 * File preview specifically for files. It only shows a list of staged files.
 */
function DropzoneFilePreview({ files, onDelete }) {
  const classes = useStyles();

  function handleDelete(evt, index) {
    const newFiles = files.filter((_f, i) => i !== index);
    onDelete(newFiles);
  }

  return (
    <div className={classes.container}>
      {files.map((file, idx) => (
        <Grid container key={idx} alignItems="center" style={{ flexWrap: 'nowrap' }}>
          <Grid item>
            <Typography variant="body1">{file.name}</Typography>
          </Grid>
          <Grid item style={{ marginLeft: '0.5rem' }}>
            <IconButton
              onClick={(evt) => {
                handleDelete(evt, idx);
              }}
              size="small">
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </div>
  );
}

DropzoneFilePreview.propTypes = {
  files: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DropzoneFilePreview;
