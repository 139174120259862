import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { uploadFiles } from '../../api';
import { LoadingStatus } from '../../constants';

export const submitForm = createAsyncThunk(
  'submitForm',
  async ({ patientId, files, cancelToken, cancel }, { rejectWithValue }) => {
    let errorObject;
    const requestQueue = [];
    await uploadFiles(files, cancelToken, cancel)
      .then((uploadResponseParameterArray) => {
        axios.post('/api/rawSequences/batch', {
          patient_id: patientId,
          raw_sequences: uploadResponseParameterArray,
        });
      })
      .catch((error) => {
        errorObject = error;
      });

    if (errorObject) {
      return rejectWithValue(errorObject.message);
    } else {
      await axios.all(requestQueue);
    }
  }
);

const initialFormState = {
  dataCollection: '',
  patientId: null,
  patientIndex: null,
};

const initialState = {
  form: initialFormState,
  status: LoadingStatus.IDLE,
};

const addSequencesToPatientFormSlice = createSlice({
  name: 'addSequencesToPatientForm',
  initialState,
  reducers: {
    setFormData: {
      reducer(state, action) {
        state.form = { ...state.form, ...action.payload };
      },
    },
    resetFormData: {
      reducer(state) {
        state.form = initialFormState;
      },
    },
    resetLoadingState: {
      reducer(state) {
        state.status = LoadingStatus.IDLE;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitForm.pending, (state) => {
        state.status = LoadingStatus.LOADING;
      })
      .addCase(submitForm.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(submitForm.fulfilled, (state) => {
        state.form = initialFormState;
        state.status = LoadingStatus.IDLE;
      });
  },
});

export const selectLoadingState = (state) => state.addSequencesToPatientForm.status;

export const {
  setFormData,
  resetFormData,
  resetLoadingState,
} = addSequencesToPatientFormSlice.actions;
export default addSequencesToPatientFormSlice.reducer;
