import React, { useState, useEffect, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const DATA_COLLECTIONS_URL = '/api/dataCollections';

export const AppContext = createContext(null);

export function AppProvider({ children }) {
  const [dataCollections, setDataCollections] = useState([]);

  useEffect(() => {
    fetchDataCollections().then((res) => {
      const names = res.data.data_collections.map((el) => el.name);
      setDataCollections(names);
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        dataCollections,
        setDataCollections,
      }}>
      {children}
    </AppContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.element,
};

export function useApp() {
  return useContext(AppContext);
}

async function fetchDataCollections() {
  return await axios
    .get(DATA_COLLECTIONS_URL)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}
