import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    background: theme.palette.background.navbar.main,
    color: theme.palette.background.navbar.contrastText,
  },
  title: {
    margin: '10px',
  },
  logoImage: {
    width: '30px',
    height: '30px',
    margin: '10px',
  },
  logo: {
    margin: '20px',
  },
  menuLink: {
    // color: theme.palette.link.menu.main,
  },
  brand: {
    cursor: 'pointer',
  },
  menuItem: {
    marginLeft: 10,
    marginRight: 10,
  },
  navLink: {
    color: theme.palette.link.menu.main,
    paddingRight: '2rem', // between links
    '&:visited': {
      color: theme.palette.link.menu.visited,
    },
  },
  navItem: {
    paddingRight: '0.5rem', // between icon and link text
  },
  version: {
    backgroundColor: theme.palette.secondary.light,
  },
}));
