import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants';

export const fetchRows = createAsyncThunk('rawSequences/fetchRows', async (_, { getState }) => {
  const {
    rawSequences: {
      table: { limit, page, sort, filteredBy, filterValue },
    },
  } = getState();
  const params = {
    limit,
    page,
    sort,
  };
  if (filteredBy != '') {
    params[filteredBy] = filterValue;
  }
  const response = await axios.get('/api/rawSequences', {
    params: params,
  });
  return response.data;
});

export const fetchRawSequence = createAsyncThunk('rawSequences/fetchRawSequence', async (id) => {
  const response = await axios.get(`/api/rawSequences/${id}`);
  return response.data;
});

export const submitShouldLabel = createAsyncThunk(
  'rawSequences/submitShouldLabel',
  async ({ data, rowId }) => {
    const response = await axios({
      url: `/api/rawSequences/${rowId}`,
      data,
      method: 'PATCH',
    });
    return response.data;
  }
);

export const submitIsUseless = createAsyncThunk(
  'rawSequences/submitIsUseless',
  async ({ data, rowId }) => {
    const response = await axios({
      url: `/api/rawSequences/${rowId}`,
      data,
      method: 'PATCH',
    });
    return response.data;
  }
);

const initialState = {
  rows: [],
  rowsById: {},
  currentItem: null,
  status: LoadingStatus.IDLE,
  table: {
    orderBy: 'RawSequence.index',
    sortOrder: 'asc',
    filteredBy: '',
    filterValue: '',
    selectedRows: [], //  prev: rowsSelected
    totalItems: 0,
    page: 0,
    limit: 50,
    sort: 'RawSequence.index',
  },
};

const rawSequencesSlice = createSlice({
  name: 'rawSequences',
  initialState,
  reducers: {
    setTableState: {
      reducer(state, action) {
        const newState = { ...state.table, ...action.payload };
        state.table = newState;
      },
    },
    removeItemById: {
      reducer(state, action) {
        const id = action.payload;
        state.rows = state.rows.filter((item) => id !== item.id);
      },
    },
    setFilter: {
      reducer(state, action) {
        state.table.filteredBy = action.payload.filteredBy;
        state.table.filterValue = action.payload.filterValue;
      },
    },
    unsetFilter: {
      reducer(state) {
        state.table.filterValue = '';
        state.table.filteredBy = '';
      },
    },
    resetLoadingState: {
      reducer(state) {
        state.status = LoadingStatus.IDLE;
      },
    },
    resetCurrentItem: {
      reducer(state) {
        state.currentItem = null;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRows.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchRows.fulfilled, (state, action) => {
        state.rows = action.payload.raw_sequences;
        state.rowsById = action.payload.raw_sequences.reduce(
          (acc, curr) => ({ ...acc, [curr.id]: curr }),
          state.rowsById
        );
        state.table.totalItems = action.payload.total_items;
        state.status = LoadingStatus.DONE;
      })

      .addCase(fetchRawSequence.fulfilled, (state, action) => {
        state.currentItem = action.payload;
      })

      .addCase(submitShouldLabel.fulfilled, (state, action) => {
        const { id } = action.payload;
        const arrayIndex = state.rows.findIndex((item) => item.id === id);
        state.rows[arrayIndex] = action.payload;
      })

      .addCase(submitIsUseless.fulfilled, (state, action) => {
        const { id } = action.payload;
        const arrayIndex = state.rows.findIndex((item) => item.id === id);
        state.rows[arrayIndex] = action.payload;
      });
  },
});

export const selectRows = ({ rawSequences }) => rawSequences.rows;
export const selectRowsById = ({ rawSequences }) => rawSequences.rowsById;
export const selectStatus = ({ rawSequences }) => rawSequences.status;
export const selectTableState = ({ rawSequences }) => rawSequences.table;
export const selectTotalItems = ({ rawSequences }) => rawSequences.table.totalItems;
export const selectSelectedRows = ({ rawSequences }) => rawSequences.table.selectedRows;
export const selectCurrentItem = ({ rawSequences }) => rawSequences.currentItem;

export const {
  setTableState,
  removeItemById,
  setFilter,
  unsetFilter,
  resetLoadingState,
  resetCurrentItem,
} = rawSequencesSlice.actions;
export default rawSequencesSlice.reducer;
