import React from 'react';
import Typography from '@material-ui/core/Typography';

import useStyles from '../../styles/NotFound404Styles';

function NotFound404() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div data-test-id="not-found" className={classes.text}>
        <Typography className={classes.font}>404 Not Found :/</Typography>
      </div>
    </div>
  );
}

export default NotFound404;
