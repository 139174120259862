import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '2rem',
    marginBottom: '2rem',
    maxHeight: '10rem',
    overflow: 'auto',
    whiteSpace: 'nowrap',
  },
}));
