import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  backDrop: {
    background: 'rgba(255,255,255,0.2)',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    minWidth: '20rem',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
  },
  closeIcon: {
    alignSelf: 'flex-end',
    marginRight: '-1.8rem',
    marginTop: '-0.8rem',
    marginBottom: '-1rem',
  },
}));
