import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

import ActionButton from './ui/ActionButton';

const useStyles = makeStyles((theme) => ({
  confirmationModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4rem',

    '& .card': {
      maxWidth: '32rem',
      minWidth: '16rem',
      padding: theme.spacing(3),

      '& .caption': {
        paddingBottom: theme.spacing(2),
      },

      '& .button-row': {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
      },
    },
  },
}));

export default function ConfirmationModal({
  children,
  show,
  label = 'Confirm',
  labelColor = 'action',
  onClose,
  onConfirm,
}) {
  const classes = useStyles();

  return (
    <Modal open={show} className={`${classes.confirmationModal}`} onClose={onClose}>
      <Card className="card">
        <div className="caption">{children}</div>
        <div className="button-row">
          <ActionButton label="Cancel" color="neutral" clickHandler={onClose} />
          <ActionButton label={label} color={labelColor} clickHandler={onConfirm} />
        </div>
      </Card>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
