import { makeStyles, lighten } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: (styleOverrides) => styleOverrides.width ?? '14rem',
    height: (styleOverrides) => styleOverrides.height ?? 'inherit',
  },
  dropzone: {
    alignItems: 'center',
    backgroundColor: '#fafafa',
    borderColor: lighten(theme.palette.secondary.dark, 0.5),
    borderRadius: '2px',
    borderStyle: 'dashed',
    borderWidth: '2px',
    color: '#bdbdbd',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
    outline: 'none',
    padding: theme.spacing(2),
    transition: 'border .24s ease-in-out',
  },
}));
