import axios from 'axios';
import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants';

export const submitForm = createAsyncThunk('submitForm', async (_, { getState }) => {
  const {
    addPatientForm: { form },
  } = getState();

  await axios.post('/api/patients/batch', {
    data_collection: form.dataCollection,
    number_of_patients: form.numberOfPatients,
  });
});

const initialFormState = {
  numberOfPatients: '',
  dataCollection: '',
};

const initialState = {
  form: initialFormState,
  status: LoadingStatus.IDLE,
};

const addPatientFormSlice = createSlice({
  name: 'addPatientForm',
  initialState,
  reducers: {
    setFormData: {
      reducer(state, action) {
        state.form = { ...state.form, ...action.payload };
      },
    },
    resetFormData: {
      reducer(state) {
        state.form = initialFormState;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitForm.pending, (state) => {
        state.status = LoadingStatus.LOADING;
      })
      .addCase(submitForm.fulfilled, (state) => {
        state.form = initialFormState;
        state.status = LoadingStatus.IDLE;
      });
  },
});

export const selectNumberOfPatients = ({ addPatientForm }) => addPatientForm.form.numberOfPatients;
export const selectDataCollection = ({ addPatientForm }) => addPatientForm.form.dataCollection;
export const selectIsUserFormInputComplete = createSelector(
  selectNumberOfPatients,
  selectDataCollection,
  (numberOfPatients, dataCollection) => numberOfPatients !== '' && dataCollection !== ''
);

export const { setFormData, resetFormData } = addPatientFormSlice.actions;
export default addPatientFormSlice.reducer;
