import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { buildFileDownloadUrl } from '../../api';
import ActionButton from '../../components/ui/ActionButton';
import {
  selectCurrentItem,
  fetchSequenceInterval,
  resetCurrentItem,
} from '../../reducers/viewData/sequenceIntervals';
import { setFilter, unsetFilter } from '../../reducers/viewData/labels';
import { configurePage, clearPage, setPageName } from '../../reducers/pageSlice';
import { clearView } from '../../reducers/viewSlice';
import { resetTabIndex } from '../../reducers/uiStateSlice';
import TabFactory from '../../components/tabs/TabFactory';
import Infobar from '../../components/infobar/Infobar';
import useStyles from './styles/RowItemDetailView';
import { ScanTypeToStringMap } from '../../constants.js';

const tabs = [
  {
    label: 'Labelling Assignments',
    icon: 'AssignmentIndIcon',
    component: 'ViewData/Labels',
  },
  {
    label: 'Metadata',
    icon: 'DescriptionIcon',
    component: 'TableRowItem/MetadataSequenceInterval',
  },
];

function SequenceIntervalItem() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sequenceInterval = useSelector(selectCurrentItem);
  const { id } = useParams();

  useEffect(() => {
    if (!sequenceInterval) {
      dispatch(fetchSequenceInterval(id));
    }
    dispatch(clearPage());
    dispatch(resetTabIndex());
    dispatch(clearView());
    dispatch(setPageName(''));
    dispatch(configurePage(tabs));
    // FIXME: it would be nice to set this only when we switch tabs. For this the
    // TabFactory needs to be refactored (similar to the way how the TableFactory is partly done)
    // In the new TabFactory we want to pass a `switchAction` to be exectuted when we click
    // a particular tab header
    dispatch(setFilter({ filteredBy: 'sequence_interval_id', filterValue: id }));
    return () => {
      dispatch(resetCurrentItem());
      dispatch(unsetFilter());
    };
  }, []);

  return (
    <>
      {sequenceInterval && (
        <>
          <Infobar heading={`Sequence Interval ${sequenceInterval.id}`} />

          <Grid container className={classes.container} direction="column">
            <Grid item>
              <Grid
                container
                className={classes.infoSection}
                alignItems="center"
                justify="space-around">
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <Box className={classes.infobox}>
                        <Typography>Data Collection:</Typography>
                        <Typography>Patient Index:</Typography>
                        <Typography>Raw Sequence Index:</Typography>
                        <Typography>Scan Type:</Typography>
                      </Box>
                    </Grid>
                    <Grid>
                      <Box className={classes.infobox}>
                        <Link
                          style={{ textDecoration: 'none' }}
                          to={`/viewData/dataCollections/${sequenceInterval.data_collection.id}`}>
                          <Typography>{sequenceInterval.data_collection.name}</Typography>
                        </Link>
                        <Link
                          style={{ textDecoration: 'none' }}
                          to={`/viewData/patients/${sequenceInterval.patient.id}`}>
                          <Typography>{sequenceInterval.patient.index}</Typography>
                        </Link>
                        <Link
                          style={{ textDecoration: 'none' }}
                          to={`/viewData/rawSequences/${sequenceInterval.raw_sequence.id}`}>
                          <Typography>{sequenceInterval.raw_sequence.index}</Typography>
                        </Link>
                        <Typography>
                          {ScanTypeToStringMap[sequenceInterval.raw_sequence.scan_type]}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    className={classes.buttonArray}
                    justify="space-between"
                    direction="column">
                    <Grid item>
                      <ActionButton
                        label="SPLIT SEQUENCE"
                        disabled={!sequenceInterval.file}
                        icon="GetAppIcon"
                        color="action"
                        href={buildFileDownloadUrl(sequenceInterval.file?.id)}
                        style={{ minWidth: '250px', marginBottom: '0.8rem' }}
                      />
                    </Grid>
                    <Grid item>
                      <ActionButton
                        label="SUBSAMPLED SEQUENCE"
                        disabled={!sequenceInterval.subsampled?.file?.file}
                        icon="GetAppIcon"
                        color="action"
                        href={buildFileDownloadUrl(sequenceInterval.subsampled?.file?.id)}
                        style={{ minWidth: '250px' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <TabFactory />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default SequenceIntervalItem;
