import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionButton from '../ui/ActionButton';
import { updateLandmarkNotPresentAt, selectAssignment } from '../../reducers/labellerSlice';
import { apiFetch } from '../../api';
import LandmarkNotPresentAts from '../LandmarkNotPresentAts';

function validateLandmarkNotPresentAts(
  landmarkNotPresentAts,
  sequenceIntervalStart,
  sequenceIntervalEnd
) {
  for (const landmarkNotPresentAt of landmarkNotPresentAts) {
    const { start, end } = landmarkNotPresentAt;
    const isValid =
      (start == null && end == null) ||
      (start <= end &&
        sequenceIntervalStart <= start &&
        start <= sequenceIntervalEnd &&
        sequenceIntervalStart <= end &&
        end <= sequenceIntervalEnd);
    if (!isValid) {
      return false;
    }
  }
  return true;
}

async function updateSubsampledLandmarkNotPresentAts(
  subsampledSequenceIntervalId,
  landmarkNotPresentAts
) {
  return apiFetch(`/api/sequenceIntervals/subsampled/${subsampledSequenceIntervalId}`, 'PATCH', {
    landmark_not_present_ats: landmarkNotPresentAts
      .map((landmarkNotPresentAts) => ({
        start: landmarkNotPresentAts.start,
        end: landmarkNotPresentAts.end,
      }))
      .filter(({ start, end }) => !(start == null && end == null)),
  });
}

export default function LandmarkSection() {
  const dispatch = useDispatch();
  const assignment = useSelector(selectAssignment);

  const sequenceIntervalId = assignment.sequence_interval.id;
  const isSubsampled = assignment.use_subsampled_file;
  const sequenceIntervalStart = 1;
  const sequenceIntervalEnd = isSubsampled
    ? assignment.sequence_interval.subsampled.num_frames
    : assignment.sequence_interval.end;
  const landmarkNotPresentAts = isSubsampled
    ? assignment.sequence_interval.subsampled.landmark_not_present_at
    : assignment.sequence_interval.metadata_dvt.landmark_not_present_at;

  const [isEditable, setEditable] = useState(false);
  const [localLandmarkNotPresentAts, setLocalLandmarkNotPresentAts] = useState(
    landmarkNotPresentAts
  );

  function handleCancel() {
    setLocalLandmarkNotPresentAts(landmarkNotPresentAts);
    setEditable(false);
  }

  async function handleSave(data) {
    const isValid = validateLandmarkNotPresentAts(
      localLandmarkNotPresentAts,
      sequenceIntervalStart,
      sequenceIntervalEnd
    );

    if (isValid) {
      if (isSubsampled) {
        await updateSubsampledLandmarkNotPresentAts(
          assignment.sequence_interval.subsampled.id,
          localLandmarkNotPresentAts
        );
      } else {
        await dispatch(
          updateLandmarkNotPresentAt({
            sequenceIntervalId,
            landmarkNotPresentAtList: localLandmarkNotPresentAts.map((landmarkNotPresentAt) => ({
              ...landmarkNotPresentAt,
              dnc: landmarkNotPresentAt.dnc || false,
            })),
          })
        );
      }

      setEditable(false);
    }
  }

  return (
    <div className={`landmark-section`}>
      <div className="edit-buttons">
        {isEditable ? (
          <>
            <ActionButton
              label="CANCEL"
              color="reject"
              icon="CancelIcon"
              variant="outlined"
              clickHandler={handleCancel}
            />{' '}
            <ActionButton
              label="SAVE"
              color="action"
              icon="SaveIcon"
              variant="outlined"
              clickHandler={handleSave}
            />
          </>
        ) : (
          <ActionButton
            label="EDIT"
            color="action"
            icon="EditIcon"
            variant="outlined"
            clickHandler={() => setEditable(true)}
          />
        )}
      </div>
      <LandmarkNotPresentAts
        className="landmark-not-present-ats"
        sequenceIntervalStart={sequenceIntervalStart}
        sequenceIntervalEnd={sequenceIntervalEnd}
        landmarkNotPresentAts={localLandmarkNotPresentAts}
        onChange={(newLandmarkNotPresentAts) =>
          setLocalLandmarkNotPresentAts(newLandmarkNotPresentAts)
        }
        disabled={!isEditable}
      />
    </div>
  );
}
