import React from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { isPrivilegedUser } from '../helpers/utils';
import { useUser } from '../contexts/UserContext';
import useStyles from '../styles/DashboardSelectionStyles';

function DashboardSelection() {
  const classes = useStyles();
  const { user } = useUser();

  return (
    <Paper elevation={0}>
      <Grid className={classes.content} container direction="column" alignContent="center">
        <Grid item>
          <Button variant="contained" className={classes.button} href={'/labellerDashboard'}>
            Labeller Dashboard
          </Button>
        </Grid>
        {isPrivilegedUser(user.role) && (
          <>
            <Grid item>
              <Button variant="contained" className={classes.button} href={'/supervisorDashboard'}>
                Supervisor Dashboard
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" className={classes.button} href={'dataCurationDashboard'}>
                Data Curation Dashboard
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
}

export default DashboardSelection;
