import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles, lighten, darken } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  alert: {
    width: '100%',
    border: `2px solid transparent`,
    borderRadius: '8px',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&.error': {
      backgroundColor: lighten(theme.palette.general.error, 0.6),
      borderColor: theme.palette.general.error,
    },

    '&.disabled': {
      backgroundColor: lighten(theme.palette.general.disabled, 0.2),
      borderColor: darken(theme.palette.general.disabled, 0.2),
    },

    '&.warning': {
      backgroundColor: lighten(theme.palette.general.warning, 0.6),
      borderColor: theme.palette.general.warning,
    },
  },
}));

export default function AlertBox({ className, children, variant = 'error' }) {
  const classes = useStyles();
  return (
    <div className={`${classes.alert} ${className} ${variant}`}>
      <Typography className="alert-content">{children}</Typography>
    </div>
  );
}

AlertBox.propTypes = {
  variant: PropTypes.oneOf(['error', 'warning', 'disabled']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
