import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import useStyles from './styles/DropzoneImagePreview';

const NO_PREVIEW = 'no_preview';

/**
 * File preview specifically for images. It only shows thumbnails.
 */
function DropzoneImagePreview({ files, onDelete }) {
  const classes = useStyles();

  const handleDelete = (index) => () => {
    const newFiles = files.filter((_f, i) => i !== index);
    onDelete(newFiles);
  };

  return (
    <aside className={classes.thumbsContainer}>
      {files.map((file, index) => (
        <Badge
          key={file.name}
          badgeContent={
            <Fab size="small" className={classes.removeBtn} onClick={handleDelete(index)}>
              <DeleteIcon />
            </Fab>
          }>
          <div className={classes.thumb}>
            <div className={classes.thumbInner}>
              {file.preview === NO_PREVIEW ? (
                <Typography className={classes.nopreview} variant="h6">
                  No Preview
                </Typography>
              ) : (
                <img src={file.preview} className={classes.img} alt="no preview" />
              )}
            </div>
          </div>
        </Badge>
      ))}
    </aside>
  );
}

DropzoneImagePreview.propTypes = {
  files: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DropzoneImagePreview;
