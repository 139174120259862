import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import MuiSnackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  anchorOriginTopCenter: {
    /* Override */
  },
  root: {
    marginTop: '4rem',
  },
}));

function Snackbar({ message, open, setOpen, overrides }) {
  const classes = useStyles();

  function handleClose(evt, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message={message}
      data-test-id="snackbar"
      classes={{ root: classes.root }}
      action={
        <>
          <IconButton size="small" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
      {...overrides}
    />
  );
}

Snackbar.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  overrides: PropTypes.object,
};

export default Snackbar;
