import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { MetadataDVTDatatypeToStringMap } from '../../constants';

export function DVTInfoSection({ anatomy, leg, landmark, type }) {
  return (
    <>
      <Grid container spacing={10}>
        <Grid item>
          <Typography>
            <b>Scan Type: DVT</b>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>{`Anatomy: ${anatomy}`}</Typography>
        </Grid>
        <Grid item>
          <Typography>{`Leg: ${leg ? leg : 'unknown'}`}</Typography>
        </Grid>
        <Grid item>
          <Typography>{`Landmark: ${landmark}`}</Typography>
        </Grid>
        <Grid item>
          <Typography>{`Type: ${MetadataDVTDatatypeToStringMap[type]}`}</Typography>
        </Grid>
      </Grid>
    </>
  );
}

DVTInfoSection.propTypes = {
  anatomy: PropTypes.string.isRequired,
  leg: PropTypes.string,
  landmark: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
};

export function WristInfoSection({ anatomy, wrist, landmark, type }) {
  return (
    <>
      <Grid container spacing={10}>
        <Grid item>
          <Typography>
            <b>Scan Type: WRIST</b>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>{`Anatomy: ${anatomy}`}</Typography>
        </Grid>
        <Grid item>
          <Typography>{`Wrist: ${wrist}`}</Typography>
        </Grid>
        <Grid item>
          <Typography>{`Landmark: ${landmark}`}</Typography>
        </Grid>
        <Grid item>
          <Typography>{`Type: ${MetadataDVTDatatypeToStringMap[type]}`}</Typography>
        </Grid>
      </Grid>
    </>
  );
}

WristInfoSection.propTypes = {
  anatomy: PropTypes.string.isRequired,
  wrist: PropTypes.string.isRequired,
  landmark: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
};
