import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants';

export const fetchRows = createAsyncThunk('labelDVTs/fetchRows', async (_, { getState }) => {
  const {
    labelDVTs: {
      table: { limit, page, sort, filteredBy, filterValue },
    },
  } = getState();
  const response = await axios.get('/api/labelDVTs', {
    params: {
      limit,
      page,
      sort,
      ...{ [filteredBy]: filterValue },
    },
  });
  return response.data;
});

const initialState = {
  rows: [],
  status: LoadingStatus.IDLE,
  table: {
    orderBy: 'SequenceInterval.id',
    sortOrder: 'asc',
    filteredBy: '',
    filterValue: '',
    selectedRows: [], //  prev: rowsSelected
    totalItems: 0,
    page: 0,
    limit: 50,
    sort: 'SequenceInterval.id',
  },
};

const labelDVTSlice = createSlice({
  name: 'labelDVTs',
  initialState,
  reducers: {
    setTableState: {
      reducer(state, action) {
        const newState = { ...state.table, ...action.payload };
        state.table = newState;
      },
    },
    removeItemById: {
      reducer(state, action) {
        const id = action.payload;
        state.rows = state.rows.filter((item) => id !== item.id);
      },
    },
    setFilter: {
      reducer(state, action) {
        state.table.filteredBy = action.payload.filteredBy;
        state.table.filterValue = action.payload.filterValue;
      },
    },
    unsetFilter: {
      reducer(state) {
        state.table.filterValue = '';
        state.table.filteredBy = '';
      },
    },
    resetLoadingState: {
      reducer(state) {
        state.status = LoadingStatus.IDLE;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRows.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchRows.fulfilled, (state, action) => {
        state.rows = action.payload.label_dvts;
        state.table.totalItems = action.payload.total_items;
        state.status = LoadingStatus.DONE;
      });
  },
});

export const selectRows = ({ labelDVTs }) => labelDVTs.rows;
export const selectStatus = ({ labelDVTs }) => labelDVTs.status;
export const selectTableState = ({ labelDVTs }) => labelDVTs.table;
export const selectTotalItems = ({ labelDVTs }) => labelDVTs.table.totalItems;
export const selectSelectedRows = ({ labelDVTs }) => labelDVTs.table.selectedRows;

export const {
  setTableState,
  removeItemById,
  setFilter,
  unsetFilter,
  resetLoadingState,
} = labelDVTSlice.actions;
export default labelDVTSlice.reducer;
