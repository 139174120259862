import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { fetchDataCollections } from '../reducers/dataCollectionSlice';
import { configurePage, clearPage, setPageName } from '../reducers/pageSlice';
import { setRowMultiSelect, clearSelectedRows } from '../reducers/uiStateSlice';

import TabFactory from '../components/tabs/TabFactory';

const DATA_COLLECTIONS_URL = '/api/dataCollections';

function DataCollections() {
  const dispatch = useDispatch();

  const tabs = [
    {
      label: 'Data Collections',
      icon: 'CollectionsBookmarkIcon',
      component: 'ViewData/DataCollections',
    },
    {
      label: 'Patients',
      icon: 'AccessibleForwardIcon',
      component: 'ViewData/Patients',
    },
    {
      label: 'Raw Sequences',
      icon: 'LinearScaleIcon',
      component: 'ViewData/RawSequences',
    },
    {
      label: 'Sequence Intervals',
      icon: 'CallSplitIcon',
      component: 'ViewData/SequenceIntervals',
    },
    {
      label: 'Labels',
      icon: 'LabelIcon',
      component: 'ViewData/Labels',
    },
  ];

  useEffect(() => {
    dispatch(clearPage());
    dispatch(configurePage(tabs));
    dispatch(setPageName('View Data'));
    dispatch(setRowMultiSelect());
    dispatch(clearSelectedRows());
    dispatch(
      fetchDataCollections({
        url: DATA_COLLECTIONS_URL,
        method: 'GET',
      })
    );
  }, []);

  return <TabFactory />;
}

export default DataCollections;
