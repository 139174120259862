import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import iconMapping from '../../helpers/IconMapping';
import { capitalize } from '../../helpers/utils';
import useStyles from './styles/ButtonStyles';

function ActionButton({
  label,
  color,
  variant,
  clickHandler,
  style,
  disabled,
  overrides,
  icon,
  href = null,
  fullWidth = false,
  iconOnRight = false,
}) {
  const Icon = iconMapping[icon];
  let iconProp;
  if (icon && iconOnRight) {
    iconProp = { endIcon: <Icon /> };
  } else if (icon && !iconOnRight) {
    iconProp = { startIcon: <Icon /> };
  } else {
    iconProp = null;
  }
  const classes = useStyles();

  return (
    <Button
      className={classes[`actionButton${color ? capitalize(color) : ''}`]}
      variant={variant ? variant : 'contained'}
      size="small"
      disabled={disabled}
      onClick={clickHandler ? clickHandler : undefined}
      fullWidth={fullWidth}
      style={style}
      href={href}
      {...iconProp}
      {...overrides}>
      {label}
    </Button>
  );
}

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  clickHandler: PropTypes.func,
  icon: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  style: PropTypes.object,
  overrides: PropTypes.object,
  fullWidth: PropTypes.bool,
  iconOnRight: PropTypes.bool,
  href: PropTypes.string,
};

export default ActionButton;
