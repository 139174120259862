import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import history from '../../history';
import { resetTabIndex } from '../../reducers/uiStateSlice';
import { clearPage } from '../../reducers/pageSlice';
import { clearView } from '../../reducers/viewSlice';
import { clearTable } from '../../reducers/tableSlice';
import useStyles from './styles/Navbar';

function NavbarLink({ icon, target, text }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  function handleClick() {
    dispatch(resetTabIndex());
    dispatch(clearPage());
    dispatch(clearView());
    dispatch(clearTable());
    history.push(target);
  }

  return (
    <Button startIcon={icon} onClick={handleClick} className={classes.navLink}>
      {text}
    </Button>
  );
}

NavbarLink.propTypes = {
  icon: PropTypes.element.isRequired,
  target: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default NavbarLink;
