import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { LabelStatus, LabelStatusToStringMap } from '../../constants';
import { isSupervisor } from '../../helpers/utils';
import { buildFileDownloadUrl } from '../../api';
import { updateLabelDVTStatus } from '../../reducers/labellerSlice';
import { useUser } from '../../contexts/UserContext';
import ActionButton from '../ui/ActionButton';
import useStyles from './styles/AssignmentBoard';

function LabelDVTSubmission({ idx, item }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useUser();

  const iconMap = {
    [LabelStatus.REVIEW]: classes.dotIconNeedsReview, // yellowOrange,
    [LabelStatus.ACCEPTED]: classes.dotIconAccepted, // lightGreen,
    [LabelStatus.REJECTED]: classes.dotIconRejected, // red,
  };

  function handleClickApprove() {
    dispatch(updateLabelDVTStatus({ labelDVTId: item.id, status: LabelStatus.ACCEPTED }))
      .then(unwrapResult)
      .catch(() => {
        throw new Error();
      });
  }

  function handleClickRequestChange() {
    dispatch(updateLabelDVTStatus({ labelDVTId: item.id, status: LabelStatus.REJECTED }))
      .then(unwrapResult)
      .catch(() => {
        throw new Error();
      });
  }

  return (
    <Paper className={classes.paperStrip} elevation={4}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <SvgIcon className={iconMap[item.status]} fontSize="large">
            <MoreHorizIcon />
          </SvgIcon>
        </Grid>
        <Grid item className={classes.grow}>
          <Typography component="div">
            <Box fontSize={18}>{`#${idx + 1} - ${LabelStatusToStringMap[LabelStatus.REVIEW]}`}</Box>
          </Typography>
        </Grid>
        {isSupervisor(user.role) && (
          <>
            <Grid item>
              <ActionButton
                label="APPROVE"
                disabled={item.status === LabelStatus.ACCEPTED}
                color="confirm"
                clickHandler={handleClickApprove}
              />
            </Grid>
            <Grid item>
              <ActionButton
                label="REQUEST CHANGE"
                disabled={item.status === LabelStatus.REJECTED}
                color="reject"
                clickHandler={handleClickRequestChange}
              />
            </Grid>
          </>
        )}
        <Grid item>
          <ActionButton
            label="DOWNLOAD"
            color="action"
            icon="GetAppIcon"
            href={buildFileDownloadUrl(item.file.id)}
          />
        </Grid>
        <Grid item>
          <Typography component="div">
            <Box fontSize={10}>{item.created}</Box>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

LabelDVTSubmission.propTypes = {
  idx: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
};

export default LabelDVTSubmission;
