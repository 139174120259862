import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageName: '',
  tabs: {},
  tabHeaders: [],
  actionElements: {},
};

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setPageName: {
      reducer(state, action) {
        state.pageName = action.payload;
      },
    },
    configurePage: {
      reducer(state, action) {
        action.payload.forEach((tab, idx) => {
          state.tabs[idx] = tab;
        });

        state.tabHeaders = action.payload.map((tab) => ({
          label: tab.label,
          icon: tab.icon,
        }));

        action.payload.forEach((el, idx) => {
          if (el.actionBar) {
            state.actionElements[idx] = el.actionBar;
          }
        });
      },
    },
    clearPage: {
      reducer(state) {
        state.pageName = '';
        state.tabs = {};
        state.tabHeaders = [];
        state.actionElements = {};
      },
    },
  },
});

/**
 * Selectors
 */
export const selectTabs = (state) => state.page.tabs;
export const selectTabHeaders = (state) => state.page.tabHeaders;
export const selectPageName = (state) => state.page.pageName;
export const selectActionElements = (state) => state.page.actionElements;

/**
 * Actions and reducer exports
 */
export const { configurePage, clearPage, setPageName } = pageSlice.actions;
export default pageSlice.reducer;
