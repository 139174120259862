import axios from 'axios';
import { memoize } from 'lodash';
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { LoadingStatus } from '../constants';

const initialState = {
  name: '',
  entities: [],
  total_items: 0,
  status: LoadingStatus.IDLE,
};

/**
 * Async thunks
 */
export const fetchTableData = createAsyncThunk(
  'tableData/fetchTableData',
  async ({ url, params, method }) => {
    const response = await axios({
      url,
      params,
      method,
    });
    return response.data;
  }
);

export const submitCell = createAsyncThunk('tableData/submitCell', async ({ url, data, rowId }) => {
  const response = await axios({
    url: `${url}${rowId}`,
    data,
    method: 'PATCH',
  });
  return response.data;
});

const tableDataSlice = createSlice({
  name: 'tableData',
  initialState: initialState,
  reducers: {
    clearTable(state) {
      state.name = '';
      state.entities = [];
      state.total_items = 0;
      state.status = LoadingStatus.IDLE;
    },
    removeItemById(state, action) {
      state.entities = state.entities.filter((item) => action.payload !== item.id);
    },
    removeItemsById(state, action) {
      state.entities = state.entities.filter((item) => !action.payload.includes(item.id));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTableData.pending, (state) => {
        state.status = LoadingStatus.LOADING;
        state.entities = [];
        state.total_items = 0;
      })
      .addCase(fetchTableData.fulfilled, (state, action) => {
        if (!action.payload) {
          return { ...initialState, status: LoadingStatus.DONE };
        }
        state.name = action.payload.name;
        state.entities = action.payload[action.payload.name];
        state.total_items = action.payload.total_items;
        state.status = LoadingStatus.DONE;
      })

      .addCase(submitCell.fulfilled, (state, action) => {
        const { id } = action.payload;
        const arrayIndex = state.entities.findIndex((item) => item.id === id);
        state.entities[arrayIndex] = action.payload;
      });
  },
});

/**
 *  selectors
 */
export const selectTableData = (state) => state.tableData.entities;

/**
 * This selector takes an argument, the table row, and retrieves this row only from the table.
 * We memoize the output function because this break the memoization the createSelector function
 * provides.
 * See: https://github.com/reduxjs/reselect#q-how-do-i-create-a-selector-that-takes-an-argument
 * and https://stackoverflow.com/a/61220891/3154357
 */
export const selectSingleTableRowById = createSelector(selectTableData, (tableRows) =>
  memoize((rowId) => {
    return tableRows.filter((row) => row.id === rowId)[0];
  })
);

export const selectTableRowsById = createSelector(selectTableData, (tableRows) =>
  memoize((rowIds) => {
    return tableRows.filter((row) => rowIds.includes(row.id));
  })
);
export const selectTotalItems = (state) => state.tableData.total_items;
export const selectLoadingState = (state) => state.tableData.status;
export const selectIdOfFirstEntry = (state) => {
  if (state.tableData.entities.length > 0) {
    return state.tableData.entities[0].id;
  } else {
    return null;
  }
};

export const { clearTable, submitUser, removeItemById, removeItemsById } = tableDataSlice.actions;
export default tableDataSlice.reducer;
