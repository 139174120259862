import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addOrRemoveItem } from '../../helpers/utils';
import { LoadingStatus } from '../../constants';

export const fetchRows = createAsyncThunk(
  'stagedForSplitting/fetchRows',
  async (_, { getState }) => {
    const {
      stagedForSplitting: {
        table: { limit, page, sort },
      },
    } = getState();
    const response = await axios.get('/api/sequenceIntervals/unstaged', {
      params: {
        limit,
        page,
        sort,
      },
    });
    return response.data;
  }
);

const initialState = {
  rows: [],
  status: LoadingStatus.IDLE,
  table: {
    orderBy: 'DataCollection.name',
    sortOrder: 'asc',
    filteredBy: '',
    filterValue: '',
    selectedRows: [], //  prev: rowsSelected
    totalItems: 0,
    page: 0,
    limit: 50,
    sort: 'DataCollection.name',
  },
};

const stagedForSplittingSlice = createSlice({
  name: 'stagedForSplitting',
  initialState,
  reducers: {
    setTableState: {
      reducer(state, action) {
        const newState = { ...state.table, ...action.payload };
        state.table = newState;
      },
    },
    setSelectedRow: {
      reducer(state, action) {
        state.table.selectedRows = addOrRemoveItem(state.table.selectedRows, action.payload).sort();
      },
    },
    setAllRowsToSelected: {
      reducer(state) {
        state.table.selectedRows = state.rows.map((row) => row.id);
      },
    },
    clearSelectedRows: {
      reducer(state) {
        state.table.selectedRows = [];
      },
    },
    removeItemById: {
      reducer(state, action) {
        const id = action.payload;
        state.rows = state.rows.filter((item) => id !== item.id);
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRows.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchRows.fulfilled, (state, action) => {
        state.rows = action.payload.raw_sequences;
        state.table.totalItems = action.payload.total_items;
        state.status = LoadingStatus.DONE;
      });
  },
});

export const selectRows = ({ stagedForSplitting }) => stagedForSplitting.rows;
export const selectStatus = ({ stagedForSplitting }) => stagedForSplitting.status;
export const selectTableState = ({ stagedForSplitting }) => stagedForSplitting.table;
export const selectTotalItems = ({ stagedForSplitting }) => stagedForSplitting.table.totalItems;
export const selectSelectedRows = ({ stagedForSplitting }) => stagedForSplitting.table.selectedRows;

export const {
  setTableState,
  removeItemById,
  setSelectedRow,
  setAllRowsToSelected,
  clearSelectedRows,
} = stagedForSplittingSlice.actions;
export default stagedForSplittingSlice.reducer;
