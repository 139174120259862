import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { configurePage, setPageName, clearPage } from '../reducers/pageSlice';
import { setRowMultiSelect } from '../reducers/uiStateSlice';
import { fetchDataCollections } from '../reducers/dataCollectionSlice';
import TabFactory from '../components/tabs/TabFactory';

const DATA_COLLECTIONS_URL = '/api/dataCollections';

function AutomatedJobs() {
  const dispatch = useDispatch();

  const tabs = [
    {
      label: 'Dicom Conversion',
      icon: 'TheatersIcon',
      component: 'AutomatedJobs/DicomConversion',
      actionBar: null,
    },
    {
      label: 'Sequence Splitting',
      icon: 'CallSplitIcon',
      component: 'AutomatedJobs/SequenceSplitting',
      actionBar: null,
    },
    {
      label: 'Autolabel',
      icon: 'ArtTrackIcon',
      component: 'AutomatedJobs/Autolabel',
      actionBar: null,
    },
    {
      label: 'Preprocessing',
      icon: 'SettingsApplicationsIcon',
      component: 'AutomatedJobs/Preprocessing',
      actionBar: null,
    },
  ];

  useEffect(() => {
    dispatch(clearPage());
    dispatch(configurePage(tabs));
    dispatch(setPageName('Automated Jobs'));
    dispatch(setRowMultiSelect());
    dispatch(
      fetchDataCollections({
        url: DATA_COLLECTIONS_URL,
        params: null,
        method: 'GET',
      })
    );
  });

  return <TabFactory />;
}

export default AutomatedJobs;
