import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  heading: {
    paddingTop: '2em',
    paddingBottom: '2em',
  },
  button: {
    '&$disabled': {
      color: theme.palette.primary.dark,
    },
  },
  disabled: {},
}));
