import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { configureModal } from '../../reducers/uiStateSlice';
import { apiFetch } from '../../api';
import iconMapper from '../../helpers/IconMapping';
import GridBreak from '../ui/GridBreak';
import useStyles from './styles/AssignmentBoard';

// NOTE(sven): This way of fetching s3 files is outdated. Should be refactored
// to use the buildDownlaoadUrl function and then load the image source
// directly.
async function fetchPresignedUrl(fileId) {
  return apiFetch('/api/presign', 'POST', { fileId });
}

function CommentBox({ comment }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const AttachFileIcon = iconMapper['AttachFileIcon'];

  const { commenter, content, created, attached_image } = comment;

  function handleAttachmentFileClick() {
    fetchPresignedUrl(attached_image.id).then((res) => {
      dispatch(
        configureModal({
          modalOpen: true,
          component: 'ImagePreview',
          props: {
            url: res.url,
            name: attached_image.original_filename,
          },
        })
      );
    });
  }

  return (
    <Paper className={classes.comment}>
      <Grid container alignItems="flex-end" spacing={1} style={{ flexWrap: 'wrap' }}>
        <Grid item>
          <Typography component="div">
            <Box>{commenter.name}</Box>
          </Typography>
        </Grid>
        <Grid item style={{ flexGrow: 80 }}>
          <Typography component="div" color="textSecondary">
            <Box fontSize={14}>{created}</Box>
          </Typography>
        </Grid>
        {attached_image && (
          <Grid item>
            <IconButton
              color="primary"
              className={classes.hoverFocus}
              component="span"
              onClick={handleAttachmentFileClick}>
              <AttachFileIcon />
            </IconButton>
          </Grid>
        )}
        <GridBreak />
        <Grid item>
          <Typography component="div">
            <Box>{content}</Box>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

CommentBox.propTypes = {
  comment: PropTypes.shape({
    commenter: PropTypes.shape({
      name: PropTypes.string,
    }),
    content: PropTypes.string,
    created: PropTypes.string,
    attached_image: PropTypes.shape({
      id: PropTypes.number,
      original_filename: PropTypes.string,
    }),
  }).isRequired,
};

export default CommentBox;
