import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { LabelStatus, JobStatus, LabelStatusToStringMap } from '../../constants';
import { buildFileDownloadUrl } from '../../api';
import ActionButton from '../ui/ActionButton';
import useStyles from './styles/AssignmentBoard';

function LabelSubmissionAutolabel({ idx, item }) {
  const classes = useStyles();

  // PENDING = 0;
  // INPROGRESS = 1;
  // DONE = 2;
  // FAILED = 3;

  const iconMap = {
    [JobStatus.PENDING]: classes.dotIconInProgress, //  blue,
    [JobStatus.INPROGRESS]: classes.dotIconPending, // blue,
    [JobStatus.DONE]: classes.dotIconDone, // green
    [JobStatus.FAILED]: classes.dotIconFailed, // red,
  };

  return (
    <Paper className={classes.paperStrip} elevation={2}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <SvgIcon className={iconMap[item.autolabel_job.status]} fontSize="large">
            <MoreHorizIcon />
          </SvgIcon>
        </Grid>
        <Grid item className={classes.grow}>
          <Typography component="div">
            <Box fontSize={18}>{`#${idx + 1} - ${
              LabelStatusToStringMap[LabelStatus.AUTOLABEL]
            }`}</Box>
          </Typography>
        </Grid>
        <Grid item>
          <ActionButton label="INPUT" icon="GetAppIcon" href={buildFileDownloadUrl(item.file.id)} />
        </Grid>
        <Grid item>
          <ActionButton
            label="INTERPOLATED"
            icon="GetAppIcon"
            disabled={item.autolabel_job.status !== JobStatus.DONE}
            href={buildFileDownloadUrl(item.autolabel_job.output_file_id)}
          />
        </Grid>
        <Grid item>
          <Typography component="div">
            <Box fontSize={10}>{item.created}</Box>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default LabelSubmissionAutolabel;

LabelSubmissionAutolabel.propTypes = {
  idx: PropTypes.number,
  item: PropTypes.shape({
    id: PropTypes.number,
    autolabel_job: PropTypes.shape({
      status: PropTypes.oneOf(Object.values(JobStatus)).isRequired,
      output_file_id: PropTypes.number.isRequired,
    }).isRequired,
    file: PropTypes.shape({
      id: PropTypes.number,
    }),
    created: PropTypes.string,
  }),
};
