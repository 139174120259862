import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    marginTop: '3rem',
    marginBottom: '5rem',
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },
  sectionDivider: {
    margin: '1.5rem 0 1.5rem 0',
  },
}));
