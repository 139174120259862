import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';

import {
  fetchLabellerAssignment,
  fetchTaskNotifications,
  selectLoadingStatus,
  selectAssignment,
  selectMetadata,
} from '../../reducers/labellerSlice';
import ScreenshotSection from '../../components/ScreenshotSection';
import DownloadSection from '../../components/assignmentBoard/DownloadSection';
import { DVTInfoSection, WristInfoSection } from '../../components/assignmentBoard/InfoSection';
import LabelSubmissionsSection from '../../components/assignmentBoard/LabelSubmissionsSection';
import VesselSection from '../../components/assignmentBoard/VesselSection';
import CommentsSection from '../../components/assignmentBoard/CommentsSection';
import { LoadingStatus, ScanType } from '../../constants';
import useStyle from '../styles/AssignmentBoard';

function LabellingAssignment() {
  const classes = useStyle();
  const dispatch = useDispatch();
  const loading = useSelector(selectLoadingStatus);
  const assignment = useSelector(selectAssignment);
  const metadata = useSelector(selectMetadata);
  let { id } = useParams();

  const scanType = assignment.sequence_interval.scan_type;

  useEffect(() => {
    if (loading !== LoadingStatus.DONE) {
      dispatch(fetchLabellerAssignment(id));
      dispatch(fetchTaskNotifications({ label_task_id: id }));
    }
  }, []);

  function renderInfoSection() {
    let section = null;
    if (scanType === ScanType.DVT) {
      section = <DVTInfoSection {...metadata} />;
    } else if (scanType === ScanType.WRIST) {
      section = <WristInfoSection {...metadata} />;
    }

    if (!section) return null;

    return (
      <>
        {section}
        <Divider className={classes.sectionDivider} />
      </>
    );
  }

  function renderVesselSection() {
    let section = null;
    if (scanType === ScanType.DVT) {
      section = <VesselSection />;
    } else if (scanType === ScanType.WRIST) {
      section = null;
    }

    if (!section) return null;

    return (
      <>
        {section}
        <Divider className={classes.sectionDivider} />
      </>
    );
  }

  function renderScreenshotSection() {
    // HACK(sven): Corinna should not see screenshots. Will be disabled again
    // after double-labelling phase is ended.
    if (assignment.labeller.id == 10) {
      return null;
    }

    return (
      <>
        <ScreenshotSection allowEditing={false} />
        <Divider className={classes.sectionDivider} />
      </>
    );
  }

  if (loading !== LoadingStatus.DONE) {
    return 'Loading...';
  }

  return (
    <div className={classes.root}>
      <DownloadSection />
      <Divider className={classes.sectionDivider} />
      {renderInfoSection()}
      {renderVesselSection()}
      {renderScreenshotSection()}
      <LabelSubmissionsSection />
      <Divider className={classes.sectionDivider} />
      <CommentsSection />
    </div>
  );
}

export default LabellingAssignment;
