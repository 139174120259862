import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { buildFileDownloadUrl } from '../api';
import Spinner from '../components/shared/Spinner';

import './DicomViewer.css';

async function downloadDicomBuffer(fileId) {
  const url = buildFileDownloadUrl(fileId);
  const response = await fetch(url, { cache: 'force-cache' });
  if (response.ok) {
    return await response.arrayBuffer();
  } else {
    const response = await fetch(url, { cache: 'no-cache' });
    if (response.ok) {
      return await response.arrayBuffer();
    } else {
      return null;
    }
  }
}

function isDicomFile(dataView) {
  return (
    dataView.getUint8(0) == 68 && // D
    dataView.getUint8(1) == 73 && // I
    dataView.getUint8(2) == 67 && // C
    dataView.getUint8(3) == 77 //M
  );
}

/**
 * Reads a dicom file from an array buffer. Performs sanity checks about the
 * expected byte format. At the moment, we only support fragment-encapsulated
 * pixel data with basic offset tables, whereby the raw data of each fragment
 * must be of conformant with MIME type `image/jpeg`. Returns null if an error
 * was encountered. Otherwise, returns an array of blob urls, whereby each of
 * these urls can be loaded by a <img> element via its `src` attribute.
 */
function parseDicomFileToObjectUrls(buffer) {
  const dataView = new DataView(buffer, 128);
  if (!isDicomFile(dataView)) {
    console.error('Dicom header not found.');
    return null;
  }

  let offset = 0;
  let pixelDataTagFound = false;
  while (offset < dataView.byteLength - 3) {
    const groupTag = dataView.getUint16(offset, true);
    const elementTag = dataView.getUint16(offset + 2, true);

    if (groupTag == 0x7fe0 && elementTag == 0x0010) {
      pixelDataTagFound = true;
      offset += 4;
      break;
    }

    offset += 1;
  }

  if (!pixelDataTagFound) {
    console.error('Pixel Data tag not found in DICOM file.');
    return null;
  }

  if (
    dataView.getUint8(offset) != 79 || // O
    dataView.getUint8(offset + 1) != 66 // B
  ) {
    console.error('Value Representation of Pixel Data is not Other Byte (OB)');
    return null;
  }

  if (
    dataView.getUint16(offset + 2) != 0x0000 ||
    dataView.getUint32(offset + 4) != 0xffffffff // basic offset table indicator
  ) {
    console.error('Pixel Data section does not have a basic offset table.');
    return null;
  }

  let pixelDataView = new DataView(dataView.buffer, dataView.byteOffset + offset + 8);
  let pixelDataOffset = 0;
  if (pixelDataView.getUint32(pixelDataOffset) != 0xfeff00e0) {
    console.error(`Item tag 0xfffe 0xe000 not found at offset ${pixelDataOffset}.`);
    return null;
  }
  pixelDataOffset += 4;

  // NOTE(sven): A basic offset table contains one entry per frame. Each entry
  // gives the offset into the pixel data view. Each fragment at the given
  // position starts with an item tag (0xfeff00e0) and afterwards a 4 bytes
  // unsigned integer of the length of that fragment. The fragment contains
  // the bytes of an image, e.g. a jpeg file that can be loaded as src into an
  // <img> element. Also, see:
  // https://dicom.nema.org/medical/dicom/current/output/html/part05.html#table_G.6-1
  const basicOffsetTable = [];
  const basicOffsetTableLength = pixelDataView.getUint32(pixelDataOffset, true);
  pixelDataOffset += 4;

  const basicOffsetTableView = new DataView(
    pixelDataView.buffer,
    pixelDataView.byteOffset + pixelDataOffset,
    basicOffsetTableLength
  );
  let botOffset = 0;
  while (botOffset < basicOffsetTableView.byteLength) {
    const fragmentOffset = basicOffsetTableView.getUint32(botOffset, true);
    basicOffsetTable.push(fragmentOffset);
    botOffset += 4;
  }

  const urls = [];
  let fragmentOffset = 0;
  let jpegFrameLength = 0;
  for (let index = 0; index < basicOffsetTable.length; index++) {
    fragmentOffset = pixelDataOffset + basicOffsetTableLength + basicOffsetTable[index];
    if (pixelDataView.getUint32(fragmentOffset) != 0xfeff00e0) {
      console.error(
        `Item tag 0xfffe 0xe000 not found at offset ${fragmentOffset} for JPEG segment ${index}.` +
          ` It is ${pixelDataView.getUint32(fragmentOffset, true).toString(16)}.`
      );
      return null;
    }
    fragmentOffset += 4;

    jpegFrameLength = pixelDataView.getUint32(fragmentOffset, true);
    fragmentOffset += 4;
    if (
      index < basicOffsetTable.length - 1 &&
      jpegFrameLength != basicOffsetTable[index + 1] - 8 - basicOffsetTable[index]
    ) {
      console.error(
        `Item length at offset ${fragmentOffset} for JPEG segment ${index} does not match` +
          ` length computed from offset: ${
            basicOffsetTable[index + 1] - 8 - basicOffsetTable[index]
          }.`
      );
      return null;
    }

    const jpegFrameView = new DataView(
      pixelDataView.buffer,
      pixelDataView.byteOffset + fragmentOffset,
      jpegFrameLength
    );

    // NOTE(sven): Creating these blobs and urls in a hot-loop is potentially
    // slow. I measured ~400ms for 1500 frames. If this is considered a
    // performance impact in the future, we can try to create these blobs on
    // demand.
    const blob = new Blob([jpegFrameView], { type: 'image/jpeg' });
    const url = URL.createObjectURL(blob);
    urls.push(url);
  }

  return urls;
}

export default function ConnectedDicomViewer() {
  let { fileId } = useParams();

  const [dicomBuffer, setDicomBuffer] = useState(null);
  const [dicomFrameBytes, setDicomFrameBytes] = useState([]);
  const [dicomIsValid, setDicomIsValid] = useState(true);
  const [dicomUrls, setDicomUrls] = useState([]);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [frameIndex, setFrameIndex] = useState(0);

  async function fetchDicomBuffer() {
    setDownloadInProgress(true);
    const buffer = await downloadDicomBuffer(fileId);
    setDicomBuffer(buffer);
    setDownloadInProgress(false);
  }

  useEffect(() => {
    fetchDicomBuffer();
  }, []);

  useEffect(() => {
    if (dicomBuffer === null) {
      return;
    }

    let urls = parseDicomFileToObjectUrls(dicomBuffer);
    if (urls === null) {
      setDicomIsValid(false);
    } else {
      setDicomIsValid(true);
      setDicomUrls(urls);
      setFrameIndex(Math.round(urls.length / 2));
    }
  }, [dicomBuffer]);

  return (
    <div className="dicom-viewer">
      {downloadInProgress && (
        <div className="download-progress">
          <Spinner />
          <span>Downloading...</span>
        </div>
      )}
      {!dicomIsValid && (
        <div className="error">
          Oh oh, failed to open file {fileId}!
          <br />
          Send this to sven@thinksono.com for help.
        </div>
      )}
      {dicomIsValid && dicomUrls.length > 0 && !downloadInProgress && (
        <div className="frame-display">
          <img src={dicomUrls[frameIndex]} />
          <span>Frame {frameIndex + 1}</span>
          <div className="frame-controls">
            <button onClick={() => setFrameIndex(Math.max(0, frameIndex - 1))}>Prev</button>
            <input
              type="range"
              min={0}
              max={dicomUrls.length - 1}
              value={frameIndex}
              onChange={(event) => setFrameIndex(parseInt(event.target.value))}
            />
            <button onClick={() => setFrameIndex(Math.min(frameIndex + 1, dicomUrls.length - 1))}>
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
