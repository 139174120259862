import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    margin: '0 auto',
    padding: '0 70px',
    minWidth: '1024px',

    '@media (max-width: 1024px)': {
      padding: `0 ${theme.spacing(1)}`,
    },
  },
}));
