import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoadingStatus } from '../constants';

const initialState = {
  labellersById: {},
  labellersByName: {},
  status: LoadingStatus.IDLE,
};

// async thunk
export const fetchLabellers = createAsyncThunk(
  'supervisorDashboard/fetchLabellers',
  async (params) => {
    const response = await axios({
      url: params.url,
      params: params.requestParams,
      method: params.method,
    });
    return response.data;
  }
);

const supervisorSlice = createSlice({
  name: 'supervisorDashboard',
  initialState: initialState,
  reducers: {
    clearSupervisorDashboard(state) {
      state.labellers = {};
      state.status = LoadingStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLabellers.pending, (state) => {
        state.status = LoadingStatus.LOADING;
      })
      .addCase(fetchLabellers.fulfilled, (state, action) => {
        action.payload.users.forEach((entity) => {
          const user = {
            id: entity.id,
            name: entity.name,
            email: entity.email,
          };
          state.labellersById[entity.id] = user;
          state.labellersByName[entity.name] = user;
        });
        state.status = LoadingStatus.IDLE;
      });
  },
});

/*
 * Selectors
 */
export const selectLabellers = (state) => state.supervisorDashboard.labellersById;

export const selectLabellersByName = (state) => state.supervisorDashboard.labellersByName;

export const selectLoadingState = (state) => state.status === LoadingStatus.LOADING;
/*
 * Action creators and exports
 */
export const { clearSupervisorDashboard } = supervisorSlice.actions;
export default supervisorSlice.reducer;
