import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { fetchComments, selectComments, selectAssignmentId } from '../../reducers/labellerSlice';
import CommentBox from './CommentBox';
import ReplyBox from './ReplyBox';
import useStyles from './styles/AssignmentBoard';

function CommentsSection() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const comments = useSelector(selectComments);
  const label_task_id = useSelector(selectAssignmentId);

  useEffect(() => {
    dispatch(fetchComments(label_task_id));
  }, []);

  function renderComments() {
    return comments.map((comment, idx) => {
      return (
        <Grid item key={idx} style={{ width: '100%' }}>
          <CommentBox comment={comment} />
        </Grid>
      );
    });
  }

  return (
    <>
      <Typography component="div">
        <Box fontWeight="fontWeightBold">Comments</Box>
      </Typography>
      <ReplyBox />
      <Grid
        container
        className={classes.container}
        style={{
          marginTop: '30px',
          overflow: 'auto',
          flexWrap: 'wrap',
        }}
        spacing={2}>
        {renderComments()}
      </Grid>
    </>
  );
}

CommentsSection.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      timeAgo: PropTypes.string.isRequired,
    })
  ),
};

export default CommentsSection;
