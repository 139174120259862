import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import RangeInput, { FormNumber } from './RangeInput';
import icons from '../helpers/IconMapping';

const { DeleteIcon, AddIcon } = icons;

const useStyles = makeStyles((theme) => ({
  vesselClosedAts: {
    display: 'flex',
    flexDirection: 'column',

    '& .MuiTypography-overline': {
      display: 'block',
    },

    '& .MuiFormControl-root': {
      margin: theme.spacing(0.5),
      minWidth: '8rem',
    },

    '& .vessel-closed-at-form': {
      display: 'flex',
      // NOTE(sven): This is exactly the hight of the form helper text when an
      // error occurs.
      marginBottom: 'calc(0.75rem * 1.66 + 4px)',

      '&:not(:last-child)': {
        borderBottom: `1px dotted ${theme.palette.general.disabled}`,
      },

      '& .form-controls': {
        display: 'flex',
        flexDirection: 'column',
      },

      '& .vessel-name': {
        width: '16em',

        '& > :not(.Mui-error)': {
          // NOTE(sven): This is exactly the hight of the form helper text when
          // an error occurs.
          marginBottom: 'calc(0.75rem * 1.66 + 4px)',
        },
      },

      '& .row': {
        display: 'flex',
        gap: theme.spacing(2),
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
}));

export const VesselClosedAtPropType = PropTypes.shape({
  vessel: PropTypes.string.isRequired,
  start: FormNumber,
  end: FormNumber,
  dnc: PropTypes.bool.isRequired,
});

export function buildVesselClosedAtState() {
  return {
    vessel: '',
    start: null,
    end: null,
    dnc: false,
  };
}

function VesselClosedAt({
  sequenceIntervalStart,
  sequenceIntervalEnd,
  vesselClosedAt,
  onChange,
  disabled = false,
}) {
  const vesselIsProvided = vesselClosedAt.vessel != '';
  const startEndNotProvided = vesselClosedAt.start === null && vesselClosedAt.end === null;

  function renderHelperText() {
    if (!vesselIsProvided && (!startEndNotProvided || vesselClosedAt.dnc)) {
      return 'Name required';
    } else if (vesselIsProvided && startEndNotProvided && !vesselClosedAt.dnc) {
      return 'Start / end required or check DNC';
    }
  }

  const helperText = renderHelperText();
  const isValid = !helperText;

  const intervalRangeIsSet = sequenceIntervalStart != null && sequenceIntervalEnd != null;

  function withinRange(value) {
    return sequenceIntervalStart <= value && value <= sequenceIntervalEnd;
  }

  return (
    <div className="form-controls">
      <div className="row">
        <TextField
          className="vessel-name"
          size="small"
          label="Vessel Name"
          variant="outlined"
          value={vesselClosedAt.vessel}
          error={!isValid}
          helperText={helperText}
          onChange={(event) => {
            onChange({ ...vesselClosedAt, vessel: event.target.value });
          }}
          disabled={disabled}
        />
        <div className="dnc">
          <span>DNC:</span>
          <Checkbox
            checked={vesselClosedAt.dnc}
            disabled={disabled}
            onChange={(event) => {
              onChange({ ...vesselClosedAt, dnc: event.target.checked });
            }}
          />
        </div>
      </div>
      <div className="row">
        <RangeInput
          start={vesselClosedAt.start}
          end={vesselClosedAt.end}
          disabled={vesselClosedAt.dnc || disabled}
          onChange={({ start, end }) => {
            onChange({ ...vesselClosedAt, start, end });
          }}
          forceStartError={
            intervalRangeIsSet &&
            vesselClosedAt.start != null &&
            !withinRange(vesselClosedAt.start) &&
            'Start out of interval range'
          }
          forceEndError={
            intervalRangeIsSet &&
            vesselClosedAt.end != null &&
            !withinRange(vesselClosedAt.end) &&
            'End out of interval range'
          }
        />
      </div>
    </div>
  );
}

VesselClosedAt.propTypes = {
  sequenceIntervalStart: PropTypes.number,
  sequenceIntervalEnd: PropTypes.number,
  vesselClosedAt: VesselClosedAtPropType.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default function VesselClosedAts({
  vesselClosedAts,
  className = '',
  onChange,
  sequenceIntervalStart,
  sequenceIntervalEnd,
  disabled = false,
}) {
  const classes = useStyles();

  // NOTE(sven): Always show at least one input field so the user can start
  // typing directly
  if (vesselClosedAts.length == 0) {
    vesselClosedAts = [buildVesselClosedAtState()];
  }

  function remove(index) {
    const newAts = vesselClosedAts.slice();

    // NOTE(sven): Never remove all input fields. If only one input
    // field is visible, clear it instead of deleting the element
    if (vesselClosedAts.length == 1) {
      newAts[index] = buildVesselClosedAtState();
    } else {
      newAts.splice(index, 1);
    }

    onChange(newAts);
  }

  function add() {
    const newAts = vesselClosedAts.slice();
    newAts.push(buildVesselClosedAtState());
    onChange(newAts);
  }

  function update(vesselClosedAt, index) {
    const newAts = vesselClosedAts.slice();
    newAts[index] = vesselClosedAt;
    onChange(newAts);
  }

  return (
    <div className={`${classes.vesselClosedAts} ${disabled ? 'disabled' : ''} ${className}`}>
      <Typography variant="overline" className="vessel-closed-ats-header">
        Vessel closed at:
      </Typography>

      {vesselClosedAts.map((vesselClosedAt, index) => (
        <div className="vessel-closed-at-form" key={index}>
          <VesselClosedAt
            sequenceIntervalStart={sequenceIntervalStart}
            sequenceIntervalEnd={sequenceIntervalEnd}
            vesselClosedAt={vesselClosedAt}
            onChange={(vesselClosedAt) => update(vesselClosedAt, index)}
            disabled={disabled}
          />
          <div className="vessel-closed-at-buttons">
            <IconButton onClick={() => remove(index)} disabled={disabled}>
              <DeleteIcon />
            </IconButton>
            {index == vesselClosedAts.length - 1 && (
              <IconButton onClick={() => add(index)} disabled={disabled}>
                <AddIcon />
              </IconButton>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

VesselClosedAts.propTypes = {
  sequenceIntervalStart: PropTypes.number,
  sequenceIntervalEnd: PropTypes.number,
  vesselClosedAts: PropTypes.arrayOf(VesselClosedAtPropType).isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
