import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    marginTop: 20,
    marginLeft: 140,
    marginRight: 140,
    padding: `0 40px 0 40px`,
  },
  rangeTextfield: {
    width: 80,
    marginLeft: 12,
    marginRight: 12,
  },
  textfield: {
    width: 180,
    marginRight: 12,
  },
  numberfield: {
    width: 80,
    marginRight: 12,
  },
  dncCheckbox: {
    marginLeft: 12,
    marginRight: 12,
  },
  error: {
    color: theme.palette.general.error,
  },
  errorDisplay: {
    marginTop: 6,
    border: '1px solid white',
    borderRadius: 4,
    padding: 3,
  },
  inputRoot: {
    backgroundColor: theme.palette.primary.light,
    '&$disabled': {
      color: 'black',
      backgroundColor: theme.palette.secondary.light,
    },
  },
  disabled: {
    cursor: 'not-allowed',
  },
  infoSection: {},
  screenshotSection: {
    minHeight: '26rem',
  },
  paperStrip: {
    paddingLeft: 40,
    paddingRight: 40,
    backgroundColor: theme.palette.section,
  },
  dotIconAccepted: {
    color: theme.palette.labelIndicator.labelStatus.accepted,
  },
  dotIconRejected: {
    color: theme.palette.labelIndicator.labelStatus.rejected,
  },
  dotIconNeedsReview: {
    color: theme.palette.labelIndicator.labelStatus.needsReview,
  },
  dotIconInProgress: {
    color: theme.palette.labelIndicator.autolabel.inProgress,
  },
  dotIconPending: {
    color: theme.palette.labelIndicator.autolabel.pending,
  },
  dotIconDone: {
    color: theme.palette.labelIndicator.autolabel.done,
  },
  dotIconFailed: {
    color: theme.palette.labelIndicator.autolabel.failed,
  },
  grow: {
    flexGrow: 1,
  },
  comment: {
    padding: 15,
  },
  submissionItem: {
    width: '100%',
  },
  image: {
    width: '18rem',
    height: '18rem',
  },
  circularProgress: {
    height: '18rem',
    width: '18rem',
    display: 'flex',
    justifyContent: 'center',
  },
  // Requires the container to be flexWrap: wrap
  breakRow: {
    flexBasis: '100%',
    height: 0,
  },
  breakColumn: {
    flexBasis: '100%',
    width: 0,
  },
  hoverFocus: {
    // '&:hover, &.Mui-focusVisible': { backgroundColor: 'yellow' },
  },
}));
