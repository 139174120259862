import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';

import {
  fetchLabellerAssignment,
  fetchTaskNotifications,
  selectLoadingStatus,
  selectAssignment,
  selectMetadata,
  resetState,
} from '../../reducers/labellerSlice'; // FIXME: should be top level assignment board slice (shared)
import ScreenshotSection from '../../components/ScreenshotSection';
import LandmarkSection from '../../components/assignmentBoard/LandmarkSection';
import DownloadSection from '../../components/assignmentBoard/DownloadSection';
import LabelSubmissionsSection from '../../components/assignmentBoard/LabelSubmissionsSection';
import VesselSection from '../../components/assignmentBoard/VesselSection';
import { DVTInfoSection, WristInfoSection } from '../../components/assignmentBoard/InfoSection';
import CommentsSection from '../../components/assignmentBoard/CommentsSection';
import ImageQualityScoreSection from '../../components/assignmentBoard/ImageQualityScoreSection';
import { LoadingStatus, ScanType } from '../../constants';
import useStyle from '../styles/AssignmentBoard';

function LabellingAssignment() {
  const classes = useStyle();
  const dispatch = useDispatch();
  const loading = useSelector(selectLoadingStatus);
  const assignment = useSelector(selectAssignment);
  const metadata = useSelector(selectMetadata);
  let { id } = useParams();

  const scanType = assignment.sequence_interval.scan_type;

  useEffect(() => {
    if (loading !== LoadingStatus.DONE) {
      dispatch(fetchLabellerAssignment(id));
      dispatch(fetchTaskNotifications({ label_task_id: id }));
    }
    return () => {
      dispatch(resetState());
    };
  }, []);

  function renderInfoSection() {
    let section = null;
    if (scanType === ScanType.DVT) {
      section = <DVTInfoSection {...metadata} />;
    } else if (scanType === ScanType.WRIST) {
      section = <WristInfoSection {...metadata} />;
    }

    if (!section) return null;

    return (
      <>
        {section}
        <Divider className={classes.sectionDivider} />
      </>
    );
  }

  function renderVesselSection() {
    let section = null;
    if (scanType === ScanType.DVT) {
      section = <VesselSection />;
    } else if (scanType === ScanType.WRIST) {
      section = null;
    }

    if (!section) return null;

    return (
      <>
        {section}
        <Divider className={classes.sectionDivider} />
      </>
    );
  }

  return (
    loading === LoadingStatus.DONE && (
      <div className={classes.root} style={{ marginLeft: 140, marginRight: 140 }}>
        <DownloadSection />
        <Divider className={classes.sectionDivider} />
        {renderInfoSection()}
        <LandmarkSection />
        <ImageQualityScoreSection />
        <Divider className={classes.sectionDivider} />
        {renderVesselSection()}
        <ScreenshotSection allowEditing={true} />
        <Divider className={classes.sectionDivider} />
        <LabelSubmissionsSection />
        <Divider className={classes.sectionDivider} />
        <CommentsSection />
      </div>
    )
  );
}

export default LabellingAssignment;
