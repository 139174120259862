import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import MUIModal from '@material-ui/core/Modal';

import IconMapping from '../../helpers/IconMapping';
import { destroyModal, selectModalComponent } from '../../reducers/uiStateSlice';
import useStyles from './styles/ModalStyles';

function dynamicImport(component) {
  return lazy(() => import(`./modals/${component}`).catch(() => import('../shared/NullElement')));
}

function Modal({ isOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const body = useSelector(selectModalComponent);
  const { CloseIcon } = IconMapping;

  const handleClose = () => {
    dispatch(destroyModal());
  };

  function importBody(component) {
    const Component = dynamicImport(component);
    return <Component />;
  }

  return (
    <MUIModal
      open={isOpen}
      BackdropProps={{ classes: { root: classes.backDrop } }}
      disableBackdropClick>
      <div className={classes.paper}>
        <IconButton className={classes.closeIcon} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Suspense fallback={<></>}>{importBody(body)}</Suspense>
      </div>
    </MUIModal>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
};

export default Modal;
