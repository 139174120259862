import React, { useContext, createContext } from 'react';
import { useAuth } from './AuthContext';

export const UserContext = createContext({});

export function UserProvider(props) {
  let { state } = useAuth();
  return <UserContext.Provider {...props} value={state} />;
}

export const useUser = () => useContext(UserContext);
