import React, { lazy, Suspense } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { selectTabIndex } from '../../reducers/uiStateSlice';
import { selectActionElements } from '../../reducers/pageSlice';
import useStyles from '../../styles/ActionBar/ActionBarStyles';

import Grid from '@material-ui/core/Grid';

function importActionElement(element) {
  return lazy(() => import(`../actionbar/${element}`).catch(() => import('../shared/NullElement')));
}

/**
 * ActionBar
 *
 * Renders a config object in the form:
 *
 *    actionBar: [
 *      {
 *        type: 'button',
 *        component: 'AddDataCollectionButton',
 *      },
 *      {
 *        type: 'button',
 *        component: 'DeleteDataCollectionButton',
 *      },
 *    ],
 */
function ActionBar() {
  const actionElements = useSelector(selectActionElements);
  const tabIdx = useSelector(selectTabIndex);

  const classes = useStyles();

  function buildActionElements(item, idx) {
    const Component = importActionElement(item.component);
    return (
      <Grid item key={idx} className={classes.container}>
        <Suspense fallback={<></>}>
          <Component {...item.props} />
        </Suspense>
      </Grid>
    );
  }

  return (
    <Grid container alignItems="center">
      {!isEmpty(actionElements) && actionElements[tabIdx] !== undefined
        ? actionElements[tabIdx].map((actionElement, idx) =>
            buildActionElements(actionElement, idx)
          )
        : undefined}
    </Grid>
  );
}

export default ActionBar;
