import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import SpeedIcon from '@material-ui/icons/Speed';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import { LABELLER_LINK_GDRIVE, VERSION } from '../../constants';
import { isSupervisor, isPrivilegedUser, isLabeller } from '../../helpers/utils';
import {
  getNumberOfNotifications,
  selectNumberOfUnseenNotifications,
} from '../../reducers/labellerSlice';
import history from '../../history';
import iconMapping from '../../helpers/IconMapping';
import NavbarLink from './NavbarLink';
import Brand from './Brand';
import { useAuth } from '../../contexts/AuthContext';
import { useUser } from '../../contexts/UserContext';
import useStyles from './styles/Navbar';

function Navbar() {
  const { user } = useUser();
  const dispatch = useDispatch();
  const classes = useStyles();
  const unseenNotifications = useSelector(selectNumberOfUnseenNotifications);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { logout } = useAuth();
  const NotificationIcon = iconMapping['NotificationsIcon'];
  const NotificationsActiveIcon = iconMapping['NotificationsActiveIcon'];
  const CloudIcon = iconMapping['CloudIcon'];

  useEffect(() => {
    dispatch(getNumberOfNotifications());
  });

  function handleMenu(evt) {
    setAnchorEl(evt.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar id="toolbar" className={classes.toolbar}>
          <Grid container data-cy="navbar" alignItems="center">
            <Grid item>
              <Brand />
            </Grid>
            <Grid item style={{ flexGrow: 90 }}></Grid>
            {isPrivilegedUser(user.role) && (
              <>
                <Grid item>
                  <NavbarLink
                    icon={<SpeedIcon />}
                    text="Automated Jobs"
                    target={'/automatedJobs'}
                  />
                </Grid>
                <Grid item>
                  <NavbarLink
                    icon={<EqualizerIcon />}
                    text="View Data"
                    target={'/dataCollections'}
                  />
                </Grid>
              </>
            )}
            {isLabeller(user.role) && (
              <Grid item>
                <Button
                  startIcon={<CloudIcon />}
                  target="_blank"
                  href={LABELLER_LINK_GDRIVE}
                  className={classes.navLink}>
                  Labelling Resources
                </Button>
              </Grid>
            )}
            <Grid item>
              <NavbarLink
                icon={<DashboardIcon />}
                text="Dashboard"
                target={isSupervisor(user.role) ? '/supervisor/dashboard' : '/label/dashboard'}
              />
            </Grid>
            <Grid item>
              <IconButton onClick={() => history.push('/notifications')} color="inherit">
                {unseenNotifications > 0 ? <NotificationsActiveIcon /> : <NotificationIcon />}
              </IconButton>
            </Grid>
            <Grid>
              <Grid item>
                <IconButton onClick={handleMenu} color="inherit">
                  <AccountCircle />
                </IconButton>
                <Menu
                  className={classes.menu}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}>
                  <Typography data-cy="username" align="center" variant="subtitle2">
                    {user.name}
                    <Divider />
                  </Typography>
                  <MenuItem className={classes.version}>{`Version: ${VERSION}`}</MenuItem>
                  <MenuItem
                    data-cy="account"
                    className={classes.menuItem}
                    onClick={handleClose}
                    component={Link}
                    to="/">
                    Account
                  </MenuItem>
                  <MenuItem data-cy="logout" className={classes.menuItem} onClick={logout}>
                    Logout
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Navbar;
