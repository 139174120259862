import axios from 'axios';
import { generateFileHash } from './helpers/utils';

/**
 * Hit JSON-based backend routes.
 */
export async function apiFetch(url, method = 'GET', body = null) {
  const response = await fetch(url, {
    method,
    body: body && JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  });

  const json = response.json();
  if (response.ok) {
    return json;
  } else {
    return Promise.reject(await json);
  }
}

/**
 * Builds a GET url to a backend endpoint which supports the filter-sort-page
 * parameter syntax. See backend/tscloud/util.py:filter_sort_page
 *
 * @param {string} baseUrl A domain or relative url to append the url parameters
 *   to. Assumes that baseUrl does not have any parameters defined already.
 * @param {object} filters An object of key-value pairs which will be attached
 *   as url paremters verbatim.
 * @param {number} limit The maximum number of items to return per page.
 * @param {number} page The page to retrieve.
 * @param {string} orderBy A database column identifier to sort the backend request by.
 * @param {string} sortOrder Sorts in ascending order when `asc`, otherwise in
 *   descending order.
 */
export function filterPageSortUrl(baseUrl, { filters, limit, page, orderBy, sortOrder }) {
  const params = new URLSearchParams();
  if (filters) {
    for (const [key, value] of Object.entries(filters)) {
      params.set(key, value);
    }
  }

  if (limit !== undefined) params.set('limit', limit);
  if (page !== undefined) params.set('page', page);
  if (orderBy !== undefined && orderBy !== '') {
    const direction = sortOrder === 'asc' ? '' : '-';
    params.set('sort', `${direction}${orderBy}`);
  }

  return baseUrl + `?${params.toString()}`;
}

/**
 * Querying this GET route will download the file asset from S3. Intended use as
 * href in an anchor tag.
 */
export function buildFileDownloadUrl(fileId) {
  return `/api/presign/download/${fileId}`;
}

/**
 * Uploads a file into the backend's configured S3 bucket and returns the id of
 * the newly created file entity.
 * @param {File} file - A file retrieved from an input or drag&drop
 *   operation. See http://developer.mozilla.org/en-US/docs/Web/API/File.
 * @returns {number|null} id of the S3File entity in the backend or null if an
 *   error occured
 */
export async function uploadFile(file) {
  try {
    const { url, file_id } = await apiFetch('/api/presign/put-url', 'POST', {
      filename: file.name,
    });
    const uploadResponse = await fetch(url, { method: 'PUT', body: file });
    if (!uploadResponse.ok) {
      return null;
    }

    return file_id;
  } catch (error) {
    // NOTE(sven): The upload failed, i.e. due to interrupted internet connection
    console.error(error);
    return null;
  }
}

export function getCancelToken() {
  return axios.CancelToken.source();
}

/**
 * @typedef {Object} S3FileParams
 * @property {string} bucket name of the bucket
 * @property {string} file hashed filename
 * @property {string} original_filename file name of the original file
 *
 * @param {Array} fileArray of files staged for upload
 * @param {string} cancelToken used by axios to provide a handle for cancelling the upload
 * @param {function} cancel cancels the upload
 * @returns {Array.<S3FileParams>} array of S3File parameters needed by the backend to create file objects.
 */
export async function uploadFiles(fileArray, cancelToken, cancel) {
  function fetchPresignedPost() {
    // TODO(sven): This function needs to be refactored to use the PUT url based
    // uploads, though I can't reproduce it's usage as the ActionBar elements
    // for the data collection don't get rendered correctly.
    throw new Error('NOT IMPLEMENTED');
  }

  // We normalize the fileArray to ensure the same order in the uploadResponseParameters
  const normalizedFileArray = fileArray.reduce((acc, curr, idx) => {
    return {
      ...acc,
      [idx]: curr,
    };
  }, {});

  const requestQueue = [];
  let errorMessage = null;
  const uploadResponseParameters = {};

  for (const [index, file] of Object.entries(normalizedFileArray)) {
    const hashedFilename = generateFileHash(file.name);
    await fetchPresignedPost(hashedFilename).then(async (res) => {
      const params = res.data.postParams;

      let formData = new FormData();

      Object.keys(params.fields).forEach((key) => {
        formData.append(key, params.fields[key]);
      });

      formData.append('file', file);

      requestQueue.push(
        axios
          .post(params.url, formData, {
            cancelToken,
            headers: {
              Accept: file.type,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            if (res.status === 204) {
              uploadResponseParameters[index] = {
                bucket: res.config.url,
                file: hashedFilename,
                original_filename: file.name,
              };
            }
          })
          .catch((err) => {
            if (err.isAxiosError) {
              errorMessage = err;
            } else {
              errorMessage = new Error(err.message);
              cancel();
            }
          })
      );
    });
  }
  await axios.all(requestQueue);
  if (errorMessage) return Promise.reject(errorMessage);
  return Object.values(uploadResponseParameters);
}
