import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { apiFetch } from '../../api';
import ActionButton from '../ui/ActionButton';
import VesselClosedAts from '../VesselClosedAts';

import {
  updateVesselClosed,
  selectVesselClosedList,
  selectSequenceIntervalId,
  selectAssignment,
} from '../../reducers/labellerSlice';

const useStyles = makeStyles((theme) => ({
  vesselSection: {
    '& .vessel-closed-ats': {
      '& .row': {
        width: '100%',
      },
    },
  },
}));

function validateVesselClosedAts(vesselClosedAts, sequenceIntervalStart, sequenceIntervalEnd) {
  for (const vesselClosedAt of vesselClosedAts) {
    const { vessel, start, end, dnc } = vesselClosedAt;
    const isValid =
      (vessel == '' && start == null && end == null && !dnc) ||
      (vessel.length > 0 &&
        (dnc ||
          (start <= end &&
            sequenceIntervalStart <= start &&
            start <= sequenceIntervalEnd &&
            sequenceIntervalStart <= end &&
            end <= sequenceIntervalEnd)));
    if (!isValid) {
      return false;
    }
  }

  return true;
}

async function updateSubsampledVesselClosedAts(subsampledSequenceIntervalId, vesselClosedAts) {
  return apiFetch(`/api/sequenceIntervals/subsampled/${subsampledSequenceIntervalId}`, 'PATCH', {
    vessel_closed_ats: vesselClosedAts
      .map((vesselClosedAt) => ({
        vessel: vesselClosedAt.vessel,
        start: vesselClosedAt.start,
        end: vesselClosedAt.end,
        dnc: vesselClosedAt.dnc,
      }))
      .filter(
        ({ vessel, start, end, dnc }) => !(vessel == '' && start == null && end == null && !dnc)
      ),
  });
}

function VesselSection() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const assignment = useSelector(selectAssignment);

  const sequenceIntervalId = assignment.sequence_interval.id;
  const isSubsampled = assignment.use_subsampled_file;
  const sequenceIntervalStart = 1;
  const sequenceIntervalEnd = isSubsampled
    ? assignment.sequence_interval.subsampled.num_frames
    : assignment.sequence_interval.end;
  const vesselClosedAts = isSubsampled
    ? assignment.sequence_interval.subsampled.vessel_closed_at
    : assignment.sequence_interval.metadata_dvt.vessel_closed_at;

  const [isEditable, setEditable] = useState(false);
  const [localVesselClosedAts, setLocalVesselClosedAts] = useState(vesselClosedAts);

  function handleCancel() {
    setLocalVesselClosedAts(vesselClosedAts);
    setEditable(false);
  }

  async function handleSave() {
    const isValid = validateVesselClosedAts(
      localVesselClosedAts,
      sequenceIntervalStart,
      sequenceIntervalEnd
    );

    if (isValid) {
      if (isSubsampled) {
        await updateSubsampledVesselClosedAts(
          assignment.sequence_interval.subsampled.id,
          localVesselClosedAts
        );
      } else {
        await dispatch(
          updateVesselClosed({
            sequenceIntervalId,
            vesselClosedList: localVesselClosedAts,
          })
        );
      }

      setEditable(false);
    }
  }

  return (
    <div className={`${classes.vesselSection} vessel-section`}>
      <div className="edit-buttons">
        {isEditable ? (
          <>
            <ActionButton
              label="CANCEL"
              color="reject"
              icon="CancelIcon"
              variant="outlined"
              clickHandler={handleCancel}
            />{' '}
            <ActionButton
              label="SAVE"
              color="action"
              icon="SaveIcon"
              variant="outlined"
              clickHandler={handleSave}
            />
          </>
        ) : (
          <ActionButton
            label="EDIT"
            color="action"
            icon="EditIcon"
            variant="outlined"
            clickHandler={() => setEditable(true)}
          />
        )}
      </div>
      <VesselClosedAts
        className="vessel-closed-ats"
        sequenceIntervalStart={sequenceIntervalStart}
        sequenceIntervalEnd={sequenceIntervalEnd}
        vesselClosedAts={localVesselClosedAts}
        onChange={(newVesselClosedAts) => setLocalVesselClosedAts(newVesselClosedAts)}
        disabled={!isEditable}
      />
    </div>
  );
}

export default VesselSection;
