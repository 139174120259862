import React from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { resetTabIndex } from '../../reducers/uiStateSlice';
import NavbarLogo from '../../img/logo-thinksono.png';
import history from '../../history';
import useStyles from './styles/Navbar';

function Brand() {
  const dispatch = useDispatch();
  const classes = useStyles();

  function handleClick() {
    history.push('/');
    dispatch(resetTabIndex());
  }

  return (
    <Grid container className={classes.brand} onClick={handleClick}>
      <Grid item>
        <img data-cy="brand-icon" className={classes.logoImage} src={NavbarLogo} alt="brand-icon" />
      </Grid>
      <Grid item data-cy="brand-text">
        <Typography className={classes.title} variant="h6">
          ThinkSono Cloud
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Brand;
