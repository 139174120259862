import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Notifications from './pages/Notifications';
import DashboardSelection from './pages/DashboardSelection';
import DataCurationDashboard from './pages/DataCurationDashboard';
import LabellerDashboard from './pages/LabellerDashboard';
import SupervisorDashboard from './pages/SupervisorDashboard';
import DataCollections from './pages/DataCollections';
import SplitSequences from './pages/SplitSequences';
import AutomatedJobs from './pages/AutomatedJobs';
import DataCollectionItem from './pages/rowItemDetailViews/DataCollectionItem';
import PatientItem from './pages/rowItemDetailViews/PatientItem';
import RawSequenceItem from './pages/rowItemDetailViews/RawSequenceItem';
import SequenceIntervalItem from './pages/rowItemDetailViews/SequenceIntervalItem';
import AssignmentBoardLabeller from './pages/labeller/AssignmentBoard';
import AssignmentBoardSupervisor from './pages/supervisor/AssignmentBoard';
import DicomViewer from './pages/DicomViewer';
import Navbar from './components/navbar/Navbar';
import NotFound404 from './components/shared/NotFound404';
import Login from './pages/Login';
import Modal from './components/ui/Modal';
import { UserProvider, useUser } from './contexts/UserContext';
import { AuthProvider } from './contexts/AuthContext';
import { AppProvider } from './contexts/AppContext';
import withTheme from './theme';
import useStyles from './styles/AppStyles';
import history from './history';

function AuthenticatedApp() {
  const classes = useStyles();
  const isOpen = useSelector((state) => state.ui.modal.modalOpen);

  return (
    <Router history={history}>
      <Navbar />
      <Modal isOpen={isOpen} />
      <div className={classes.container}>
        <Switch>
          <Route exact path={'/'} render={() => <DashboardSelection />} />
          <Route exact path={'/notifications'} render={(props) => <Notifications {...props} />} />
          <Route
            exact
            path={'/dashboardSelection'}
            render={(props) => <DashboardSelection {...props} />}
          />
          <Route
            exact
            path={'/supervisorDashboard'}
            render={(props) => <SupervisorDashboard {...props} />}
          />
          <Route
            exact
            path={'/labellerDashboard'}
            render={(props) => <LabellerDashboard {...props} />}
          />
          <Route
            exact
            path={'/labellerDashboard/tasks/:id'}
            render={(props) => <AssignmentBoardLabeller {...props} />}
          />
          <Route
            exact
            path={'/supervisorDashboard/tasks/:id'}
            render={(props) => <AssignmentBoardSupervisor {...props} />}
          />
          <Route
            exact
            path={'/dataCurationDashboard'}
            render={(props) => <DataCurationDashboard {...props} />}
          />
          <Route
            exact
            path={'/dataCurationDashboard/splitTasks/:id'}
            render={(props) => <SplitSequences {...props} />}
          />
          <Route
            exact
            path={'/dataCollections'}
            render={(props) => <DataCollections {...props} />}
          />
          <Route exact path={'/automatedJobs'} render={(props) => <AutomatedJobs {...props} />} />

          <Route
            exact
            path={'/viewData/dataCollections/:id'}
            render={(props) => <DataCollectionItem {...props} />}
          />
          <Route
            exact
            path={'/viewData/patients/:id'}
            render={(props) => <PatientItem {...props} />}
          />
          <Route
            exact
            path={'/viewData/rawSequences/:id'}
            render={(props) => <RawSequenceItem {...props} />}
          />
          <Route
            exact
            path={'/viewData/sequenceIntervals/:id'}
            render={(props) => <SequenceIntervalItem {...props} />}
          />
          <Route
            exact
            path={'/viewData/tasks/:id'}
            render={(props) => <AssignmentBoardSupervisor {...props} />}
          />
          <Route
            exact
            path={'/dicomViewer/:fileId'}
            render={(props) => <DicomViewer {...props} />}
          />
          <Route component={NotFound404} />
        </Switch>
      </div>
    </Router>
  );
}

function LoginManager() {
  const { user } = useUser();
  return user ? (
    <AppProvider>
      <AuthenticatedApp />
    </AppProvider>
  ) : (
    <Login />
  );
}

function App() {
  return (
    <AuthProvider>
      <UserProvider>
        <LoginManager />
      </UserProvider>
    </AuthProvider>
  );
}

export default withTheme(App);
