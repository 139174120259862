import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { configurePage, setPageName, clearPage } from '../reducers/pageSlice';
import { unsetRowMultiSelect, clearSelectedRows } from '../reducers/uiStateSlice';
import { TaskStatus } from '../constants.js';
import TabFactory from '../components/tabs/TabFactory';

function LabellerDashboard() {
  const dispatch = useDispatch();

  const tabs = [
    {
      label: 'To Do',
      icon: 'CreateIcon',
      component: 'LabellerDashboardTab',
      props: {
        taskStatus: TaskStatus.TODO,
      },
    },
    {
      label: 'Pending',
      icon: 'ScheduleIcon',
      component: 'LabellerDashboardTab',
      props: {
        taskStatus: TaskStatus.PENDING,
      },
    },
    {
      label: 'Finished',
      icon: 'DoneIcon',
      component: 'LabellerDashboardTab',
      props: {
        taskStatus: TaskStatus.FINISHED,
      },
    },
  ];

  useEffect(() => {
    dispatch(clearPage());
    dispatch(clearSelectedRows());
    dispatch(configurePage(tabs));
    dispatch(unsetRowMultiSelect());
    dispatch(setPageName('Labeller Dashboard'));
  });

  return <TabFactory />;
}

export default LabellerDashboard;
