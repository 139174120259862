import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, lighten, darken } from '@material-ui/core/styles';

import history from '../history';
import Infobar from '../components/infobar/Infobar';
import ActionButton from '../components/ui/ActionButton';
import { ScreenshotSection } from '../components/ScreenshotSection';
import Spinner from '../components/shared/Spinner';
import Snackbar from '../components/ui/Snackbar';
import AlertBox from '../components/AlertBox';
import RangeInput, { FormNumber } from '../components/RangeInput';
import ImageQualityScore from '../components/ImageQualityScore';
import VesselClosedAts, { VesselClosedAtPropType } from '../components/VesselClosedAts';
import LandmarkNotPresentAts, {
  LandmarkNotPresentAtPropType,
} from '../components/LandmarkNotPresentAts';
import { buildFileDownloadUrl, apiFetch } from '../api';
import {
  ScanTypeToStringMap,
  ANATOMY_CATEGORIES,
  LEG_CATEGORIES,
  ExamDatatypeToStringMap,
  WRIST_CATEGORIES,
  DeviceToStringMap,
  LANDMARK_OPTIONS,
  ScanType,
  ExamDatatype,
  Device,
  Region,
  RegionToStringMap,
} from '../constants';
import icons from '../helpers/IconMapping';
import SimpleSelect from '../components/SimpleSelect';
import ConfirmationModal from '../components/ConfirmationModal';

const { DeleteIcon, AddIcon } = icons;

//
// PropTypes
//

const SequenceIntervalPropType = PropTypes.shape({
  start: PropTypes.number,
  end: PropTypes.number,
  anatomy: PropTypes.string,
  landmarks: PropTypes.arrayOf(PropTypes.oneOf(LANDMARK_OPTIONS)),
  type: PropTypes.oneOf(Object.values(ExamDatatype)),
  device: PropTypes.oneOf(Object.values(Device)),
  imageQualityScore: PropTypes.number,
  screenshotFileId: PropTypes.number,
  landmarkNotPresentAts: PropTypes.arrayOf(LandmarkNotPresentAtPropType).isRequired,
  leg: PropTypes.string,
  vesselClosedAts: PropTypes.arrayOf(VesselClosedAtPropType).isRequired,
  wrist: PropTypes.string,
});

//
// Styles
//

const useStyles = makeStyles((theme) => ({
  splitSequences: {
    maxWidth: '64rem',
    margin: '0 auto',

    '& .row': {
      padding: theme.spacing(1),
      width: '100%',

      '& > h6': {
        marginBottom: theme.spacing(1),
      },
    },

    '& .form-wrapper': {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'flex-start',
      width: '100%',
      marginBottom: theme.spacing(2),
    },

    '& .sequence-interval-form': {
      // display: 'flex',
      // flexDirection: 'column',
      padding: theme.spacing(2),
      marginLeft: theme.spacing(1),
      border: `1px solid ${theme.palette.general.black}`,

      '& .MuiTypography-overline': {
        display: 'block',
      },

      '& .MuiFormControl-root': {
        margin: theme.spacing(0.5),
        minWidth: '8rem',
      },

      '& .screenshot-section section': {
        width: '100%',
      },
    },
  },

  sequenceIntervals: {
    '& > header': {
      backgroundColor: darken(theme.palette.secondary.light, 0.1),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),
    },

    '& > section': {
      display: 'flex',
      flexDirection: 'column',
    },

    '& .sequence-interval': {
      padding: theme.spacing(2),
      margin: theme.spacing(1),
      position: 'relative',
      border: '2px solid transparent',
      cursor: 'pointer',
      width: '16rem',

      '& h6': {
        paddingRight: theme.spacing(5),
      },

      '&.selected': {
        border: `2px solid ${theme.palette.general.primary}`,
      },

      '&.error': {
        borderColor: `${lighten(theme.palette.general.error, 0.4)}`,
      },

      '&.error.selected': {
        borderColor: `${theme.palette.general.error}`,
      },

      '& > *:first-child': {
        display: 'inline-block',

        paddingBottom: theme.spacing(1),
      },

      '& > button': {
        position: 'absolute',
        top: '4px',
        right: '4px',
      },
    },
  },

  topbar: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),

    '& > div': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },

    '& .file-description': {
      '& h6 > span': {
        color: lighten(theme.palette.secondary.dark, 0.2),
      },
    },
  },
}));

//
// Sub Components
//

function Topbar({
  rawSequenceId,
  remainingSequencesCount,
  scanType,
  dicomFile,
  sampledDicomFile,
  side,
  region,
  examType,
  device,
  notes = '',
  isUseless,
  error = false,
  showDelete = true,
  onChangeUseless,
  onSubmit,
  onCancel,
  onDelete,
}) {
  const classes = useStyles();
  const [showConfirm, setShowConfirm] = useState(false);

  const descriptionRow = (
    <div>
      <div className="file-description">
        <Typography variant="subtitle2">
          <span>Raw Sequence ID:</span> {rawSequenceId}
        </Typography>
        <Typography variant="subtitle2">
          <span>Scan Type:</span> {ScanTypeToStringMap[scanType]}
        </Typography>
        <Typography variant="subtitle2">
          <span>File Name:</span> {dicomFile.original_filename}
        </Typography>
        <Typography variant="subtitle2">
          <span>Side:</span> {side}
        </Typography>
        <Typography variant="subtitle2">
          <span>Region:</span> {RegionToStringMap[region]}
        </Typography>
        <Typography variant="subtitle2">
          <span>Exam Type:</span> {ExamDatatypeToStringMap[examType]}
        </Typography>
        <Typography variant="subtitle2">
          <span>Device:</span> {DeviceToStringMap[device]}
        </Typography>
        <Typography variant="subtitle2">
          <span>Notes:</span> {notes}
        </Typography>
      </div>
      <div className={classes.remainingSequences}>
        <Typography variant="subtitle2">
          {remainingSequencesCount} raw sequences remaining
        </Typography>
      </div>
    </div>
  );

  const buttonRow = (
    <div>
      <div>
        <ActionButton
          label="Cancel"
          icon="NavigateBeforeIcon"
          color="neutral"
          clickHandler={onCancel}
        />{' '}
        <ActionButton
          label="Download"
          icon="GetAppIcon"
          disabled={!dicomFile}
          href={buildFileDownloadUrl(dicomFile?.id)}
        />{' '}
        <ActionButton
          label="Download (compressed)"
          icon="GetAppIcon"
          disabled={!sampledDicomFile}
          href={buildFileDownloadUrl(sampledDicomFile?.id)}
        />{' '}
        <ActionButton
          label="View"
          icon="VisibilityIcon"
          disabled={!sampledDicomFile}
          href={`/dicomViewer/${sampledDicomFile?.id}`}
          overrides={{ target: '_blank' }}
        />{' '}
        <ActionButton
          label={isUseless ? 'Mark as Candidate' : 'Mark as Useless'}
          icon={isUseless ? 'CheckIcon' : 'CancelIcon'}
          color="neutral"
          clickHandler={() => onChangeUseless(!isUseless)}
        />
      </div>
      <div>
        {showDelete && (
          <>
            <ConfirmationModal
              show={showConfirm}
              onConfirm={() => {
                onDelete();
                setShowConfirm(false);
              }}
              onClose={() => setShowConfirm(false)}
              label="Delete"
              labelColor="reject">
              This will delete all sequence intervals of this raw sequence.
            </ConfirmationModal>
            <ActionButton
              label="Delete"
              icon="DeleteIcon"
              color="reject"
              clickHandler={() => setShowConfirm(true)}
            />{' '}
          </>
        )}
        <ActionButton label="Save" icon="SaveIcon" color="action" clickHandler={onSubmit} />
      </div>
    </div>
  );

  const uselessExplainerRow = (
    <div>
      <AlertBox variant="warning">
        You have marked this sequences as useless. All unsaved changes to this sequence will be lost
        and this sequence will be stored as useless when submitting. Mark the sequence as a
        labelling candidate to split it into intervals.
      </AlertBox>
    </div>
  );

  const errorsExplainerRow = (
    <div>
      <AlertBox>The form cannot be submitted due to errors, see below.</AlertBox>
    </div>
  );

  return (
    <div className={classes.topbar}>
      {descriptionRow}
      {buttonRow}
      {isUseless && uselessExplainerRow}
      {!isUseless && error && errorsExplainerRow}
    </div>
  );
}

Topbar.propTypes = {
  rawSequenceId: PropTypes.number.isRequired,
  remainingSequencesCount: PropTypes.number.isRequired,
  scanType: PropTypes.oneOf(Object.values(ScanType)).isRequired,
  side: PropTypes.oneOf(LEG_CATEGORIES.concat(WRIST_CATEGORIES)).isRequired,
  region: PropTypes.oneOf(Object.values(Region)).isRequired,
  examType: PropTypes.oneOf(Object.values(ExamDatatype)).isRequired,
  device: PropTypes.oneOf(Object.values(Device)).isRequired,
  notes: PropTypes.string,
  dicomFile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    original_filename: PropTypes.string.isRequired,
  }),
  sampledDicomFile: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  isUseless: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  showDelete: PropTypes.bool,
  onChangeUseless: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

function SequenceInterval({
  start,
  end,
  landmarks,
  type,
  error = false,
  selected = false,
  onRemove,
  onSelect,
}) {
  const joinedLandmarks = landmarks.sort().join(',');

  function handleRemove(event) {
    // NOTE(sven): Avoid event bubbling so that clicking the delete button does
    // not trigger a select action
    event.stopPropagation();
    onRemove();
  }

  return (
    <Paper
      className={`sequence-interval ${selected && 'selected'} ${error && 'error'}`}
      onClick={onSelect}>
      <Typography variant="subtitle2">
        Sequence Interval {start ? start : 'start'} - {end ? end : 'end'}
        {error && (
          <>
            <br />
            <Typography variant="caption" color="error">
              This sequence has form errors.
            </Typography>
          </>
        )}
      </Typography>
      <Typography>
        Type: <b>{ExamDatatypeToStringMap[type] || 'none'}</b>
      </Typography>
      <Typography>
        Landmarks: <b>{landmarks.length > 0 ? joinedLandmarks : 'none'}</b>
      </Typography>
      <IconButton onClick={handleRemove}>
        <DeleteIcon />
      </IconButton>
    </Paper>
  );
}

SequenceInterval.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
  landmarks: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.number,
  error: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

function SequenceIntervalList({ sequenceIntervals, selectedIndex, onAdd, onRemove, onChange }) {
  const classes = useStyles();
  return (
    <div className={classes.sequenceIntervals}>
      <header>
        <Typography variant="subtitle2" align="center">
          Sequence Intervals
        </Typography>
      </header>
      <section>
        <ActionButton label="Add" color="action" icon="AddIcon" clickHandler={onAdd} />
        {sequenceIntervals.map((sequenceInterval, index) => (
          <SequenceInterval
            key={index}
            type={sequenceInterval.type}
            start={sequenceInterval.start}
            end={sequenceInterval.end}
            landmarks={sequenceInterval.landmarks}
            error={sequenceInterval.error}
            onRemove={() => onRemove(index)}
            onSelect={() => onChange(index)}
            selected={selectedIndex == index}
          />
        ))}
      </section>
    </div>
  );
}

SequenceIntervalList.propTypes = {
  sequenceIntervals: PropTypes.arrayOf(SequenceIntervalPropType),
  selectedIndex: PropTypes.number.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function SequenceIntervalForm({ sequenceInterval, scanType, error = false, onChange }) {
  function renderDVTMetadata() {
    return (
      <>
        <Typography variant="subtitle2">DVT Metadata</Typography>
        <SimpleSelect
          label="Leg"
          options={LEG_CATEGORIES}
          value={sequenceInterval.leg || ''}
          onChange={(value) => {
            onChange({ ...sequenceInterval, leg: value });
          }}
        />
        <VesselClosedAts
          sequenceIntervalStart={sequenceInterval.start}
          sequenceIntervalEnd={sequenceInterval.end}
          vesselClosedAts={sequenceInterval.vesselClosedAts}
          onChange={(newAts) => onChange({ ...sequenceInterval, vesselClosedAts: newAts })}
        />
      </>
    );
  }

  function renderWristMetadata() {
    return (
      <>
        <Typography variant="subtitle2">Wrist Metadata</Typography>
        <SimpleSelect
          label="Wrist"
          options={WRIST_CATEGORIES}
          value={sequenceInterval.wrist || ''}
          onChange={(value) => {
            onChange({ ...sequenceInterval, wrist: value });
          }}
          placeholder="Select wrist..."
        />
      </>
    );
  }

  return (
    <Box className="sequence-interval-form">
      <div className="row">
        <Typography variant="subtitle2">General Metadata</Typography>
        <div className="sequence-interval-range">
          <Typography variant="overline">Sequence Interval Range</Typography>
          <RangeInput
            required
            emptyIsError={error && (sequenceInterval.start == null || sequenceInterval.end == null)}
            start={sequenceInterval.start}
            end={sequenceInterval.end}
            onChange={({ start, end }) => onChange({ ...sequenceInterval, start, end })}
          />
        </div>
        <Typography variant="overline">Metadata</Typography>
        <SimpleSelect
          label="Anatomy"
          required
          error={error && sequenceInterval.anatomy == null}
          options={ANATOMY_CATEGORIES}
          value={sequenceInterval.anatomy || ''}
          onChange={(value) => {
            onChange({ ...sequenceInterval, anatomy: value });
          }}
        />
        <SimpleSelect
          label="Landmarks"
          required
          options={LANDMARK_OPTIONS}
          multiple
          value={sequenceInterval.landmarks}
          error={error && sequenceInterval.landmarks.length == 0}
          onChange={(value) => {
            onChange({ ...sequenceInterval, landmarks: value });
          }}
        />
        <SimpleSelect
          label="Type"
          required
          options={ExamDatatypeToStringMap}
          error={error && sequenceInterval.type == null}
          value={sequenceInterval.type !== null ? sequenceInterval.type : ''}
          onChange={(value) => {
            onChange({ ...sequenceInterval, type: parseInt(value) });
          }}
        />
        <SimpleSelect
          label="Device"
          required
          error={error && sequenceInterval.device == null}
          options={DeviceToStringMap}
          value={sequenceInterval.device !== null ? sequenceInterval.device : ''}
          onChange={(value) => {
            onChange({ ...sequenceInterval, device: parseInt(value) });
          }}
        />
        <ImageQualityScore
          imageQualityScore={sequenceInterval.imageQualityScore}
          onChange={(score) => {
            onChange({ ...sequenceInterval, imageQualityScore: score });
          }}
        />
      </div>
      <div className="row">
        <ScreenshotSection
          allowEditing={true}
          fileId={sequenceInterval.screenshotFileId}
          onChange={(fileId) => {
            onChange({ ...sequenceInterval, screenshotFileId: fileId });
          }}
        />
      </div>
      <div className="row">
        <LandmarkNotPresentAts
          sequenceIntervalStart={sequenceInterval.start}
          sequenceIntervalEnd={sequenceInterval.end}
          landmarkNotPresentAts={sequenceInterval.landmarkNotPresentAts}
          onChange={(newAts) => onChange({ ...sequenceInterval, landmarkNotPresentAts: newAts })}
        />
      </div>
      <div className="row">
        {scanType === ScanType.DVT && renderDVTMetadata()}
        {scanType === ScanType.WRIST && renderWristMetadata()}
      </div>
    </Box>
  );
}

SequenceIntervalForm.propTypes = {
  sequenceInterval: SequenceIntervalPropType.isRequired,
  scanType: PropTypes.oneOf(Object.values(ScanType)).isRequired,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

function buildSequenceIntervalState() {
  return {
    // Form data
    error: false,

    // General Metadata
    id: null,
    start: null,
    end: null,
    anatomy: null,
    landmarks: [],
    type: null,
    device: null,
    landmarkNotPresentAts: [], // see buildLandmarkNotPresentAtState
    screenshotFileId: null,
    imageQualityScore: null,

    // DVT
    leg: null,
    vesselClosedAts: [], // see buildVesselClosedAtState

    // WRIST
    wrist: null,
  };
}

//
// Page Component
//

function SplitSequences({
  rawSequence,
  sequenceIntervals: initialSequenceIntervals = [],
  remainingSequencesCount,
  onSubmit,
  onCancel,
}) {
  const classes = useStyles();

  const hasInitialIntervals = initialSequenceIntervals.length > 0;
  const [sequenceIntervals, setSequenceIntervals] = useState(
    hasInitialIntervals ? initialSequenceIntervals : [buildSequenceIntervalState()]
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isUseless, setIsUseless] = useState(rawSequence.is_useless);

  const sequenceInterval = sequenceIntervals[selectedIndex];
  const hasErrors = sequenceIntervals.reduce((acc, si) => acc || si.error, false);

  function validateSequenceInterval(sequenceInterval) {
    const {
      start,
      end,
      anatomy,
      device,
      landmarks,
      type,
      imageQualityScore,
      vesselClosedAts,
      landmarkNotPresentAts,
    } = sequenceInterval;
    if (start > end) {
      return false;
    }
    if (anatomy === null || device === null || landmarks.length == 0 || type === null) {
      return false;
    }
    if (imageQualityScore < 1 || imageQualityScore > 30) {
      return false;
    }

    if (vesselClosedAts) {
      for (const vesselClosedAt of vesselClosedAts) {
        const { vessel, start, end, dnc } = vesselClosedAt;
        const isValid =
          (vessel == '' && start == null && end == null && !dnc) ||
          (vessel.length > 0 &&
            (dnc ||
              (start <= end &&
                sequenceInterval.start <= start &&
                start <= sequenceInterval.end &&
                sequenceInterval.start <= end &&
                end <= sequenceInterval.end)));
        if (!isValid) {
          return false;
        }
      }
    }

    if (landmarkNotPresentAts) {
      for (const landmarkNotPresentAt of landmarkNotPresentAts) {
        const { start, end } = landmarkNotPresentAt;
        const isValid =
          (start == null && end == null) ||
          (start <= end &&
            sequenceInterval.start <= start &&
            start <= sequenceInterval.end &&
            sequenceInterval.start <= end &&
            end <= sequenceInterval.end);
        if (!isValid) {
          return false;
        }
      }
    }

    return true;
  }

  function submitSequenceIntervals() {
    let hasErrors = false;

    for (const sequenceInterval of sequenceIntervals) {
      const hasError = !validateSequenceInterval(sequenceInterval);
      hasErrors = hasErrors || hasError;
      sequenceInterval.error = hasError;
    }

    setSequenceIntervals([...sequenceIntervals]);

    if (!hasErrors || isUseless) {
      onSubmit(isUseless, sequenceIntervals);
    }
  }

  function deleteAndSubmit() {
    onSubmit(false, []);
  }

  function addSequenceInterval() {
    setSequenceIntervals([...sequenceIntervals, buildSequenceIntervalState()]);
  }

  function removeSequenceInterval(index) {
    const newSequenceIntervals = sequenceIntervals.slice();
    newSequenceIntervals.splice(index, 1);

    if (newSequenceIntervals.length == 0) {
      newSequenceIntervals.push(buildSequenceIntervalState());
    }

    if (index == selectedIndex && index > 0) {
      setSelectedIndex(0);
    }

    setSequenceIntervals(newSequenceIntervals);
  }

  function selectSequenceInterval(index) {
    if (index != selectedIndex) {
      setSelectedIndex(index);
    }
  }

  function updateSequenceInterval(newSequenceInterval) {
    const newSequenceIntervals = sequenceIntervals.slice();

    // NOTE(sven): While the form is edited, we only change from error to
    // no-error state, not the other way around, so that the user does not see
    // an entirely red form when they have just started out editing.
    if (validateSequenceInterval(newSequenceInterval)) {
      newSequenceInterval.error = false;
    }
    newSequenceIntervals[selectedIndex] = newSequenceInterval;

    setSequenceIntervals(newSequenceIntervals);
  }

  return (
    <div className={classes.splitSequences}>
      <Infobar heading="Split Sequences" />
      <Topbar
        rawSequenceId={rawSequence.id}
        remainingSequencesCount={remainingSequencesCount}
        dicomFile={rawSequence.dicom_file}
        sampledDicomFile={rawSequence.sampled_dicom_file}
        scanType={rawSequence.scan_type}
        side={
          rawSequence.scan_type == ScanType.DVT
            ? rawSequence.metadata_dvt.leg
            : rawSequence.metadata_dvt.wrist
        }
        region={rawSequence.metadata_dvt.region}
        device={rawSequence.metadata_dvt.device}
        notes={rawSequence.metadata_dvt.notes}
        examType={rawSequence.metadata_dvt.exam_type}
        isUseless={isUseless}
        error={hasErrors}
        showDelete={hasInitialIntervals}
        onChangeUseless={setIsUseless}
        onSubmit={submitSequenceIntervals}
        onCancel={onCancel}
        onDelete={deleteAndSubmit}
      />
      {isUseless && (
        <AlertBox variant="disabled">
          The form is disabled because this sequence is marked as useless.
        </AlertBox>
      )}
      {!isUseless && (
        <div className="form-wrapper">
          <SequenceIntervalList
            sequenceIntervals={sequenceIntervals}
            selectedIndex={selectedIndex}
            onAdd={addSequenceInterval}
            onRemove={removeSequenceInterval}
            onChange={selectSequenceInterval}
          />
          <SequenceIntervalForm
            scanType={rawSequence.scan_type}
            sequenceInterval={sequenceInterval}
            onChange={updateSequenceInterval}
            error={sequenceInterval.error}
          />
        </div>
      )}
    </div>
  );
}

SplitSequences.propTypes = {
  rawSequence: PropTypes.shape({
    id: PropTypes.number.isRequired,
    is_useless: PropTypes.bool.isRequired,
    dicom_file: PropTypes.shape({
      id: PropTypes.number.isRequired,
      original_filename: PropTypes.string.isRequired,
    }),
    scan_type: PropTypes.oneOf(Object.values(ScanType)),
    metadata_dvt: PropTypes.shape({
      exam_type: PropTypes.oneOf(Object.values(ExamDatatype)),
      device: PropTypes.oneOf(Object.values(Device)),
      region: PropTypes.oneOf(Object.values(Region)),
      wrist: PropTypes.oneOf(WRIST_CATEGORIES),
      leg: PropTypes.oneOf(LEG_CATEGORIES),
    }),
  }).isRequired,
  sequenceIntervals: PropTypes.arrayOf(SequenceIntervalPropType),
  remainingSequencesCount: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

async function fetchRemainingSequencesCount() {
  const { count } = await apiFetch('/api/rawSequences/notScreened/count');
  return count;
}

async function fetchRawSequence(rawSequenceId) {
  const rawSequence = await apiFetch(`/api/rawSequences/${rawSequenceId}`);
  return rawSequence;
}

async function fetchSequenceIntervals(rawSequenceId) {
  const { sequence_intervals } = await apiFetch(
    `/api/rawSequences/${rawSequenceId}/sequenceIntervals`
  );
  return sequence_intervals;
}

/**
 * Map backend sequence interval definitions to the frontend form state.
 */
function mapSequencesToFormData(sequenceIntervals) {
  return sequenceIntervals.map((si) => {
    return {
      error: false,
      id: si.id,
      start: si.start,
      end: si.end,
      type: si.metadata_dvt.type,
      anatomy: si.metadata_dvt.anatomy,
      device: si.metadata_dvt.device,
      landmarks: si.metadata_dvt.landmark.split(','),
      imageQualityScore: si.image_quality_score,
      screenshotFileId: si.screenshot?.file_id,
      landmarkNotPresentAts: si.metadata_dvt.landmark_not_present_at || [],
      vesselClosedAts: si.metadata_dvt.vessel_closed_at || [],
      leg: si.metadata_dvt.leg?.toLowerCase(),
      wrist: si.metadata_dvt.wrist?.toLowerCase(),
    };
  });
}

async function postSequenceIntervals(rawSequenceId, sequenceIntervals) {
  // mapping the frontend sequence interval data structure to the backend keys
  const sequence_intervals = sequenceIntervals.map((si) => ({
    id: si.id,
    start: si.start,
    end: si.end,
    type: si.type,
    anatomy: si.anatomy,
    device: si.device,
    landmark: si.landmarks.join(','),
    screenshot_file_id: si.screenshotFileId || null,
    image_quality_score: si.imageQualityScore,
    landmark_not_present_ats: si.landmarkNotPresentAts.filter(({ start }) => start !== null),
    leg: si.leg?.toLowerCase() || null,
    vessel_closed_ats: si.vesselClosedAts.filter(({ vessel }) => vessel !== ''),
    wrist: si.wrist?.toLowerCase() || null,
  }));

  return apiFetch(`/api/rawSequences/${rawSequenceId}/sequenceIntervals/batch`, 'POST', {
    sequence_intervals,
  });
}

async function postIsUseless(rawSequenceId, isUseless) {
  return apiFetch(`/api/rawSequences/${rawSequenceId}`, 'PATCH', { is_useless: isUseless });
}

function SplitSequencesPage() {
  const { id: rawSequenceId } = useParams();
  const [rawSequence, setRawSequence] = useState(null);
  const [remainingSequencesCount, setRemainingSequencesCount] = useState(null);
  const [sequenceIntervals, setSequenceIntervals] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);

  // NOTE(sven): Fetch the data once when the page loads.
  useEffect(() => {
    fetchRemainingSequencesCount().then(setRemainingSequencesCount);
    fetchRawSequence(rawSequenceId).then(setRawSequence);
    fetchSequenceIntervals(rawSequenceId).then(mapSequencesToFormData).then(setSequenceIntervals);
  }, []);

  async function submitForm(isUseless, sequenceIntervals) {
    try {
      await postIsUseless(rawSequenceId, isUseless);
      if (!isUseless) {
        await postSequenceIntervals(rawSequenceId, sequenceIntervals);
      }
      history.push('/dataCurationDashboard');
    } catch (error) {
      console.log(error);
      setShowSnackbar(true);
    }
  }

  if (rawSequence === null || remainingSequencesCount === null || sequenceIntervals === null) {
    return (
      <div style={{ height: '12rem' }}>
        <Spinner hcenter vcenter />
      </div>
    );
  } else {
    return (
      <>
        <SplitSequences
          rawSequence={rawSequence}
          remainingSequencesCount={remainingSequencesCount}
          sequenceIntervals={sequenceIntervals}
          onSubmit={submitForm}
          onCancel={() => history.push('/dataCurationDashboard')}
        />
        <Snackbar
          message="Failed to save sequence intervals."
          open={showSnackbar}
          setOpen={setShowSnackbar}
        />
      </>
    );
  }
}

export default SplitSequencesPage;
