import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { configurePage, setPageName, clearPage } from '../reducers/pageSlice';
import { unsetRowMultiSelect, clearSelectedRows } from '../reducers/uiStateSlice';
import { fetchDataCollections } from '../reducers/dataCollectionSlice';
import TabFactory from '../components/tabs/TabFactory';

const DATA_COLLECTIONS_URL = '/api/dataCollections';

function Notifications() {
  const dispatch = useDispatch();

  const tabs = [
    {
      label: 'Unseen',
      icon: 'VisibilityOffIcon',
      component: 'Notifications/Unseen',
    },
    {
      label: 'Seen',
      icon: 'VisibilityIcon',
      component: 'Notifications/Seen',
    },
  ];

  useEffect(() => {
    dispatch(clearPage());
    dispatch(clearSelectedRows());
    dispatch(configurePage(tabs));
    dispatch(setPageName('Notifications'));
    dispatch(unsetRowMultiSelect());
    dispatch(
      fetchDataCollections({
        url: DATA_COLLECTIONS_URL,
        method: 'GET',
      })
    );
  }, []);

  return <TabFactory />;
}

export default Notifications;
