import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {},
  infobox: {
    marginRight: theme.spacing(3),
  },
  infoSection: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
}));
