import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  imageQualityScore: {
    '& .MuiTypography-overline': {
      display: 'block',
    },

    '& .MuiFormControl-root': {
      margin: theme.spacing(0.5),
      minWidth: '8rem',
    },
  },
}));

export default function ImageQualityScore({
  imageQualityScore,
  onChange,
  className = '',
  disabled = false,
}) {
  const classes = useStyles();
  const hasError = imageQualityScore < 0 || imageQualityScore > 30;
  return (
    <div className={`${classes.imageQualityScore} ${disabled ? 'disabled' : ''} ${className}`}>
      <Typography variant="overline">
        Image Quality Score{' '}
        <a
          // Image Quality Index definition
          href="https://docs.google.com/document/d/1FEECaUzxB-8r8djyYiLSi3zO188LP9ks8NNDMrdOFLA/edit"
          target="_blank"
          rel="noreferrer">
          (?)
        </a>
      </Typography>

      <span>
        <TextField
          size="small"
          label="Score"
          type="number"
          variant="outlined"
          disabled={disabled}
          value={imageQualityScore || ''}
          inputProps={{ min: 1, max: 30 }}
          error={hasError}
          helperText={hasError && 'The score must between 1 and 30.'}
          onChange={(event) => onChange(parseInt(event.target.value) || null)}
        />
      </span>
    </div>
  );
}

ImageQualityScore.propTypes = {
  imageQualityScore: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
