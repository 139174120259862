import { createSlice, createSelector } from '@reduxjs/toolkit';

import { addOrRemoveItem } from '../helpers/utils';

const initialState = {
  modal: {
    modalOpen: false,
    component: null,
  },
  table: {
    rowsSelected: [],
    labeller: null,
  },
  linkState: {
    accessor: 'id', //default: row.id
    target: '',
    action: '',
  },
  isRowSelectable: true,
  tab: {
    tabIndex: 0,
    page: 0,
    limit: 50,
    sortOrder: 'asc',
    orderBy: null,
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    configureModal: {
      reducer(state, action) {
        state.modal = action.payload;
      },
    },
    destroyModal: {
      reducer(state) {
        state.modal = initialState.modal;
      },
    },
    setSelectedRows: {
      reducer(state, action) {
        state.table.rowsSelected = addOrRemoveItem(state.table.rowsSelected, action.payload).sort();
      },
    },
    setSelectedAllRows: {
      reducer(state, action) {
        state.table.rowsSelected = action.payload;
      },
    },
    clearSelectedRows: {
      reducer(state) {
        state.table.rowsSelected = [];
      },
    },
    clearSelectedLabeller: {
      reducer(state) {
        state.table.labeller = null;
      },
    },
    setSelectedLabeller: {
      reducer(state, action) {
        state.table.labeller = action.payload;
      },
    },
    setRowMultiSelect: {
      reducer(state) {
        state.isRowSelectable = true;
      },
    },
    unsetRowMultiSelect: {
      reducer(state) {
        state.isRowSelectable = false;
      },
    },
    changeTabIndex: {
      reducer(state, action) {
        state.tab.tabIndex = parseInt(action.payload.tabIdx, 10);
      },
    },
    resetTabIndex: {
      reducer(state) {
        state.tab.tabIndex = 0;
      },
    },
    setPage: {
      reducer(state, action) {
        state.tab.page = parseInt(action.payload, 10);
      },
    },
    setLimit: {
      reducer(state, action) {
        state.tab.limit = parseInt(action.payload, 10);
      },
    },
    setSortOrder: {
      reducer(state, action) {
        state.tab.sortOrder = action.payload;
      },
    },
    setOrderBy: {
      reducer(state, action) {
        state.tab.orderBy = action.payload;
      },
    },
    setLinkState: {
      reducer(state, action) {
        state.linkState = { ...state.linkState, ...action.payload };
      },
    },
    clearLinkState: {
      reducer(state) {
        state.linkState = { target: '', accessor: 'id' };
      },
    },
  },
});

/**
 * Selectors
 */
export const selectModalState = (state) => state.ui.modal.modalOpen;

export const selectModalComponent = (state) => state.ui.modal.component;

export const selectRowIdsSelected = (state) => state.ui.table.rowsSelected;
export const selectNumberOfRowsSelected = createSelector(
  selectRowIdsSelected,
  (rowsSelected) => rowsSelected.length
);
export const selectSelectedLabeller = (state) => state.ui.table.labeller;

export const selectTabIndex = (state) => state.ui.tab.tabIndex;

export const selectPage = (state) => state.ui.tab.page;

export const selectLimit = (state) => state.ui.tab.limit;

export const selectOrderBy = (state) => state.ui.tab.orderBy;

export const selectSortOrder = (state) => state.ui.tab.sortOrder;

export const selectLinkState = (state) => state.ui.linkState;

export const selectIsRowSelectable = (state) => state.ui.isRowSelectable;

/**
 * Actions and reducer exports
 */
export const {
  configureModal,
  destroyModal,
  setSelectedRows,
  setSelectedAllRows,
  clearSelectedRows,
  clearSelectedLabeller,
  setSelectedLabeller,
  changeTabIndex,
  resetTabIndex,
  setPage,
  setLimit,
  setLinkState,
  clearLinkState,
  setRowMultiSelect,
  unsetRowMultiSelect,
  setSplitSequencesFormVisible,
  setSplitSequencesSaveButtonVisible,
  setSortOrder,
  setOrderBy,
} = uiSlice.actions;
export default uiSlice.reducer;
