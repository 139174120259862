import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AccessibleForwardIcon from '@material-ui/icons/AccessibleForward';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AddIcon from '@material-ui/icons/Add';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import BuildIcon from '@material-ui/icons/Build';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import CancelIcon from '@material-ui/icons/Cancel';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CloudIcon from '@material-ui/icons/Cloud';
import CloseIcon from '@material-ui/icons/Close';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import GetAppIcon from '@material-ui/icons/GetApp';
import HelpIcon from '@material-ui/icons/Help';
import LabelIcon from '@material-ui/icons/Label';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PersonIcon from '@material-ui/icons/Person';
import PostAddIcon from '@material-ui/icons/PostAdd';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PublishIcon from '@material-ui/icons/Publish';
import SaveIcon from '@material-ui/icons/Save';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import TheatersIcon from '@material-ui/icons/Theaters';
import TuneIcon from '@material-ui/icons/Tune';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import WarningIcon from '@material-ui/icons/Warning';
import FilterListIcon from '@material-ui/icons/FilterList';

export default {
  AddCircleOutlineIcon,
  AccessibleForwardIcon,
  AccessTimeIcon,
  AddIcon,
  ArtTrackIcon,
  AssignmentIcon,
  AssignmentIndIcon,
  AttachFileIcon,
  BuildIcon,
  CallSplitIcon,
  CancelIcon,
  CancelPresentationIcon,
  CheckIcon,
  ClearIcon,
  CloudIcon,
  CloseIcon,
  CollectionsBookmarkIcon,
  CreateIcon,
  DeleteIcon,
  DescriptionIcon,
  DoneIcon,
  EditIcon,
  FindInPageIcon,
  GetAppIcon,
  HelpIcon,
  LabelIcon,
  LinearScaleIcon,
  PersonIcon,
  NavigateBeforeIcon,
  NavigateNextIcon,
  NotificationsIcon,
  NotificationsActiveIcon,
  PostAddIcon,
  PlayArrowIcon,
  PublishIcon,
  SaveIcon,
  ScheduleIcon,
  SettingsApplicationsIcon,
  TheatersIcon,
  TuneIcon,
  VisibilityIcon,
  VisibilityOffIcon,
  WarningIcon,
  FilterListIcon,
};
