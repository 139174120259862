import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionButton from '../ui/ActionButton';
import { setImageQualityScore, selectAssignment } from '../../reducers/labellerSlice';
import { apiFetch } from '../../api';
import ImageQualityScore from '../ImageQualityScore';

async function updateImageQualityScore(sequenceIntervalId, imageQualityScore) {
  return apiFetch(`/api/sequenceIntervals/${sequenceIntervalId}`, 'PATCH', {
    image_quality_score: imageQualityScore,
  });
}

export default function ImageQualityScoreSection() {
  const dispatch = useDispatch();
  const assignment = useSelector(selectAssignment);

  const sequenceIntervalId = assignment.sequence_interval.id;
  const imageQualityScore = assignment.sequence_interval.image_quality_score;

  const [isEditable, setEditable] = useState(false);
  const [localImageQualityScore, setLocalImageQualityScore] = useState(imageQualityScore);

  function handleCancel() {
    setLocalImageQualityScore(imageQualityScore);
    setEditable(false);
  }

  async function handleSave(data) {
    const isValid = localImageQualityScore >= 1 && localImageQualityScore <= 30;

    if (isValid) {
      await updateImageQualityScore(sequenceIntervalId, localImageQualityScore);
      dispatch(setImageQualityScore(localImageQualityScore));
      setEditable(false);
    }
  }

  return (
    <div className={`landmark-section`}>
      <div className="edit-buttons">
        {isEditable ? (
          <>
            <ActionButton
              label="CANCEL"
              color="reject"
              icon="CancelIcon"
              variant="outlined"
              clickHandler={handleCancel}
            />{' '}
            <ActionButton
              label="SAVE"
              color="action"
              icon="SaveIcon"
              variant="outlined"
              clickHandler={handleSave}
            />
          </>
        ) : (
          <ActionButton
            label="EDIT"
            color="action"
            icon="EditIcon"
            variant="outlined"
            clickHandler={() => setEditable(true)}
          />
        )}
      </div>
      <ImageQualityScore
        imageQualityScore={localImageQualityScore}
        onChange={setLocalImageQualityScore}
        disabled={!isEditable}
      />
    </div>
  );
}
