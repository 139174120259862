import { makeStyles } from '@material-ui/core/styles';

/* GridBreak - force Grid to break into newline
 *
 * Requires the container to be flexWrap: wrap
 */
export default makeStyles(() => ({
  breakRow: {
    flexBasis: '100%',
    height: 0,
  },
  breakColumn: {
    flexBasis: '100%',
    width: 0,
  },
}));
