import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants';

export const fetchRows = createAsyncThunk(
  'sequenceIntervals/fetchRows',
  async (_, { getState }) => {
    const {
      sequenceIntervals: {
        table: { limit, page, sort, filteredBy, filterValue },
      },
    } = getState();

    const response = await axios.get('/api/sequenceIntervals', {
      params: {
        limit,
        page,
        sort,
        ...{ [filteredBy]: filterValue },
      },
    });
    return response.data;
  }
);

export const fetchSequenceInterval = createAsyncThunk(
  'sequenceIntervals/fetchSequenceInterval',
  async (id) => {
    const response = await axios.get(`/api/sequenceIntervals/${id}`);
    return response.data;
  }
);

const initialState = {
  rows: [],
  currentItem: null,
  status: LoadingStatus.IDLE,
  table: {
    orderBy: 'SequenceInterval.id',
    sortOrder: 'asc',
    filteredBy: '',
    filterValue: null,
    selectedRows: [], //  prev: rowsSelected
    totalItems: 0,
    page: 0,
    limit: 50,
    sort: 'SequenceInterval.id',
  },
};

const sequenceIntervalsSlice = createSlice({
  name: 'sequenceIntervals',
  initialState,
  reducers: {
    setTableState: {
      reducer(state, action) {
        const newState = { ...state.table, ...action.payload };
        state.table = newState;
      },
    },
    removeItemById: {
      reducer(state, action) {
        const id = action.payload;
        state.rows = state.rows.filter((item) => id !== item.id);
      },
    },
    setFilter: {
      reducer(state, action) {
        state.table.filteredBy = action.payload.filteredBy;
        state.table.filterValue = action.payload.filterValue;
      },
    },
    unsetFilter: {
      reducer(state) {
        state.table.filterValue = '';
        state.table.filteredBy = '';
      },
    },
    resetLoadingState: {
      reducer(state) {
        state.status = LoadingStatus.IDLE;
      },
    },
    resetCurrentItem: {
      reducer(state) {
        state.currentItem = null;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRows.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchRows.fulfilled, (state, action) => {
        state.rows = action.payload.sequence_intervals;
        state.table.totalItems = action.payload.total_items;
        state.status = LoadingStatus.DONE;
      })

      .addCase(fetchSequenceInterval.fulfilled, (state, action) => {
        state.currentItem = action.payload;
      });
  },
});

export const selectRows = ({ sequenceIntervals }) => sequenceIntervals.rows;
export const selectStatus = ({ sequenceIntervals }) => sequenceIntervals.status;
export const selectTableState = ({ sequenceIntervals }) => sequenceIntervals.table;
export const selectTotalItems = ({ sequenceIntervals }) => sequenceIntervals.table.totalItems;
export const selectSelectedRows = ({ sequenceIntervals }) => sequenceIntervals.table.selectedRows;
export const selectCurrentItem = ({ sequenceIntervals }) => sequenceIntervals.currentItem;

export const {
  setTableState,
  removeItemById,
  setFilter,
  unsetFilter,
  resetLoadingState,
  resetCurrentItem,
} = sequenceIntervalsSlice.actions;
export default sequenceIntervalsSlice.reducer;
