import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addOrRemoveItem } from '../../helpers/utils';
import { LoadingStatus } from '../../constants';

export const fetchRows = createAsyncThunk(
  'labellingCandidates/fetchRows',
  async (_, { getState }) => {
    const {
      labellingCandidates: {
        table: { limit, page, sort },
      },
    } = getState();
    const response = await axios.get('/api/rawSequences/notScreened', {
      params: {
        limit,
        page,
        sort,
      },
    });
    return response.data;
  }
);

export const submitIsUselessCheckbox = createAsyncThunk(
  'labellingCandidates/submitIsUselessCheckbox',
  async ({ data, rowId }) => {
    const response = await axios({
      url: `/api/rawSequences/${rowId}`,
      data,
      method: 'PATCH',
    });
    return response.data;
  }
);

const initialState = {
  rows: [],
  status: LoadingStatus.IDLE,
  table: {
    orderBy: 'DataCollection.name',
    sortOrder: 'asc',
    filteredBy: '',
    filterValue: '',
    selectedRows: [], //  prev: rowsSelected
    totalItems: 0,
    page: 0,
    limit: 50,
    sort: 'DataCollection.name',
  },
};

const labellingCandidatesSlice = createSlice({
  name: 'labellingCandidates',
  initialState,
  reducers: {
    setTableState: {
      reducer(state, action) {
        const newState = { ...state.table, ...action.payload };
        state.table = newState;
      },
    },
    setSelectedRow: {
      reducer(state, action) {
        state.table.selectedRows = addOrRemoveItem(state.table.selectedRows, action.payload).sort();
      },
    },
    clearSelectedRows: {
      reducer(state) {
        state.table.selectedRows = [];
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRows.pending, (state) => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(fetchRows.fulfilled, (state, action) => {
        state.rows = action.payload.raw_sequences;
        state.table.totalItems = action.payload.total_items;
        state.status = LoadingStatus.DONE;
      })

      .addCase(submitIsUselessCheckbox.fulfilled, (state, action) => {
        const { id } = action.payload;
        const arrayIndex = state.rows.findIndex((item) => item.id === id);
        state.rows[arrayIndex] = action.payload;
      });
  },
});

export const selectRows = ({ labellingCandidates }) => labellingCandidates.rows;
export const selectStatus = ({ labellingCandidates }) => labellingCandidates.status;
export const selectTableState = ({ labellingCandidates }) => labellingCandidates.table;
export const selectTotalItems = ({ labellingCandidates }) => labellingCandidates.table.totalItems;
export const selectSelectedRows = ({ labellingCandidates }) =>
  labellingCandidates.table.selectedRows;

export const {
  setTableState,
  setSelectedRow,
  clearSelectedRows,
} = labellingCandidatesSlice.actions;
export default labellingCandidatesSlice.reducer;
