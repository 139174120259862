import { configureStore } from '@reduxjs/toolkit';

import tableDataReducer from './reducers/tableSlice';
import viewReducer from './reducers/viewSlice';
import pageReducer from './reducers/pageSlice';
import uiReducer from './reducers/uiStateSlice';
import supervisorDashboardReducer from './reducers/supervisorDashboardSlice';
import dataCollectionReducer from './reducers/dataCollectionSlice';
import labellerAssignmentReducer from './reducers/labellerSlice';
import addPatientFormReducer from './reducers/form/addPatientsSlice';
import addSequencesToPatientFormReducer from './reducers/form/addSequencesToPatientSlice';

// Transitional reducers
import labellingCandidatesReducer from './reducers/dataCurationDashboard/labellingCandidates';
import stagedForSplittingReducer from './reducers/dataCurationDashboard/stagedForSplitting';
import pendingReducer from './reducers/dataCurationDashboard/pending';

import dataCollectionsReducer from './reducers/viewData/dataCollections';
import patientsReducer from './reducers/viewData/patients';
import rawSequencesReducer from './reducers/viewData/rawSequences';
import sequenceIntervalsReducer from './reducers/viewData/sequenceIntervals';
import labelsReducer from './reducers/viewData/labels';

import unassignedReducer from './reducers/unassigned';

export default configureStore({
  reducer: {
    tableData: tableDataReducer,
    view: viewReducer,
    page: pageReducer,
    ui: uiReducer,
    dataCollection: dataCollectionReducer,
    supervisorDashboard: supervisorDashboardReducer,
    labellerAssignment: labellerAssignmentReducer,
    addPatientForm: addPatientFormReducer,
    addSequencesToPatientForm: addSequencesToPatientFormReducer,
    // Transitional reducers
    // DataCuration Dashboard
    labellingCandidates: labellingCandidatesReducer,
    stagedForSplitting: stagedForSplittingReducer,
    pending: pendingReducer,
    // View Data
    dataCollections: dataCollectionsReducer,
    patients: patientsReducer,
    rawSequences: rawSequencesReducer,
    sequenceIntervals: sequenceIntervalsReducer,
    labelDVTs: labelsReducer,
    // supervisorDashboard
    unassigned: unassignedReducer,
  },
});
