import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&.vcenter': {
      height: '100%',
    },

    '&.hcenter': {
      width: '100%',
    },

    '& .circular-progress': {
      color: 'inherit',
    },
  },
});

/**
 * Shows a circular spinner. If vcenter or hcenter are provided the spinner will
 * create a container spanning the whole width or height respectively and it
 * will center itself in its container.
 */
function Spinner({ vcenter = false, hcenter = false }) {
  const classes = useStyles();
  return (
    <div className={`${classes.spinner} ${vcenter && 'vcenter'} ${hcenter && 'hcenter'}`}>
      <CircularProgress className="circular-progress" />
    </div>
  );
}

Spinner.propTypes = {
  hcenter: PropTypes.bool,
  vcenter: PropTypes.bool,
};

export default Spinner;
