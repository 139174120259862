import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants';

export const fetchRows = createAsyncThunk('patients/fetchRows', async (_, { getState }) => {
  const {
    patients: {
      table: { limit, page, sort, filteredBy, filterValue },
    },
  } = getState();
  const params = {
    limit,
    page,
    sort,
  };
  if (filteredBy != '') {
    params[filteredBy] = filterValue;
  }
  const response = await axios.get('/api/patients', {
    params: params,
  });
  return response.data;
});

export const fetchPatient = createAsyncThunk(
  'patients/fetchPatient',
  async (patient_id, { getState }) => {
    const {
      patients: {
        table: { limit, page, sort },
      },
    } = getState();
    const response = await axios.get(`/api/patients/${patient_id}`, {
      params: {
        limit,
        page,
        sort,
      },
    });
    return response.data;
  }
);

export const submitPathological = createAsyncThunk(
  'patients/submitCell',
  async ({ data, rowId }) => {
    const response = await axios({
      url: `/api/patients/${rowId}`,
      data,
      method: 'PATCH',
    });
    return response.data;
  }
);

const initialState = {
  rows: [],
  rowsById: {},
  currentItem: null,
  status: LoadingStatus.IDLE,
  table: {
    orderBy: 'DataCollection.name',
    sortOrder: 'asc',
    filteredBy: '',
    filterValue: '',
    selectedRows: [], //  prev: rowsSelected
    totalItems: 0,
    page: 0,
    limit: 50,
    sort: 'DataCollection.name',
  },
};

const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setTableState: {
      reducer(state, action) {
        const newState = { ...state.table, ...action.payload };
        state.table = newState;
      },
    },
    removeItemById: {
      reducer(state, action) {
        const id = action.payload;
        state.rows = state.rows.filter((item) => id !== item.id);
      },
    },
    setFilter: {
      reducer(state, action) {
        state.table.filteredBy = action.payload.filteredBy;
        state.table.filterValue = action.payload.filterValue;
      },
    },
    unsetFilter: {
      reducer(state) {
        state.table.filterValue = '';
        state.table.filteredBy = '';
      },
    },
    resetLoadingState: {
      reducer(state) {
        state.status = LoadingStatus.IDLE;
      },
    },
    resetCurrentItem: {
      reducer(state) {
        state.currentItem = null;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRows.pending, (state) => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(fetchRows.fulfilled, (state, action) => {
        state.rows = action.payload.patients;
        state.rowsById = action.payload.patients.reduce(
          (acc, curr) => ({ ...acc, [curr.id]: curr }),
          state.rowsById
        );
        state.table.totalItems = action.payload.total_items;
        state.status = LoadingStatus.DONE;
      })

      .addCase(fetchPatient.pending, (state) => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(fetchPatient.fulfilled, (state, action) => {
        state.currentItem = action.payload;
        state.status = LoadingStatus.DONE;
      })

      .addCase(submitPathological.fulfilled, (state, action) => {
        const { id } = action.payload;
        const arrayIndex = state.rows.findIndex((item) => item.id === id);
        state.rows[arrayIndex] = action.payload;
      });
  },
});

export const selectRows = ({ patients }) => patients.rows;
export const selectRowsById = ({ patients }) => patients.rowsById;
export const selectStatus = ({ patients }) => patients.status;
export const selectTableState = ({ patients }) => patients.table;
export const selectTotalItems = ({ patients }) => patients.table.totalItems;
export const selectSelectedRows = ({ patients }) => patients.table.selectedRows;
export const selectCurrentItem = ({ patients }) => patients.currentItem;

export const {
  setTableState,
  removeItemById,
  setFilter,
  unsetFilter,
  resetLoadingState,
  resetCurrentItem,
} = patientsSlice.actions;
export default patientsSlice.reducer;
