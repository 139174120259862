import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { UserRole } from '../constants';
import { configurePage, setPageName, clearPage } from '../reducers/pageSlice';
import { clearSelectedRows } from '../reducers/uiStateSlice';
import { fetchLabellers } from '../reducers/supervisorDashboardSlice';
import { fetchDataCollections } from '../reducers/dataCollectionSlice';
import TabFactory from '../components/tabs/TabFactory';
const DATA_COLLECTIONS_URL = '/api/dataCollections';
const LABELLER_URL = '/api/users';

function SupervisorDashboard() {
  const dispatch = useDispatch();

  const tabs = [
    {
      label: 'Unassigned',
      icon: 'CreateIcon',
      component: 'SupervisorDashboard/Unassigned',
      actionBar: [
        {
          type: 'checkbox',
          component: 'SupervisorDashboard/FetchAssignedCheckbox',
        },
        {
          type: 'dropdown',
          component: 'SupervisorDashboard/LabellerSelector',
        },
        {
          type: 'button',
          component: 'SupervisorDashboard/AssignButton',
        },
      ],
    },
    {
      label: 'In Progress',
      icon: 'AccessTimeIcon',
      component: 'SupervisorDashboard/InProgress',
      actionBar: [],
    },
    {
      label: 'Action Required',
      icon: 'WarningIcon',
      component: 'SupervisorDashboard/ActionRequired',
      actionBar: [],
    },
    {
      label: 'Finished',
      icon: 'DoneIcon',
      component: 'SupervisorDashboard/Finished',
      actionBar: [],
    },
    {
      label: 'Labellers',
      icon: 'PersonIcon',
      component: 'SupervisorDashboard/Labellers',
      actionBar: [
        {
          type: 'button',
          component: 'SupervisorDashboard/AddPersonButton',
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch(clearPage());
    dispatch(clearSelectedRows()); //FIXME: is executed too early when multiple renders without []
    dispatch(configurePage(tabs));
    dispatch(setPageName('Supervisor Dashboard'));
    dispatch(
      fetchLabellers({
        url: LABELLER_URL,
        method: 'GET',
        params: { role: UserRole.LABELLER },
      })
    );
    dispatch(
      fetchDataCollections({
        url: DATA_COLLECTIONS_URL,
        method: 'GET',
      })
    );
  }, []);

  return <TabFactory />;
}

export default SupervisorDashboard;
