import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addOrRemoveItem } from '../../helpers/utils';
import { LoadingStatus } from '../../constants';

export const fetchRows = createAsyncThunk('pending/fetchRows', async (_, { getState }) => {
  const {
    pending: {
      table: { limit, page, sort },
    },
  } = getState();
  const response = await axios.get('/api/sequenceIntervals/staged', {
    params: {
      limit,
      page,
      sort,
    },
  });
  return response.data;
});

const initialState = {
  rows: [],
  status: LoadingStatus.IDLE,
  table: {
    orderBy: 'DataCollection.name',
    sortOrder: 'asc',
    filteredBy: '',
    filterValue: '',
    selectedRows: [], //  prev: rowsSelected
    totalItems: 0,
    page: 0,
    limit: 50,
    sort: 'DataCollection.name',
  },
};

const pendingSlice = createSlice({
  name: 'pending',
  initialState,
  reducers: {
    setTableState: {
      reducer(state, action) {
        const newState = { ...state.table, ...action.payload };
        state.table = newState;
      },
    },
    setSelectedRow: {
      reducer(state, action) {
        state.table.selectedRows = addOrRemoveItem(state.table.selectedRows, action.payload).sort();
      },
    },
    setAllRowsToSelected: {
      reducer(state) {
        state.table.selectedRows = state.rows.map((row) => row.id);
      },
    },
    clearSelectedRows: {
      reducer(state) {
        state.table.selectedRows = [];
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRows.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchRows.fulfilled, (state, action) => {
        state.rows = action.payload.raw_sequences;
        state.table.totalItems = action.payload.total_items;
        state.status = LoadingStatus.DONE;
      });
  },
});

export const selectRows = ({ pending }) => pending.rows;
export const selectStatus = ({ pending }) => pending.status;
export const selectTableState = ({ pending }) => pending.table;
export const selectTotalItems = ({ pending }) => pending.table.totalItems;
export const selectSelectedRows = ({ pending }) => pending.table.selectedRows;

export const {
  setTableState,
  setSelectedRow,
  setAllRowsToSelected,
  clearSelectedRows,
} = pendingSlice.actions;
export default pendingSlice.reducer;
