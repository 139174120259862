import React, { useState, useEffect, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { clearPage } from '../reducers/pageSlice';
import { resetTabIndex } from '../reducers/uiStateSlice';
import { clearView } from '../reducers/viewSlice';
import { clearTable } from '../reducers/tableSlice';
import { clearDataCollections } from '../reducers/dataCollectionSlice';

import Spinner from '../components/shared/Spinner';

const AUTH_CURRENT_URL = '/api/auth/current';
const AUTH_LOGIN_URL = '/api/auth/login';
const AUTH_LOGOUT_URL = '/api/auth/logout';

export const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const dispatch = useDispatch();

  const [, , removeCookie] = useCookies(['session']);

  const [state, setState] = useState({
    status: 'pending',
    error: null,
    user: null,
  });

  function resetState() {
    setState({ status: 'success', error: null, user: null });
  }

  function storeUser(user) {
    setState({ status: 'success', error: null, user });
  }

  async function login(email, password) {
    return authenticate(email, password)
      .then(() => {
        getCurrentUser().then((res) => storeUser(res.data));
      })
      .catch((error) => {
        throw error;
      });
  }

  function logout() {
    signout().then(() => {
      resetState();
      removeCookie();
      dispatch(clearTable());
      dispatch(resetTabIndex());
      dispatch(clearView());
      dispatch(clearPage());
      dispatch(clearDataCollections());
    });
  }

  useEffect(() => {
    getCurrentUser()
      .then((res) => {
        storeUser(res.data);
      })
      .catch(() => {
        resetState();
      });
  }, []);

  return (
    <AuthContext.Provider value={{ state, login, logout }}>
      {state.status === 'pending' ? <Spinner /> : children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.element,
};

export function useAuth() {
  const state = useContext(AuthContext);
  const isPending = state.status === 'pending';
  const isError = state.status === 'error';
  const isSuccess = state.status === 'success';
  const isAuthenticated = state.user && isSuccess;
  return {
    ...state,
    isPending,
    isError,
    isSuccess,
    isAuthenticated,
  };
}

async function getCurrentUser() {
  return await axios({
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    url: AUTH_CURRENT_URL,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}

async function authenticate(email, password) {
  return await axios({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    url: AUTH_LOGIN_URL,
    data: { email, password },
  })
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

async function signout() {
  return await axios({
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    url: AUTH_LOGOUT_URL,
  })
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}
