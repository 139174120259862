import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { LabelStatus } from '../../constants';
import { uploadFile } from '../../api';
import {
  selectLabelDVTs,
  selectAssignment,
  createLabelDVT,
  createAutolabelJob,
} from '../../reducers/labellerSlice';
import ActionButton from '../ui/ActionButton';
import LabelDVTSubmission from './LabelDVTSubmission';
import LabelDVTAutolabel from './LabelDVTAutolabel';
import useStyles from './styles/AssignmentBoard';

function LabelSubmissionsSection() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const labelDVTs = useSelector(selectLabelDVTs);
  const assignment = useSelector(selectAssignment);
  const autolabelInputFile = useRef(null);
  const submissionInputFile = useRef(null);

  async function handleLabelSubmissionUpload(event) {
    const { files } = event.target;
    if (files && files.length > 0) {
      const fileId = await uploadFile(files[0]);
      if (fileId) {
        dispatch(
          createLabelDVT({
            labelTaskId: assignment.id,
            fileId,
          })
        );
      }
    }
  }

  async function handleAutolabelUpload(event) {
    const { files } = event.target;
    if (files && files.length > 0) {
      const fileId = await uploadFile(files[0]);
      if (fileId) {
        dispatch(
          createAutolabelJob({
            labelTaskId: assignment.id,
            fileId,
          })
        );
      }
    }
  }

  return (
    labelDVTs && (
      <>
        <Grid container spacing={2}>
          <Grid item style={{ flexGrow: '60', paddingBottom: '2.5rem' }}>
            <Typography component="div">
              <Box fontWeight="fontWeightBold">Label Submissions</Box>
            </Typography>
          </Grid>
          <Grid item>
            <input
              style={{ display: 'none' }}
              ref={submissionInputFile}
              onChange={handleLabelSubmissionUpload}
              type="file"
            />
            <ActionButton
              label="FOR REVIEW"
              icon="PublishIcon"
              variant="outlined"
              color="action"
              style={{ width: '10rem' }}
              clickHandler={() => submissionInputFile.current.click()}
            />
          </Grid>
          <Grid item>
            <input
              style={{ display: 'none' }}
              ref={autolabelInputFile}
              onChange={handleAutolabelUpload}
              type="file"
            />
            <ActionButton
              label="FAST LABELLING"
              icon="PublishIcon"
              variant="outlined"
              color="action"
              style={{ width: '10rem' }}
              clickHandler={() => autolabelInputFile.current.click()}
            />
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: '1rem' }} spacing={4}>
          {labelDVTs.map((labelDVT, idx) => {
            if (labelDVT.status === LabelStatus.AUTOLABEL) {
              return (
                <Grid item key={idx} className={classes.submissionItem}>
                  <LabelDVTAutolabel idx={labelDVTs.length - idx - 1} item={labelDVT} />
                </Grid>
              );
            } else {
              return (
                <Grid item key={idx} className={classes.submissionItem}>
                  <LabelDVTSubmission idx={labelDVTs.length - idx - 1} item={labelDVT} />
                </Grid>
              );
            }
          })}
        </Grid>
      </>
    )
  );
}

export default LabelSubmissionsSection;
