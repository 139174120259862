import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  content: {
    display: 'flex',
    marginTop: '100px',
  },
  button: {
    margin: '15px',
    width: '380px',
  },
}));
