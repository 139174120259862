import React from 'react';
import {
  MuiThemeProvider,
  withStyles,
  unstable_createMuiStrictModeTheme as createMuiTheme,
  lighten,
  darken,
} from '@material-ui/core/styles'; // experimental createMuiTheme i.o. to suppress StrictMode Warning

import { CssBaseline } from '@material-ui/core';

export const styles = {
  '@global': {
    '.root': {
      height: 100,
      width: 100,
    },
    '.link': {
      textDecoration: 'none',
    },
  },
};

const colors = {
  // Primary color (logo variants)
  logoGreenLight: '#e7f6f4',
  logoGreen: '#18A794',
  logoGreenDark: '#106F63',

  // Secondary color (gray)
  lightGray: '#f6f6f6',
  gray: '#e5e5e5',
  darkGray: '#4c4c4c',

  // Others
  white: '#ffffff',
  black: '#000000',
  yellowOrange: '#ffb547',
  red: '#e32d2d',
  blue: '#0066e3',
  darkGreen: '#106F63',
  greenishBlue: '#cdeaf0',
  darkBlue: '#0b00e0',
  lightGreen: '#98ef5e',
  lightBlue: '#cdffff',
  lightYellow: '#f7f7f1',
};

export const theme = createMuiTheme({
  props: {
    MuiOutlinedInput: {
      margin: 'dense',
    },
  },
  colors,
  palette: {
    primary: {
      light: colors.logoGreenLight,
      main: colors.logoGreen,
      dark: colors.logoGreenDark,
    },
    secondary: {
      light: colors.lightGray,
      main: colors.gray,
      dark: colors.darkGray,
    },
    general: {
      primary: colors.logoGreen,
      secondary: colors.lightGray,
      error: colors.red,
      success: colors.logoGreen,
      warning: colors.yellowOrange,
      disabled: colors.gray,
      black: colors.black,
      white: colors.white,
    },
    button: {
      neutral: {
        main: colors.white,
        contrastText: colors.darkGray,
      },
      confirm: {
        main: colors.yellowOrange,
        contrastText: colors.darkGray,
      },
      reject: {
        main: colors.red,
        contrastText: colors.lightGray,
      },
      accept: {
        main: colors.greenishBlue,
        contrastText: colors.darkGray,
      },
      action: {
        main: colors.darkGreen,
        contrastText: colors.lightGray,
        disabled: colors.gray,
      },
      submit: {
        main: colors.blue,
        contrastText: colors.lightGray,
      },
    },
    contextMenu: {
      background: colors.greenishBlue,
    },
    labelIndicator: {
      autolabel: {
        inProgress: colors.darkBlue,
        pending: colors.darkBlue,
        done: colors.darkGreen,
        failed: colors.red,
      },
      labelStatus: {
        needsReview: colors.yellowOrange,
        rejected: colors.red,
        accepted: colors.darkGreen,
      },
    },
    buttonSet: {
      button: {
        original: colors.lightBlue,
        subsampled: lighten(colors.yellowOrange, 0.5),
      },
    },
    background: {
      default: colors.white,
      navbar: {
        main: colors.black,
        contrastText: colors.white,
      },
      appbar: {
        main: colors.white,
        contrastText: colors.darkGray,
      },
    },
    link: {
      menu: {
        main: colors.white,
        visited: colors.gray,
      },
    },
    section: colors.lightYellow,
  },
  typography: {
    subtitle2: {
      fontWeight: 'bold',
      fontSize: '1rem',
    },
    useNextVariants: true,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

theme.overrides = {
  MuiButton: {
    contained: {
      color: theme.palette.secondary.light,
      backgroundColor: theme.palette.primary.dark,
      fontSize: '1rem',
      '&:hover': {
        color: darken(theme.palette.secondary.light, 0, 8),
        backgroundColor: lighten(theme.palette.primary.dark, 0.2),
      },
      '&:disabled': {
        color: theme.palette.secondary.dark,
        backgroundColor: theme.palette.secondary.light,
        borderColor: lighten(theme.palette.secondary.dark, 0.4),
        borderStyle: 'solid',
        borderWidth: '1px',
      },
    },
  },
  MuiTabs: {
    indicator: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  MuiTab: {
    root: {
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
      },
    },
  },
  MuiTableRow: {
    root: {
      '&$selected,&$selected:hover': {
        background: theme.palette.primary.light,
      },
    },
    head: {
      background: theme.palette.secondary.main,
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
};

function withTheme(Component) {
  const WithTheme = (props) => {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  };

  return withStyles(styles)(WithTheme);
}

export default withTheme;
