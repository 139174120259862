import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    padding: '30px 20px 0 20px',
  },
  container: {},
  appbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.appbar.main,
    color: theme.palette.background.appbar.contrastText,
    paddingBottom: '20px',
    boxShadow: 'none',
  },
  tab: {},
  tabs: {},
  tabbar: {},
}));
