import { nanoid } from 'nanoid';
import { transform, isArray, isObject, camelCase, snakeCase } from 'lodash';
import { UserRole } from '../constants';

export function capitalize(s) {
  if (typeof s !== 'string') throw new Error('Parameter must be of type string!');
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function addOrRemoveItem(arr, item) {
  const found = arr.indexOf(item);
  if (found > -1) {
    return arr.filter((el) => el !== item);
  } else {
    return [...arr, item];
  }
}

export function stripFilename(filename) {
  const result = filename.split('/');
  return result[result.length - 1];
}

export function camelize(obj) {
  return transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);

    acc[camelKey] = isObject(value) ? camelize(value) : value;
  });
}

export function snakealize(obj) {
  return transform(obj, (acc, value, key, target) => {
    const snakeKey = isArray(target) ? key : snakeCase(key);

    acc[snakeKey] = isObject(value) ? snakealize(value) : value;
  });
}

export function generateFileHash(filename) {
  const dot = filename.indexOf('.');
  const ext = filename.substring(dot + 1);
  return `${nanoid()}.${ext}`;
}

export function isSupervisor(currentRole) {
  /**
   * Apply bitmap comparision
   *
   * ADMIN = 4
   * SUPERVISOR = 2
   * LABELLER = 1
   */
  return (currentRole & UserRole.SUPERVISOR) != 0;
}

export function isLabeller(currentRole) {
  return (currentRole & UserRole.LABELLER) != 0;
}

export function isAdmin(currentRole) {
  return (currentRole & UserRole.ADMIN) != 0;
}

export function isPrivilegedUser(currentRole) {
  return isAdmin(currentRole) || isSupervisor(currentRole);
}

/**
 * Take the React Form Hook error object and extract all error messages (or only the ones from specified fields).
 *
 *  Why is this needed?
 *  We don't want to use the MUI helperText to display form errors because some form fields are not wide enough
 *  display an error message. The effect is that the message wraps and deforms the interface. Here we provide the
 *  an array of messages relevant to the field, so we can format our own error div.
 *
 * @param {Object} errors error object provided by React Hook Form, every key is a field holding
 *   an object of the form {message: ERROR_MESSAGE}
 * @param {*} fields specify the fields which we want to retrieve explicitly. Useful when we
 *   pass in an object which holds unrelated errors such as the root object
 * @returns Array of error messages
 */
export function retrieveAllErrors(errors, fields = []) {
  if (!errors) return;

  if (Array.isArray(fields) && !fields.length) {
    return Object.keys(errors).map((key) => {
      return errors[key].message;
    });
  } else {
    return fields.map((key) => errors[key]?.message);
  }
}
