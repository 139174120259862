import React, { lazy, useState, useEffect, memo, Suspense } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Grid from '@material-ui/core/Grid';

import ActionBar from './ActionBar';
import Infobar from '../infobar/Infobar';
import { selectTabs, selectTabHeaders, selectPageName } from '../../reducers/pageSlice';
import {
  changeTabIndex,
  selectTabIndex,
  clearSelectedRows,
  clearSelectedLabeller,
  clearLinkState,
  setOrderBy,
  setPage,
} from '../../reducers/uiStateSlice';

import iconMapping from '../../helpers/IconMapping';

import useStyles from '../../styles/TabFactoryStyles';

function importView(element) {
  return lazy(() => import(`../../views/${element}`).catch(() => import('../shared/NullElement')));
}

function TabFactory() {
  const dispatch = useDispatch();
  const tabConfig = useSelector(selectTabs);
  const tabHeaders = useSelector(selectTabHeaders);
  const tabIdx = useSelector(selectTabIndex);
  const pageName = useSelector(selectPageName);
  const [view, setView] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (tabConfig[tabIdx]) {
      setView({ component: tabConfig[tabIdx].component });
    }
  }, [tabConfig]);

  function handleTabChange(evt, tabIdx) {
    dispatch(changeTabIndex({ tabIdx }));
    dispatch(setOrderBy(null));
    dispatch(clearSelectedRows());
    dispatch(clearSelectedLabeller());
    dispatch(clearLinkState());
    dispatch(setPage(0));
    setView({ component: tabConfig[tabIdx].component });
  }

  function renderView(view, id) {
    const View = importView(view.component);
    return <View key={id} {...tabConfig[tabIdx]?.props} />;
  }

  if (isEmpty(view)) return null;

  return (
    <div className={classes.root}>
      {pageName && <Infobar heading={pageName} />}
      <Grid container justify="space-between" alignItems="baseline">
        <Grid item>
          <AppBar className={classes.appbar} position="static">
            <Tabs
              className={classes.tabs}
              value={tabIdx}
              onChange={handleTabChange}
              indicatorcolor="primary">
              {tabHeaders.map((tab, idx) => {
                const Icon = iconMapping[tab.icon];
                return (
                  <Tab
                    data-cy="tab"
                    className={classes.tab}
                    key={idx}
                    label={tab.label}
                    icon={<Icon />}
                  />
                );
              })}
            </Tabs>
          </AppBar>
        </Grid>

        <Grid item classes={{ root: classes.tabbar }}>
          <ActionBar />
        </Grid>
      </Grid>
      <div className={classes.container}>
        <Suspense fallback={<></>}>{renderView(view, tabIdx)}</Suspense>
      </div>
    </div>
  );
}

export default memo(TabFactory);
