import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  rangeInput: {
    '& .range-start > :not(.Mui-error), & .range-end > div:not(.Mui-error)': {
      // NOTE(sven): Yhis is exactly the hight of the form helper text when an
      // error occurs.
      marginBottom: 'calc(0.75rem * 1.66 + 4px)',
    },
  },
}));

// Custom Prop Type
export function FormNumber(props, propName, componentName) {
  const { [propName]: value } = props;

  if (value === undefined) {
    return new Error(`Prop name ${propName} in component ${componentName} is required.`);
  }

  if (value !== null && typeof value !== 'number') {
    return new Error(
      `Prop name ${propName} in component ${componentName} must be the null or a number.`
    );
  }
}

/**
 * Renders a start and end text input field with error validation.
 *
 * It is a valid state when both fields are empty. Start must be less or equal
 * to end. When `emptyIsError` is set, the empty state will be shown as
 * errornous state. When a string is passed to either `forceStartError` or
 * `forceEndError` the error helper text and state can be overwritten.
 */
export default function RangeInput({
  start,
  end,
  onChange,
  disabled = false,
  required = false,
  emptyIsError = false,
  forceStartError = null,
  forceEndError = null,
}) {
  const classes = useStyles();

  const startInputIsValid = (start === null && end === null) || start > 0;
  const startHasError = !startInputIsValid || (emptyIsError && start == null) || !!forceStartError;

  const endInputIsValid = (start === null && end === null) || end >= start;
  const endHasError = !endInputIsValid || (emptyIsError && end == null) || !!forceEndError;

  return (
    <span className={classes.rangeInput}>
      <TextField
        className="range-start"
        size="small"
        label="Start"
        variant="outlined"
        type="number"
        required={required}
        disabled={disabled}
        inputProps={{ min: 1 }}
        value={start || ''}
        error={startHasError}
        helperText={
          forceStartError ? forceStartError : startHasError ? 'Positive number required.' : ''
        }
        onChange={(event) => onChange({ start: parseInt(event.target.value) || null, end })}
      />
      <TextField
        className="range-end"
        size="small"
        label="End"
        variant="outlined"
        required={required}
        disabled={disabled}
        type="number"
        inputProps={{ min: start || 1 }}
        error={endHasError}
        value={end || ''}
        helperText={
          forceEndError ? forceEndError : endHasError ? 'Must greater or equal to start.' : ''
        }
        onChange={(event) => onChange({ start, end: parseInt(event.target.value) || null })}
      />
    </span>
  );
}

RangeInput.propTypes = {
  start: FormNumber,
  end: FormNumber,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  emptyIsError: PropTypes.bool,
  forceStartError: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string]),
  forceEndError: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};
