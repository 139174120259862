import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, lighten, darken } from '@material-ui/core/styles';

import Dropzone from './ui/Dropzone';
import ActionButton from './ui/ActionButton';
import { buildFileDownloadUrl, uploadFile } from '../api';
import icons from '../helpers/IconMapping';

import { createScreenshot } from '../reducers/labellerSlice';
import { selectScreenshot, selectAssignment } from '../reducers/labellerSlice';

const { CloseIcon } = icons;

const useStyles = makeStyles((theme) => {
  return {
    screenshotSection: {
      position: 'relative',

      '& button': {
        position: 'absolute',
        top: 0,
        right: 0,
      },

      '& h6': {
        marginBottom: theme.spacing(2),
      },

      '& .screenshot-image': {
        width: '100%',
        cursor: 'pointer',
      },

      '& .screenshot-placeholder': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
        textAlign: 'center',

        backgroundColor: lighten(theme.palette.general.disabled, 0.4),
        border: `3px dashed ${darken(theme.palette.general.disabled, 0.4)}`,
        borderRadius: '4px',
      },
    },

    screenshotModal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& .screenshot-card': {
        maxWidth: '80%',
        position: 'relative',
      },

      '& .close-screenshot-modal': {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        backgroundColor: 'rgba(255, 255, 255, 150)',
      },

      '& .screenshot-large': {
        width: '100%',
      },
    },

    assignmentBoardScreenshot: {
      maxWidth: '24rem',
    },
  };
});

/**
 * Dumb component.
 *
 * Shows a dropzone to upload a new screenshot.  Once a screenshot is uploaded,
 * the image can be clicked to enlarge it. A "change image" button appears which
 * opens a file selector so the user can upload a new screenshot. Editing can be
 * disabled via a property.
 *
 * fileId can be undefined when no screenshot is uploaded yet.
 *
 * When a new screenshot is successfully uploaded, the onChange handler is
 * called with the new fileId of the S3File entity.
 */
export function ScreenshotSection({ allowEditing = false, fileId, className = '', onChange }) {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const inputFile = useRef(null);

  const hasScreenshot = !!fileId;
  const screenshotUrl = buildFileDownloadUrl(fileId);

  /**
   * Uploads the first file in the given FileList as the new screenshot and
   * triggers the onChange handler when the upload succeeds.
   */
  async function uploadScreenshot(fileList) {
    if (fileList && fileList.length > 0) {
      const fileId = await uploadFile(fileList[0]);
      if (fileId) {
        onChange(fileId);
      }
    }
  }

  function renderScreenshot() {
    if (hasScreenshot) {
      return (
        <img className="screenshot-image" src={screenshotUrl} onClick={() => setShowModal(true)} />
      );
    } else if (!allowEditing) {
      return (
        <div className="screenshot-placeholder">
          <h3>No screenshot available. Ask your supervisor to provide one.</h3>
        </div>
      );
    }
  }

  return (
    <>
      <Modal
        open={showModal}
        className={`${classes.screenshotModal} screenshot-modal`}
        onClose={() => setShowModal(false)}>
        <Card className="screenshot-card">
          <IconButton className="close-screenshot-modal" onClick={() => setShowModal(false)}>
            <CloseIcon />
          </IconButton>
          <img src={screenshotUrl} className="screenshot-large" />
        </Card>
      </Modal>

      <Box className={`${classes.screenshotSection} screenshot-section ${className}`}>
        <Typography variant="subtitle2">Screenshot</Typography>

        {allowEditing && hasScreenshot && (
          <>
            <input
              style={{ display: 'none' }}
              accept=".jpg,.png"
              ref={inputFile}
              onChange={(event) => uploadScreenshot(event.target.files)}
              type="file"
            />
            <ActionButton
              label="Change Image"
              icon=""
              clickHandler={() => inputFile.current.click()}
            />
          </>
        )}

        {allowEditing && !hasScreenshot && (
          <Dropzone filesLimit={1} dropHandler={uploadScreenshot} />
        )}

        {renderScreenshot()}
      </Box>
    </>
  );
}

ScreenshotSection.propTypes = {
  allowEditing: PropTypes.bool,
  fileId: PropTypes.number,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

/**
 * Smart component. Used in the assignment board views as it is tied to the
 * store and has a lot of useSelector calls.
 */
function AssignmentBoardScreenshotSection({ allowEditing = false }) {
  const dispatch = useDispatch();
  const screenshot = useSelector(selectScreenshot);
  const labelTask = useSelector(selectAssignment);
  const classes = useStyles();

  function onScreenshotChange(fileId) {
    dispatch(
      createScreenshot({
        sequenceIntervalId: labelTask.sequence_interval.id,
        fileId,
      })
    );
  }

  return (
    <ScreenshotSection
      className={classes.assignmentBoardScreenshot}
      allowEditing={allowEditing}
      onChange={onScreenshotChange}
      fileId={screenshot?.file_id}
    />
  );
}

export default AssignmentBoardScreenshotSection;
