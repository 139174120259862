import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { buildFileDownloadUrl } from '../../api';
import { selectAssignment } from '../../reducers/labellerSlice';
import { stripFilename } from '../../helpers/utils';
import ActionButton from '../ui/ActionButton';
import { apiFetch } from '../../api';

function DownloadSection() {
  const labelTask = useSelector(selectAssignment);
  const useSubsampledFile = labelTask.use_subsampled_file;
  const subsampledFileLabel = useSubsampledFile ? 'Subsampled File' : 'Original File';
  const file = useSubsampledFile
    ? labelTask.sequence_interval.subsampled?.file
    : labelTask.sequence_interval.file;

  const [rawSequence, setRawSequence] = useState(null);

  useEffect(() => {
    if (!labelTask) {
      return;
    }

    async function fetchRawSequence(id) {
      const sequence = await apiFetch(`/api/rawSequences/${id}`);
      setRawSequence(sequence);
    }

    const id = labelTask.raw_sequence.id;
    fetchRawSequence(id);
  }, [labelTask]);

  if (!file) {
    return <div>No file found!</div>;
  }

  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item style={{ flexGrow: 80 }}>
        <Link
          to={`/viewData/sequenceIntervals/${labelTask.sequence_interval.id}`}
          style={{ textDecoration: 'none' }}>
          <Typography component="div">
            <Box fontWeight="fontWeightBold">
              {stripFilename(file.original_filename)} ({subsampledFileLabel})
            </Box>
          </Typography>
        </Link>
      </Grid>
      <Grid item>
        <ActionButton
          label="DOWNLOAD"
          icon="GetAppIcon"
          variant="outlined"
          color="action"
          href={buildFileDownloadUrl(file.id)}
        />{' '}
        <ActionButton
          label="View"
          icon="VisibilityIcon"
          variant="outlined"
          href={`/dicomViewer/${file.id}`}
          overrides={{ target: '_blank' }}
        />{' '}
        <ActionButton
          label="View Full Sequence"
          icon="VisibilityIcon"
          variant="outlined"
          disabled={!rawSequence || !rawSequence?.sampled_dicom_file}
          href={`/dicomViewer/${rawSequence?.sampled_dicom_file?.id}`}
          overrides={{ target: '_blank' }}
        />
      </Grid>
      <Grid>
        <span>
          Ranges from frame {labelTask.sequence_interval.start} to {labelTask.sequence_interval.end}{' '}
          in the full sequence.
        </span>
      </Grid>
    </Grid>
  );
}

export default DownloadSection;
