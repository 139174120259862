import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import iconMapping from '../../helpers/IconMapping.js';

import useStyles from './styles/Infobar';

function Infobar({ heading }) {
  const classes = useStyles();
  const Icon = iconMapping['PlayArrowIcon'];

  return (
    <Button
      variant="text"
      disabled
      classes={{ root: classes.button, disabled: classes.disabled }}
      startIcon={<Icon />}>
      <Typography className={classes.heading} variant="h5">
        {heading}
      </Typography>
    </Button>
  );
}

Infobar.propTypes = {
  heading: PropTypes.string.isRequired,
};

export default Infobar;
