import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { snakealize } from '../helpers/utils';
import { LoadingStatus } from '../constants';

const initialState = {
  assignment: {
    sequence_interval: {
      id: null,
      file: null,
      screenshot: null,
      metadata_dvt: {
        vessel_closed_at: null,
        landmark_not_present_at: null,
      },
    },
    label_dvts: null,
  },
  comments: [],
  notifications: [],
  numberOfUnseenNotifications: 0,
  status: LoadingStatus.IDLE,
  screenshotStatus: LoadingStatus.IDLE,
  commentFetchStatus: LoadingStatus.IDLE,
  commentUploadStatus: LoadingStatus.IDLE,
};

/**
 * Async thunks
 */
export const fetchLabellerAssignment = createAsyncThunk(
  'labellerAssignment/fetchAssignment',
  async (id) => {
    const response = await axios.get(`/api/labelTasks/${id}`);
    return response.data;
  }
);

export const updateVesselClosed = createAsyncThunk(
  'labellerAssignment/updateVesselClosed',
  async (params) => {
    const response = await axios.put(
      `/api/sequenceIntervals/${params.sequenceIntervalId}/metadataDVT`,
      {
        vessel_closed_at: [...params.vesselClosedList],
      }
    );
    return response.data;
  }
);

export const updateLandmarkNotPresentAt = createAsyncThunk(
  'labellerAssignment/updateLandmarkNotPresentAt',
  async (params) => {
    const response = await axios.put(
      `/api/sequenceIntervals/${params.sequenceIntervalId}/metadataDVT`,
      {
        landmark_not_present_at: [...params.landmarkNotPresentAtList],
      }
    );
    return response.data;
  }
);

export const updateLabelDVTStatus = createAsyncThunk(
  'labellerAssignment/updateLabelDVTStatus',
  async (params) => {
    const response = await axios.patch(`/api/labelDVTs/${params.labelDVTId}/changeStatus`, {
      status: params.status,
    });
    return response.data;
  }
);

export const fetchComments = createAsyncThunk('labellerAssignment/fetchComments', async (id) => {
  const response = await axios.get(`/api/labelTasks/${id}/comments`);
  return response.data;
});

export const createComment = createAsyncThunk(
  'labellerAssignment/createComment',
  async ({ comment, fileId, labelTaskId }) => {
    const commentResponse = await fetch(`/api/labelTasks/${labelTaskId}/comments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        comment,
        file_id: fileId,
      }),
    });
    if (commentResponse.ok) {
      return commentResponse.json();
    } else {
      return null;
    }
  }
);

export const getNumberOfNotifications = createAsyncThunk(
  'labellerAssignment/getNumberOfNotifications',
  async () => {
    const response = await axios.get(`/api/notifications/getNumberOfUnseen`);
    return response.data;
  }
);

export const fetchTaskNotifications = createAsyncThunk(
  'labellerAssignment/fetchTaskNotifications',
  async (params) => {
    const response = await axios.get(`/api/notifications/task/${params.label_task_id}`);
    return response.data;
  }
);

export const fetchNotifications = createAsyncThunk(
  'labellerAssignment/fetchNotifications',
  async () => {
    const response = await axios.get(`/api/notifications`);
    return response.data;
  }
);

export const createNotification = createAsyncThunk(
  'labellerAssignment/createNotification',
  async (params) => {
    const response = await axios.post(
      `/api/users/${params.userId}/notifications`,
      snakealize(params.payload)
    );
    return response.data;
  }
);

export const updateNotification = createAsyncThunk(
  'labellerAssignment/updateNotification',
  async (params) => {
    const response = await axios.patch(
      `/api/users/${params.userId}/notifications/${params.notificationId}`,
      snakealize(params.payload)
    );
    return response.data;
  }
);

export const updateNotificationStatus = createAsyncThunk(
  'labellerAssignment/updateNotificationStatus',
  async (params) => {
    const response = await axios.patch(
      `/api/users/${params.userId}/notifications/${params.notificationId}`,
      params.payload
    );
    return response.data;
  }
);

export const createScreenshot = createAsyncThunk(
  'labellerAssignment/createScreenshot',
  async ({ sequenceIntervalId, fileId }) => {
    const response = await axios.post(`/api/sequenceIntervals/${sequenceIntervalId}/screenshot`, {
      file_id: fileId,
    });
    return response.data;
  }
);

export const createLabelDVT = createAsyncThunk(
  'labellerAssignment/createLabelDVT',
  async ({ labelTaskId, fileId }) => {
    const response = await axios.post(`/api/labelTasks/${labelTaskId}/labelDVTs`, {
      file_id: fileId,
    });
    return response.data;
  }
);

export const createAutolabelJob = createAsyncThunk(
  'labellerAssignment/createAutolabelJob',
  async ({ labelTaskId, fileId }) => {
    const response = await axios.post(`/api/labelTasks/${labelTaskId}/labelDVTs/autolabel`, {
      file_id: fileId,
    });
    return response.data;
  }
);

const labellerSlice = createSlice({
  name: 'labellerAssignment',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state.assignment = initialState.assignment;
      state.comments = [];
      state.notifications = [];
      state.numberOfUnseenNotifications = 0;
      state.status = LoadingStatus.IDLE;
      state.screenshotStatus = LoadingStatus.IDLE;
      state.commentFetchStatus = LoadingStatus.IDLE;
      state.commentUploadStatus = LoadingStatus.IDLE;
    },
    setImageQualityScore(state, action) {
      state.assignment.sequence_interval.image_quality_score = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLabellerAssignment.pending, (state) => {
        state.status = LoadingStatus.LOADING;
      })
      .addCase(fetchLabellerAssignment.fulfilled, (state, action) => {
        state.assignment = action.payload;
        state.status = LoadingStatus.DONE;
      })
      .addCase(updateVesselClosed.fulfilled, (state, action) => {
        const newArray = action.payload.vessel_closed_at.map((item) => {
          return { end: item.end, start: item.start, vessel: item.vessel, dnc: item.dnc };
        });
        state.assignment.sequence_interval.metadata_dvt.vessel_closed_at = newArray;
      })
      .addCase(updateLandmarkNotPresentAt.fulfilled, (state, action) => {
        const newArray = action.payload.landmark_not_present_at.map((item) => {
          return { end: item.end, start: item.start, dnc: item.dnc };
        });
        state.assignment.sequence_interval.metadata_dvt.landmark_not_present_at = newArray;
      })
      .addCase(updateLabelDVTStatus.fulfilled, (state, action) => {
        state.assignment.label_dvts.forEach((item) => {
          if (item.id === action.payload.id) {
            item.status = action.payload.status;
          }
        });
      })
      .addCase(fetchComments.pending, (state) => {
        state.commentFetchStatus = LoadingStatus.LOADING;
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.comments = action.payload.comments;
        state.commentFetchStatus = LoadingStatus.DONE;
      })
      .addCase(createComment.pending, (state) => {
        state.commentUploadStatus = LoadingStatus.LOADING;
      })
      .addCase(createComment.fulfilled, (state, action) => {
        if (action.payload) {
          state.comments.unshift(action.payload);
        }
        state.commentUploadStatus = LoadingStatus.DONE;
      })
      .addCase(createNotification.fulfilled, (state, action) => {
        state.notifications.push(action.payload.notification);
      })
      .addCase(fetchTaskNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload.notifications;
      })
      .addCase(getNumberOfNotifications.fulfilled, (state, action) => {
        state.numberOfUnseenNotifications = action.payload.count;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload.notifications;
      })
      .addCase(createScreenshot.pending, (state) => {
        state.screenshotStatus = LoadingStatus.LOADING;
      })
      .addCase(createScreenshot.fulfilled, (state, action) => {
        state.assignment.sequence_interval.screenshot = {
          id: action.payload.id,
          approved: action.payload.approved,
          file_id: action.payload.file.id,
          sequence_interval: action.payload.id,
        };
        state.screenshotStatus = LoadingStatus.DONE;
      })
      .addCase(createLabelDVT.pending, (state) => {
        state.labelDVTStatus = LoadingStatus.LOADING;
      })
      .addCase(createLabelDVT.fulfilled, (state, action) => {
        state.assignment.label_dvts.unshift(action.payload);
      })
      .addCase(createAutolabelJob.pending, (state) => {
        state.labelDVTStatus = LoadingStatus.LOADING;
      })
      .addCase(createAutolabelJob.fulfilled, (state, action) => {
        state.assignment.label_dvts.unshift(action.payload);
      });
  },
});

export const selectLoadingStatus = (state) => state.labellerAssignment.status;

export const selectAssignment = (state) => state.labellerAssignment.assignment;

export const selectAssignmentId = (state) => state.labellerAssignment.assignment.id;

export const selectSequenceIntervalId = (state) =>
  state.labellerAssignment.assignment.sequence_interval.id;

export const selectMetadata = (state) =>
  state.labellerAssignment.assignment.sequence_interval.metadata_dvt;

export const selectSequenceIntervalFile = (state) =>
  state.labellerAssignment.assignment.sequence_interval.file;

export const selectScreenshot = (state) =>
  state.labellerAssignment.assignment.sequence_interval.screenshot;

export const selectVesselClosedList = (state) =>
  state.labellerAssignment.assignment.sequence_interval.metadata_dvt.vessel_closed_at;

export const selectVesselClosedListItem = (state) => (id) =>
  state.labellerAssignment.assignment.sequence_interval.metadata_dvt.vessel_closed_at[id];

export const selectLandmarkNotPresentList = (state) =>
  state.labellerAssignment.assignment.sequence_interval.metadata_dvt.landmark_not_present_at;

export const selectLabelDVTSubmittedFile = (state) =>
  state.labellerAssignment.assignment.label_dvts.file;

export const selectLabelDVTs = (state) => state.labellerAssignment.assignment.label_dvts;

export const selectComments = (state) => state.labellerAssignment.comments;

export const selectCommentFetchStatus = (state) => state.labellerAssignment.comments;

export const selectCommentUploadStatus = (state) => state.labellerAssignment.commentUploadStatus;

export const selectTaskReviewNotifications = (state) => {
  return state.labellerAssignment.notifications.filter((notification) => notification?.type === 0); // Review
};

export const selectTaskCommentNotifications = (state) => {
  return state.labellerAssignment.notifications.filter((notification) => notification?.type === 1); // Commment
};

export const selectNumberOfUnseenNotifications = (state) =>
  state.labellerAssignment.numberOfUnseenNotifications;

export const { resetState, setImageQualityScore } = labellerSlice.actions;
export default labellerSlice.reducer;
