import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchDataCollections } from '../reducers/dataCollectionSlice';
import { configurePage, setPageName, clearPage } from '../reducers/pageSlice';
import { unsetRowMultiSelect, clearSelectedRows } from '../reducers/uiStateSlice';
import TabFactory from '../components/tabs/TabFactory';

const DATA_COLLECTIONS_URL = '/api/dataCollections';

function DataCurationDashboard() {
  const dispatch = useDispatch();

  const tabs = [
    {
      label: 'Labelling Candidates',
      icon: 'CreateIcon',
      component: 'DataCurationDashboard/LabellingCandidates',
      actionBar: [],
    },
    {
      label: 'Staged for Splitting',
      icon: 'PostAddIcon',
      component: 'DataCurationDashboard/StagedForSplitting',
      actionBar: [
        {
          type: 'button',
          component: 'DataCurationDashboard/SplitSequencesButton',
        },
      ],
    },
    {
      label: 'Pending',
      icon: 'AccessTimeIcon',
      component: 'DataCurationDashboard/Pending',
    },
  ];

  useEffect(() => {
    dispatch(clearPage());
    dispatch(clearSelectedRows());
    dispatch(configurePage(tabs));
    dispatch(setPageName('Data Curation Dashboard'));
    dispatch(unsetRowMultiSelect());
    dispatch(
      fetchDataCollections({
        url: DATA_COLLECTIONS_URL,
        method: 'GET',
      })
    );
  }, []);

  return <TabFactory />;
}

export default DataCurationDashboard;
