import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  img: {
    display: 'block',
    width: 'auto',
    height: '100%',
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
    '&:hover $removeBtn': { opacity: 1 },
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  },
  removeBtn: {
    opacity: 0,
    transition: '.5s ease',
  },
  nopreview: {
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));
