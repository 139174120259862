import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Snackbar from '../components/ui/Snackbar';

import { useAuth } from '../contexts/AuthContext';

import useStyles from '../styles/LoginStyles';

function Login() {
  const classes = useStyles();
  const { login } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    login(email, password)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        showSnackbar(err);
      });
  }

  function showSnackbar() {
    setOpen(true);
  }

  return (
    <Box className={classes.container}>
      <Paper className={classes.paper}>
        <form
          name="login"
          data-test-id="login-form"
          onSubmit={handleSubmit}
          className={classes.form}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              id="email"
              value={email}
              onChange={(event) => setEmail(event.target.value.trim())}
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value.trim())}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading || !email.trim() || !password.trim()}
            className={classes.submit}>
            {loading ? 'Logging in ...' : 'Login'}
          </Button>
        </form>
      </Paper>
      <Snackbar message="Email or password wrong!" open={open} setOpen={setOpen} />
    </Box>
  );
}

export default Login;
