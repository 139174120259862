import { makeStyles, lighten, darken } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  actionButtonNeutral: {
    backgroundColor: theme.palette.general.white,
    color: theme.palette.general.black,
    '&:hover': {
      backgroundColor: theme.palette.button.neutral.main,
      color: darken(theme.palette.button.neutral.contrastText, 0.8),
    },
  },
  actionButtonConfirm: {
    backgroundColor: theme.palette.button.confirm.main,
    color: theme.palette.button.confirm.contrastText,
    '&:hover': {
      backgroundColor: lighten(theme.palette.button.confirm.main, 0.4),
      color: darken(theme.palette.button.confirm.contrastText, 0.8),
    },
  },
  actionButtonReject: {
    backgroundColor: theme.palette.button.reject.main,
    color: theme.palette.button.reject.contrastText,
    '&:hover': {
      backgroundColor: lighten(theme.palette.button.reject.main, 0.3),
    },
  },
  actionButtonAccept: {
    backgroundColor: theme.palette.button.accept.main,
    color: theme.palette.button.accept.contrastText,
    '&:hover': {
      backgroundColor: lighten(theme.palette.button.accept.main, 0.4),
      color: theme.palette.button.accept.contrastText,
    },
  },
  actionButtonAction: {
    backgroundColor: theme.palette.button.action.main,
    color: theme.palette.button.action.contrastText,
    '&:hover': {
      backgroundColor: lighten(theme.palette.button.action.main, 0.3),
    },
  },
  actionButtonWarning: {
    backgroundColor: darken(theme.palette.general.warning, 0.05),
    '&:hover': {
      backgroundColor: theme.palette.general.warning,
    },
  },
  actionButtonSubmit: {
    backgroundColor: theme.palette.button.submit.main,
    color: theme.palette.button.reject.contrastText,
    '&:hover': {
      backgroundColor: lighten(theme.palette.button.submit.main, 0.3),
    },
  },
}));
