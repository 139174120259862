import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants';

export const fetchRows = createAsyncThunk('dataCollections/fetchRows', async (_, { getState }) => {
  const {
    dataCollections: {
      table: { limit, page, sort },
    },
  } = getState();
  const response = await axios.get('/api/dataCollections', {
    params: {
      limit,
      page,
      sort,
    },
  });
  return response.data;
});

export const fetchDataCollection = createAsyncThunk(
  'dataCollections/fetchDataCollection',
  async (id, { getState }) => {
    const {
      dataCollections: {
        table: { limit, page, sort },
      },
    } = getState();
    const response = await axios.get(`/api/dataCollections/${id}`, {
      params: {
        limit,
        page,
        sort,
      },
    });
    return response.data;
  }
);

const initialState = {
  rows: [],
  rowsById: {},
  currentItem: null,
  status: LoadingStatus.IDLE,
  table: {
    orderBy: 'DataCollection.name',
    sortOrder: 'asc',
    filteredBy: '',
    filterValue: '',
    selectedRows: [], //  prev: rowsSelected
    totalItems: 0,
    page: 0,
    limit: 50,
    sort: 'DataCollection.name',
  },
};

const dataCollectionsSlice = createSlice({
  name: 'dataCollections',
  initialState,
  reducers: {
    setTableState: {
      reducer(state, action) {
        const newState = { ...state.table, ...action.payload };
        state.table = newState;
      },
    },
    removeItemById: {
      reducer(state, action) {
        const id = action.payload;
        state.rows = state.rows.filter((item) => id !== item.id);
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRows.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchRows.fulfilled, (state, action) => {
        state.rows = action.payload.data_collections;
        state.rowsById = action.payload.data_collections.reduce(
          (acc, curr) => ({ ...acc, [curr.id]: curr }),
          state.rowsById
        );
        state.table.totalItems = action.payload.total_items;
        state.status = LoadingStatus.DONE;
      })

      .addCase(fetchDataCollection.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchDataCollection.fulfilled, (state, action) => {
        state.currentItem = action.payload;
        state.status = LoadingStatus.DONE;
      });
  },
});

export const selectRows = ({ dataCollections }) => dataCollections.rows;
export const selectStatus = ({ dataCollections }) => dataCollections.status;
export const selectTableState = ({ dataCollections }) => dataCollections.table;
export const selectTotalItems = ({ dataCollections }) => dataCollections.table.totalItems;
export const selectRowsById = ({ dataCollections }) =>
  dataCollections.rowsById.isEmpty ? null : dataCollections.rowsById;
export const selectSelectedRows = ({ dataCollections }) => dataCollections.table.selectedRows;
export const selectDataCollectionCategories = ({ dataCollections }) =>
  dataCollections.rows.map((row) => row.name);
export const selectCurrentItem = ({ dataCollections }) => dataCollections.currentItem;

export const { setTableState, removeItemById } = dataCollectionsSlice.actions;
export default dataCollectionsSlice.reducer;
