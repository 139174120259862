import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  columns: [],
  initalRequest: {},
  headerFields: [],
  filterElements: [],
  accessors: [],
};

const viewSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    configureView: {
      reducer(state, action) {
        state.columns = action.payload.columns;
        state.initalRequest = action.payload.initalRequest;
        state.headerFields = action.payload.columns.map((row) => row.header);
        state.filterElements = action.payload.columns.map((row) =>
          row.filter ? row.filter : null
        );
        state.accessors = action.payload.columns.map((row) => row.accessor);
      },
      prepare(columns, initalRequest) {
        return {
          payload: { columns, initalRequest },
        };
      },
    },
    clearView: {
      reducer(state) {
        state.columns = [];
        state.initalRequest = {};
        state.headerFields = [];
        state.filterElements = [];
        state.accessors = [];
      },
    },
  },
});

/**
 * Selectors
 */
export const selectColumns = (state) => state.view.columns;
export const selectInitialRequest = (state) => state.view.initalRequest;
export const selectColumnAccessors = (state) => state.view.accessors;
export const selectHeaderFields = (state) => state.view.headerFields;
export const selectFilterElements = (state) => state.view.filterElements;

/**
 * Actions and reducer exports
 */
export const { configureView, clearView } = viewSlice.actions;
export default viewSlice.reducer;
