import React from 'react';
import PropTypes from 'prop-types';
import MuiTooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.light,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}));

function Tooltip({ children, message }) {
  const classes = useStyles();
  return (
    <MuiTooltip title={message} classes={classes}>
      {children}
    </MuiTooltip>
  );
}

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  message: PropTypes.string.isRequired,
};

export default Tooltip;
