import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

/**
 * A single-select or multi-select dropdown element. When multiple is used,
 * value must be an array. Options can be a flat list or an object with keys as
 * forms values and values as render string.
 */
function SimpleSelect({
  options,
  value = null,
  label,
  multiple = false,
  required = false,
  error = false,
  helperText,
  className = '',
  onChange,
}) {
  // NOTE(sven): Make a flat list of options into a map from value to render
  // string.
  if (Array.isArray(options)) {
    options = Object.fromEntries(options.map((value) => [value, value]));
  }

  if (multiple && !Array.isArray(value)) {
    value = [value];
  }

  return (
    <TextField
      variant="outlined"
      size="small"
      className={className}
      label={label}
      select
      SelectProps={{
        multiple,
        renderValue: (value) => {
          if (Array.isArray(value)) {
            return value.map((v) => options[v]).join(',');
          } else {
            return options[value];
          }
        },
      }}
      error={error}
      helperText={helperText}
      required={required}
      value={value}
      onChange={(event) => {
        onChange(event.target.value);
      }}>
      {Object.entries(options).map(([v, option]) => (
        <MenuItem key={v} value={v}>
          {multiple && <Checkbox checked={value.indexOf(v) >= 0} />}
          <ListItemText>{option}</ListItemText>
        </MenuItem>
      ))}
    </TextField>
  );
}

SimpleSelect.propTypes = {
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SimpleSelect;
