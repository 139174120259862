import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { selectCurrentItem, fetchDataCollection } from '../../reducers/viewData/dataCollections';
import { setFilter, unsetFilter } from '../../reducers/viewData/patients';
import { configurePage, clearPage, setPageName } from '../../reducers/pageSlice';
import { clearView } from '../../reducers/viewSlice';
import { resetTabIndex } from '../../reducers/uiStateSlice';
import TabFactory from '../../components/tabs/TabFactory';
import Infobar from '../../components/infobar/Infobar';
import useStyles from './styles/RowItemDetailView';

const tabs = [
  {
    label: 'Patients',
    icon: 'AssignmentIndIcon',
    component: 'ViewData/Patients',
  },
  {
    label: 'Metadata',
    icon: 'DescriptionIcon',
    component: 'TableRowItem/MetadataDataCollection',
  },
];

function RawSequenceItem() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataCollection = useSelector(selectCurrentItem);
  const { id } = useParams();

  useEffect(() => {
    if (!dataCollection) {
      dispatch(fetchDataCollection(id));
    }
    dispatch(clearPage());
    dispatch(resetTabIndex());
    dispatch(clearView());
    dispatch(setPageName(''));
    dispatch(configurePage(tabs));
    // FIXME: it would be nice to set this only when we switch tabs. For this the
    // TabFactory needs to be refactored (similar to the way how the TableFactory is partly done)
    // In the new TabFactory we want to pass a `switchAction` to be exectuted when we click
    // a particular tab header
    dispatch(setFilter({ filteredBy: 'data_collection_id', filterValue: id }));
    return () => dispatch(unsetFilter());
  }, []);

  return (
    <>
      {dataCollection && (
        <>
          <Infobar heading={`Data Collection ${dataCollection.name}`} />

          <Grid container className={classes.container} direction="column">
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <TabFactory />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default RawSequenceItem;
